<div *ngIf="content" class="how-works">
    <div class="container">
        <h2 *ngIf="content.title"> {{content.title}} </h2>
        <h4 *ngIf="content.subtitle" class="mb-5 h4-width"> {{content.subtitle}} </h4>
        <div class="how-works-image d-flex flex-column flex-lg-row w-100">
            <div *ngIf="content.mainImage && content.mainImage._id" class="mobile-img w-100">
                <img src="{{environment.base_api}}/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId={{content.mainImage._id}}"
                    alt="Imagem principal">
            </div>
            <div *ngIf="!content.mainImage" class="mobile-img w-100">
                <img src="assets/images/home/mobile.png">
            </div>
            <div *ngIf="content.sectionElements" class="steps w-100">
                <div *ngFor="let section of content.sectionElements[0].content.content">
                    <h4 class="item-title">{{section.title}}</h4>
                    <div class="rectangle"></div>
                    <h4 class="item-description">{{section.subtitle}}</h4>
                </div>
            </div>
        </div>
        <div *ngIf="content.siteCta" class="d-flex align-items-center justify-content-center mt-5 gap-2">
            <a *ngFor="let cta of content.siteCta" class="text-decoration-none" href={{cta.buttonLink}}>
                <button class="btn btn-primary cta-button" [ngStyle]="setColors(cta)">{{cta.buttonTitle}}</button>
            </a>
        </div>
    </div>
</div>