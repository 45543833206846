import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-companies-board',
  templateUrl: './companies-board.component.html',
  styleUrls: ['./companies-board.component.scss']
})
export class CompaniesBoardComponent {
  @Input() companiesData: any;
  @Input() backgroundColor: string;
  @Input() fontColor: string;

  currentEnvironment: string = environment.base_api;
  style: string;

  constructor() { }
  
  ngOnInit(): void {
    this.style = "--background-color: " + this.backgroundColor +
    "; --font-color: " + this.fontColor;
  }
}