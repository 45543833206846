import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { CareerService } from "src/app/shared/services/career.service";
import { Job } from "src/app/shared/models/job";
import { environment } from "src/environments/environment";
import { UsersService } from "src/app/shared/services/users.service";
import { UtilService } from "src/app/shared/services/util.service";
import { AuthService } from "src/app/shared/services/auth.service";

export function validateName(control: AbstractControl) {
  if (control.value.split(" ").length <= 1) {
    return { invalidName: true };
  }
  return null;
}
@Component({
  selector: "app-career",
  templateUrl: "./career.component.html",
  styleUrls: ["./career.component.scss"],
})
export class CareerComponent implements OnInit {
  @ViewChild("modalForm") modalFormRef;

  error: boolean;
  groupSelectiveProcess: Map<string, Array<any>> = new Map();
  keysSelectiveProcesses;
  loading: boolean = false;
  selectiveProcess: Array<Job> = [];
  title: string;
  userLogged: boolean;
  environment: any = environment;

  constructor(
    private careerService: CareerService,
    private util: UtilService,
    private userService: UsersService,
    private authService: AuthService
  ) {
    this.util.createCanonicalURL();
    this.userService.userIsLogged().subscribe((status) => {
      this.userLogged = status;
    });
  }

  ngOnInit(): void {
    this.title = "Trabalhe Conosco";

    this.getJobs();
  }

  openLoginModal(): void {
    this.authService.openModalLogin();
  }

  openModalJob(): void {
    this.modalFormRef.open();
  }

  getJobs(): void {
    this.setLoading(true);
    this.careerService.getJobs().subscribe(
      (res) => {
        if (res && res.sps && res.sps.length) {
          res.sps.forEach((job) => {
            this.selectiveProcess.push(job);
          });
          this.groupSelectiveProcess = new Map(
            Object.entries(this.groupByType(this.selectiveProcess))
          );
        }
        this.setError(false);
        this.setLoading(false);
      },
      (err) => {
        this.setError(true);
        this.setLoading(false);
      }
    );
  }

  groupByType(array) {
    return array.reduce((r, a) => {
      r[a.cityName] = r[a.cityName] || [];
      r[a.cityName].push({ a });
      return r;
    }, Object.create(null));
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  setError(error: boolean): void {
    this.error = error;
  }

  ngAfterContentChecked() {
    if (
      this.groupSelectiveProcess &&
      this.groupSelectiveProcess.size &&
      !this.loading
    ) {
      this.getKeys();
    }
  }

  getKeys() {
    this.keysSelectiveProcesses = this.groupSelectiveProcess.keys();
  }
}
