import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(public utils: UtilService, public route: ActivatedRoute, private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loaderService.startLoader();
    this.utils.getPaymentLink(this.route.snapshot.paramMap.get('id')).subscribe(result => {
      this.loaderService.stopLoader();
      if(result._singleValue) window.location.href = result._singleValue;
      else window.location.href = environment.ecommerce_url + 'cardPaymentLink';
    }, err => {
      this.loaderService.stopLoader();
      window.location.href = environment.ecommerce_url + 'cardPaymentLink';
    })
  }

}
