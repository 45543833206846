<div class="error-container">
    <div class="error-404 col col-lg-8">
        <div class="error-title">
            <img width="70px" height="70px" src="assets/images/404-icon.svg" />
            <div class="title"> Não encontramos nenhum cadastro relacionado a esse link! </div>
        </div>

        <div class="error-description">
            Em caso de dúvidas, entre em contato com o nosso time de atendimento pelo whatsapp.
        </div>

        <div class="whatsapp-button" (click)="onClickButton()">
            <img src="assets/icons/whatsapp.svg">
            falar com atendimento
        </div>
    </div>
</div>
