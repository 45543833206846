<ng-template *ngIf="isModal" #privacy_content let-modal>
    <div class="modal-header" id="modal-title">
        <h4 class="my-auto modal-title">{{title}}</h4>
        <sy-button class="dismiss-button" content="icon-only" icon="close" type="default" target="_blank"
            button-type="button" (click)="modal.dismiss()"></sy-button>
    </div>
    <div class="modal-body" id="privacy-body">
        <div *ngIf="openTDU && tdu" [innerHtml]="tdu | safeHtml"></div>
        <div *ngIf="openTDU && !tdu"> Termo de uso não disponível.</div>
    </div>
</ng-template>

<main *ngIf="!isModal">
    <app-title title="Termos de uso"></app-title>
    <div class="terms-of-use">
        <div *ngIf="tdu" class="text" [innerHTML]="tdu | safeHtml"></div>
    </div>
</main>