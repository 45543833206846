import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../../shared/services/util.service'

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicy: any;

  constructor(private util: UtilService) {
    this.util.createCanonicalURL();
    this.util.getPrivacyPolicy().subscribe((result) => {
      this.privacyPolicy = result.privacyPolicy;
    });
  }

  ngOnInit(): void {}

}
