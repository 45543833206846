import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-how-works',
  templateUrl: './how-works.component.html',
  styleUrls: ['./how-works.component.scss']
})
export class HowWorksComponent implements OnInit {

  @Input() pageContent;
  @Input() origin;

  public videoLink;
  public nameMethod = "";
  currentEnvironment: string = environment.base_api;

  constructor(
    private sanitized: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.nameMethod = this.origin && this.origin === "affiliatedPage" ? "getAffiliatedImages" : "getImagesSite";

    this.getVideoUrl();
  }

  private getVideoUrl(){
    if(this.pageContent && this.pageContent.sectionElements && this.pageContent.sectionElements.length){
      const element = this.pageContent.sectionElements.find(pC => pC.contentName && pC.contentName === "video");
      if(element && element.content && element.content.content)
        this.videoSanitizer(element.content.content);

    }
  }

  private videoSanitizer(videoLink): void{
    this.videoLink = this.sanitized.bypassSecurityTrustResourceUrl(videoLink);
  }

}
