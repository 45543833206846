import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-how-it-works",
  templateUrl: "./how-it-works.component.html",
  styleUrls: ["./how-it-works.component.scss"],
})
export class HowItWorksComponent implements OnInit {
  @Input() content: any;
  
  environment: any = environment;

  constructor() {}

  ngOnInit(): void {
    if (!this.content || !Object.keys(this.content).length) {
      this.content = {
        siteCta: [
          {
            backgroundColor: "#F4822C",
            borderColor: "#F4822C",
            buttonTitle: "VER VALORES",
            buttonLink: "drlavatudo.com/loja",
            fontColor: "#FFFFFFF",
          },
        ],
        subtitle: "Todo o processo de solicitação, agendamento e pagamento é digital. Você tem total controle para escolher o que atende suas necessidades.",
        title: "Como funciona?",
        sectionElements: [
          {
            content: {
              content: [
                {
                  subtitle: "Você escolhe os serviços em nossa loja on-line",
                  title: "1. Selecionar itens",
                },
                {
                  subtitle: "Faz o agendamento: escolhe o local, dia e hora",
                  title: "2. Agendar",
                },
                {
                  subtitle: "Você pode dividir em até 10 vezes no cartão, ou pagar utilizando PIX",
                  title: "3. Pagar",
                },
                {
                  subtitle: "Com nossas soluções de limpeza você vive o melhor de sua casa",
                  title: "4. Relaxar",
                },
              ],
            },
          },
        ],
      };
    }
  }

  setColors(cta) {
    return {
      "background-color": cta.backgroundColor,
      "border-color": cta.borderColor,
      "color": cta.fontColor,
    }
  }
}
