import { Injectable, EventEmitter, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpService } from './http.service';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UtilService {

    public cartOpen = new BehaviorSubject(false);
    private updateCart: EventEmitter<boolean> = new EventEmitter();

    constructor(private httpService: HttpService, @Inject(DOCUMENT) private dom) { }

    logout = (): void => {
        localStorage.removeItem('explorer_user_map');
        // localStorage.removeItem('shoppingCart');
    }

    public getRatingCart(address: any): Observable<any> {
        if (!address.cityRef || !address.cityRef._id) {
            return of({ "_singleValue": false });
        }

        let url = '?zipcode=' + address.zipcode + '&cityId=' + address.cityRef._id;
        if (address.coordinates && address.coordinates && address.coordinates.coordinates && address.coordinates.coordinates.length > 0) {
            url += '&lon=' + address.coordinates.coordinates[0] + '&lat=' + address.coordinates.coordinates[1];
        }

        // return this.httpService.get('drLavaTudo/loggedAreaAPI/getRatingCart' + url, undefined, true);
        return this.httpService.get('drLavaTudo/ecommerceAPI/getRatingCart' + url);
    }

    getWhatsappNumber(context?: string): Observable<any> {
        const parameters = context ? "?context=" + context : "";
        return this.httpService.get('drLavaTudo/ecommerceAPI/getWhatsapp' + parameters);
    }

    getPrivacyPolicy(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getPrivacyPolicy');
    }

    getTDU(type?: string): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getTDU' + (type ? "?type=" + type : ""));
    }

    getCommonQuestions(page?: string): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getCommonQuestions' + (page ? '?page=' + page : ''));
    }

    getCouponList(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getCouponList');
    }

    getTestimonies(origin: string): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getTestimonies?origin=' + origin);
    }

    getSEOPage(indentifier): any {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getSEOPage?indentifier=' + indentifier);
    }

    searchZipcode(zipcode: string, onlyActive?: boolean): Observable<any> {
        let params = '?zipcode=' + zipcode;
        if (onlyActive) {
            params += "&onlyActive=true";
        }

        return this.httpService.get('drLavaTudo/ecommerceAPI/getUnitByZipcode' + params);
    }

    getServiceBeforeAfter(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getServiceBeforeAfter');
    }

    getCity(textSearched?: string): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getCity' + (textSearched ? "?textSearched=" + textSearched : ""));
    }

    getPaymentLink(id: string): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getNewPaymentLink?id=' + id);
    }

    getAttendedsCitys(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getAttendedsCitys');
    }

    getRecentsPosts(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getRecentsPosts');
    }

    getIndicationData(identifier: string): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getIndicationData?identifier=' + identifier);
    }

    getDynamicContentFromSite(pageContent?: string): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getDynamicContentFromSite' + (pageContent ? '?pageContent=' + pageContent : ''));
    }

    getAffiliatedDynamicContent(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getAffiliatedDynamicContent');
    }

    getSiteFooterSeals(type?: string) {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getSiteFooterSeals?type=' + type);
    }

    getCampaignParameters(): any {
        let parameters: any = {};
    
        parameters.utmParams = JSON.parse(localStorage.getItem("params"));
        parameters.gclid = JSON.parse(localStorage.getItem("gclid"));
        parameters.fbclid = JSON.parse(localStorage.getItem("fbclid"));
    
        parameters.userAgent = window.navigator.userAgent;
        parameters.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        parameters.screenHeigth = (window.innerHeight > 0) ? window.innerHeight : screen.height;
        parameters.url = window.location.href;
        parameters.title = document.title;
    
        return parameters;
    }

    getPlanPrices(): any {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getPlanPrices');
    }

    getContentLandingPage(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getContentLandingPage');
    }

    getCompaniesForBoard() {
        return this.httpService.get('drLavaTudo/ecommerceAPI/getCompaniesForBoard');
    }

    createCanonicalURL(): void {
        let link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', this.dom.URL);
    }
}

