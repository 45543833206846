<div class="home overflow-hidden">
  <div class="header">
    <div class="container">
      <div class="header-elements">
        <div class="header-title">
          <div class="d-flex h-100">
            <div class="description d-flex flex-column">
              <h1>
                <b
                  >Higienização e Impermeabilização de mais de 50 itens como
                  Sofás, Estofados e Tapetes
                </b>
                a partir de R$29,90/mês
              </h1>

              <h3>
                E mais: Atendimento em 2 horas, benefícios exclusivos e
                descontos em todos serviços da Dr. Lava Tudo aprovados por mais
                500.000 clientes
              </h3>

              <a
                class="text-decoration-none"
                target="_blank"
                href="https://promocao.drlavatudo.com/lp/115033/drprime"
                ><button class="btn btn-primary">QUERO ASSINAR</button></a
              >
            </div>
          </div>
        </div>
        <div class="header-image">
          <div class="description-images">
            <div class="images-position">
              <div class="square square-small"></div>
              <img src="assets/images/home/cleanning-1.webp" />
              <img src="assets/images/home/cleanning-2.webp" />
              <img src="assets/images/home/cleanning-3.webp" />
              <div class="square square-big"></div>
              <div class="square square-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="como-funciona">
    <div class="container">
      <h2 class="mt-lg-5">
        <p class="title-green text-margin text-center">Como funciona?</p>
      </h2>

      <div class="como-funciona__content">
        <div class="como-funciona__content__items">
          <div class="como-funciona__content__items__item">
            <div class="como-funciona-item">
              <div class="como-funciona-item__number">
                <div class="bubbled-number">1</div>
              </div>
              <div class="como-funciona-item__text">
                <strong>Escolha quantos serviços deseja</strong> por ano de
                acordo com sua necessidade
              </div>
            </div>
          </div>

          <div class="como-funciona__content__items__item">
            <div class="como-funciona-item">
              <div class="como-funciona-item__number">
                <div class="bubbled-number">2</div>
              </div>
              <div class="como-funciona-item__text">
                <strong>Pague online um valor mensal</strong> sem nenhum
                adicional
              </div>
            </div>
          </div>

          <div class="como-funciona__content__items__item">
            <div class="como-funciona-item">
              <div class="como-funciona-item__number">
                <div class="bubbled-number">3</div>
              </div>
              <div class="como-funciona-item__text">
                <strong>Tenha acesso aos serviços em até 2 horas</strong> e aos
                melhores prestadores
              </div>
            </div>
          </div>

          <div class="como-funciona__content__items__item">
            <div class="como-funciona-item">
              <div class="como-funciona-item__number">
                <div class="bubbled-number">4</div>
              </div>
              <div class="como-funciona-item__text">
                <strong>Receba mais de R$500</strong> em descontos e benefícios
                em parceiros
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="vantagens-exclusivas"></div>

  <div class="servicos-disponiveis"></div>

  <div class="beneficios"></div>

  <div class="satisfacao-garantida"></div>

  <div class="servicos"></div>

  <div class="perguntas-frequentes"></div>
</div>
