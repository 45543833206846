import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {

    public isLoading = new BehaviorSubject(false);

    constructor() { }

    startLoader(): void {
        this.isLoading.next(true);
    }

    stopLoader(): void {
        this.isLoading.next(false);
    }

}
