import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
    selector: 'app-custom-loader',
    templateUrl: './custom-loader.component.html',
    styleUrls: ['./custom-loader.component.scss'],
})
export class CustomLoaderComponent implements OnInit {

    loading: boolean;

    constructor(private loaderService: LoaderService) {
        this.loaderService.isLoading.subscribe((value: boolean) => {
            this.loading = value;
        });
    }

    ngOnInit = (): void => {
    }

}
