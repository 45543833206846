<main>
  <app-title [title]="title"></app-title>
  <div class="body-style career-job">
    <div class="container pt-5">
      <!-- Loading-->
      <div *ngIf="loading">
        <app-loading class="loading text-center"></app-loading>
      </div>

      <div *ngIf="!loading && error">
        <div class="no-job-available mb-3">
          <h2 class="sub-title-jobs text-center">
            Ocorreu um erro e não conseguimos carregar esta vaga no momento.
            Tente novamente mais tarde.
          </h2>
        </div>
      </div>

      <div class="row justify-content-between " *ngIf="job && !error">
        <div class="col-lg-6">
          <div class="job-detail-description d-flex mb-5">
            <a class="btn-link btn-link-back pl-0" routerLink="/trabalhe-conosco">
              <i class="las la-arrow-left"></i>
              VAGAS ABERTAS</a>
          </div>

          <h1 class="job-detail-title mb-4">{{ job.name }}</h1>

          <p class="job-detail-subtitle">
            {{ job.cityName }}, {{ job.city.stateInitials }}
            <span>&#8226;</span> {{ job.quantity }}
            {{ job.quantity == 1 ? "vaga" : "vagas" }}
          </p>

          <div *ngIf="job.jobDetails && job.jobDetails.length">
            <div class="job-section" *ngFor="let detail of job.jobDetails">
              <h2>{{detail.header}}</h2>
              <div [ngSwitch]="detail.component">
                <div class='job-detail-subtitle' *ngSwitchCase="'p'" [innerHTML]="detailParagraph(detail)"></div>
                <div class="list-details" *ngSwitchCase="'ul'" [innerHTML]="detailList(detail)"></div>
                <div *ngSwitchCase="'accordion'">
                  <div *ngFor="let accordionDetail of detail.accordionDetails">
                    <mat-accordion class="accordion-job">
                      <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                          {{accordionDetail.header}}
                        </mat-expansion-panel-header>
                        <div class="job-detail-subtitle" [ngSwitch]="accordionDetail.component">
                          <div class="mt-4" *ngSwitchCase="'p'" [innerHTML]="detailParagraph(accordionDetail)"></div>
                          <div class="list-details mt-4" *ngSwitchCase="'ul'"
                            [innerHTML]="detailList(accordionDetail)"></div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 job-detail-subscription">
          <h4 class="job-detail-subscription-title">Junte-se a nós</h4>
          <p class="job-detail-subscription-subtitle mb-4">
            Buscamos pessoas incríveis para fazer coisas incríveis. Candidate-se
            para esta oportunidade.
          </p>
          <button *ngIf="userLogged" id="vagas" class="btn job-detail-subscription py-3"
            (click)="openModalJob(modalJob)">
            Inscreva-se
          </button>


          <button *ngIf="!userLogged" id="vagas" class="btn job-detail-subscription py-3"
            (click)="openLoginModal()">
            Cadastrar
          </button>
        </div>
      </div>
    </div>
  </div>
</main>

<app-career-job-form #modalForm> </app-career-job-form>