import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/pages/terms-of-use/terms-of-use.component';
import { ContactComponent } from './components/pages/contact/contact.component';

import { SiteComponent } from './site.component';
import { PageLocalSEOComponent } from './components/pages/page-local-seo/page-local-seo.component';
import { CareerComponent } from './components/pages/career/career.component';
import { CareerJobComponent } from './components/pages/career-job/career-job.component';
import { FrequentlyAskedQuestionsComponent } from './components/pages/frequently-asked-questions/frequently-asked-questions.component';
import { SpecialDealsComponent } from './components/pages/special-deals/special-deals.component';
import { PrimeComponent } from './components/pages/prime/prime.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';

const siteRoutes: Routes = [
  {
    path: '',
    component: SiteComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'contato', component: ContactComponent },
      { path: 'trabalhe-conosco', component: CareerComponent },
      { path: 'trabalhe-conosco/vaga/:id', component: CareerJobComponent },
      { path: 'perguntas-frequentes', component: FrequentlyAskedQuestionsComponent },
      { path: 'perguntas-frequentes/:id', component: FrequentlyAskedQuestionsComponent },
      { path: 'politica-de-privacidade', component: PrivacyPolicyComponent },
      { path: 'termos-de-uso', component: TermsOfUseComponent},
      { path: 'tdu-chat-web', component: TermsOfUseComponent },
      { path: 'desconto-especial', component: SpecialDealsComponent},
      { path: 'prime', component: PrimeComponent},
    ]
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    RouterModule.forChild(siteRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SiteRoutingModule { }
