import { HttpService } from "../../shared/services/http.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Coordinates } from "../models/coordinates";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

/**
 * Serviço de manipulação de CEP.
 */
@Injectable()
export class LocationService {
  constructor(private httpService: HttpService, private http: HttpClient) {}

  /**
   * Obter dados de benefícios por plano
   */
  public findByZipCode(zipCode: string): Observable<any> {
    return this.httpService.post(
      "drLavaTudo/ecommerceAPI/findAddressByZipCode",
      {
        zipcode: zipCode,
      }
    );
  }

  /**
   * Perguntar a localização para o usuário
   */
  public askLocation(): Promise<Coordinates> {
    return new Promise<Coordinates>((resolve) => {
      if (!("geolocation" in navigator)) {
        resolve(null);
      }
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const lat = Number(pos.coords.latitude);
          const lon = Number(pos.coords.longitude);
          const coords = <Coordinates>{
            lat: lat,
            lon: lon,
          };
          resolve(coords);
        },
        (err) => {
          console.log(err);
          resolve(null);
        },
        { timeout: 5000 }
      );
    });
  }

  public findCoordinatesByZipCode(zipcode: string): Observable<any> {
    return this.httpService.get('drLavaTudo/ecommerceAPI/findCoordinatesByZipCode?zipcode=' + zipcode);
  }

  public findAddressByCoordinates(coordinates, headers): Observable<any>{
    const data = {
      coordinates,
      headers
    }
    return this.httpService.post('drLavaTudo/ecommerceAPI/getAddressByCoordinates', data, false)
  }

  /**
   * Armazenar coordenadas no localstorage e adicionar data em que expiram
   */
  public storeCoords(coords: Coordinates) {
    let expiryTime = new Date();
    expiryTime.setHours(expiryTime.getHours() + 2);
    let storeCoords = <any>coords;
    storeCoords.expiryTime = expiryTime;
    localStorage.setItem("coords", JSON.stringify(storeCoords));
  }

  /**
   * Retornar coordenadas se estiverem armazenadas no localstorage e não tiverem expirado
   */
  public getStoredCoords() {
    let storedCoords: { lat: number; lon: number; expiryTime: Date } =
      JSON.parse(localStorage.getItem("coords"));

    if (
      storedCoords &&
      new Date().getTime() < new Date(storedCoords.expiryTime).getTime()
    ) {
      return storedCoords;
    } else {
      return null;
    }
  }
}
