<header id="landing-page-header">
    <img *ngIf="backgroundImage" class="header-background-image" [src]="backgroundImage" alt="Imagem de fundo do cabeçalho">
    <div class="header d-flex flex-column align-items-center justify-content-center">
        <img *ngIf="origin !== 'affiliatedPage'" src="assets/images/logo_ai.svg" alt="Dr. Lava Tudo" width="171px">
        <sy-text class="headline" type="h3"><b>{{headerContent.headline}}</b></sy-text>
        <sy-text class="subheadline" type="h6">{{headerContent.subheadline}}</sy-text>

        <div *ngIf="headerContent.siteCta" class="cta-style">
            <div *ngFor="let cta of headerContent.siteCta" >
                <sy-button
                    *ngIf="!cta.loginButton && !loading"
                    style={{setColors(cta)}}
                    label={{cta.buttonTitle}}
                    href={{cta.buttonLink}}
                    content="text-only"
                    fab="true"
                    target={{target}}
                ></sy-button>

                <a [href]="registrationStatus === 'initial' ? 'afiliados#affiliated-form' : affiliatedPortalUrl">
                    <sy-button
                        *ngIf="cta.loginButton && isLoged"
                        style={{setColors(cta)}}
                        label={{cta.buttonLogedTitle}}
                        content="text-only"
                        fab="true"
                    ></sy-button>
                </a>

                <sy-button
                    *ngIf="cta.loginButton && !isLoged"
                    style={{setColors(cta)}}
                    (click)="openModal()"
                    label={{cta.buttonTitle}}
                    content="text-only"
                    fab="true"
                ></sy-button>
            </div>
        </div>
    </div>    
</header>
