import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UsersService } from 'src/app/shared/services/users.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-page-for-companies',
  templateUrl: './page-for-companies.component.html',
  styleUrls: ['./page-for-companies.component.scss']
})
export class PageForCompaniesComponent implements OnInit {
  nameFormControl = new FormControl('', [Validators.required]);
  cityFormControl = new FormControl('', [Validators.required]);
  companyFormControl = new FormControl('', [Validators.required]);
  detailsFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [Validators.required, Validators.email,]);
  companySizeFormControl = new FormControl('', [Validators.required]);
  interestFormControl = new FormControl('', [Validators.required]);
  confirmationFormControl = new FormControl(false, [Validators.required]);
  forCompaniesPage: any;
  videoLink: any;
  satisfactionBoard: any;
  companiesBoard: any;
  budgetForm: any;
  defaultContent: boolean;
  companiesData: any;
  currentEnvironment: string = environment.base_api;
  forCompaniesStyle: string;
  footerSeals: any[] = [];
  footerSealsString: string;
  footerLogo: string;

  processing: boolean;
  confirmation: boolean = false;
  formData: any = {
    "name": '',
    "email": '',
    "city": '',
    "company": '',
    "companySize": '',
    "interest": '',
    "details": '',
    "reason": 'sac',
    "confirmation": false
  };
  config2: SwiperConfigInterface = {
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
      770: {
        slidesPerView: 2,
        spaceBetween: 20,
        direction: 'vertical'
      },
    },
  };
  footerStyle: string;
  menuItems: any;

  getEmailErrorMessage(): string {
    if (this.emailFormControl.hasError('required')) return 'Este campo é obrigatório.';

    return this.emailFormControl.hasError('email') ? 'E-mail inválido.' : '';
  }

  ngOnInit(): void {
    this.getPageContent();
  }

  verifyConfirmation(sufix: string): void {
    if (sufix) {
      if (sufix == "error-interest") document.getElementById('error-interest').style.display = 'none';
      if (sufix == "error-size") document.getElementById('error-size').style.display = 'none';
      if (sufix == "error-confirmation") document.getElementById('error-confirmation').style.display = 'none';
    }
  }

  onSave(): any {
    this.formData.name = this.nameFormControl.value;
    this.formData.email = this.emailFormControl.value;
    this.formData.city = this.cityFormControl.value;
    this.formData.company = this.companyFormControl.value;
    this.formData.details = this.detailsFormControl.value;
    this.formData.companySize = this.companySizeFormControl.value;
    this.formData.interest = this.interestFormControl.value;
    this.confirmation = this.confirmationFormControl.value;

    if (!this.formData.name || !this.formData.email || !this.formData.city ||
      !this.formData.company) {
      window.location.href = '/para-empresas#form-page';
      return;
    }
    if (!this.formData.details) {
      window.location.href = '/para-empresas#details-session';
      return;
    }
    if (!this.formData.interest) {
      window.location.href = '/para-empresas#company-session';
      document.getElementById('error-interest').style.display = 'block';
      return;
    }
    if (!this.formData.companySize) {
      window.location.href = '/para-empresas#company-session';
      document.getElementById('error-size').style.display = 'block';
      return;
    }
    if (!this.confirmation) {
      window.location.href = '/para-empresas#confirmation-session';
      document.getElementById('error-confirmation').style.display = 'block';
      return;
    }
    this.processing = true;
    this.userService.sendEmailToCompanies(this.formData).subscribe(resp => {
      this.processing = false;
      document.getElementById('success').style.display = 'block';
      window.location.href = '/para-empresas#form-page';
      document.getElementById('form-sucess').style.display = 'none';
    });
  }

  constructor(
    private userService: UsersService, 
    private util: UtilService, 
    private sanitized: DomSanitizer
  ) { 
    this.util.createCanonicalURL(); 
  }

  private getPageContent(): void {
    this.util.getDynamicContentFromSite('forCompaniesContent').subscribe(
      result => {
        if(result.categories && result.categories.length){
          this.menuItems = JSON.stringify(result.categories);
        }else this.menuItems = "";
        this.forCompaniesPage = result;
        this.getVideoUrl();
        this.budgetForm = this.forCompaniesPage.pageContent.find(content => content.sectionName === "budgetForm");
        this.satisfactionBoard = this.forCompaniesPage.pageContent.find(content => content.sectionName === "testimonies");
        this.companiesBoard = this.forCompaniesPage.pageContent.find(content => content.sectionName === "companiesBoard");
        this.forCompaniesStyle = "--header-background-color:" + this.forCompaniesPage.header.backgroundColor +
          "; --header-font-color:" + this.forCompaniesPage.header.fontColor +
          "; --differential-section-color: " + this.forCompaniesPage.backgroundColorDifferentialSection +
          "; --content-background-color: " + this.forCompaniesPage.backgroundColorContentSection +
          "; --content-font-color: " + this.forCompaniesPage.fontColorContentSection +
          "; --footer-background-color: " + this.forCompaniesPage.backgroundColorContentSection;
        if (this.companiesBoard.active) {
          this.util.getCompaniesForBoard().subscribe(resp => {
            this.companiesData = resp;
          });
        };
        if (this.forCompaniesPage.footerConfig) {
          this.footerStyle = "; --footer-subtitle-font-color: " + this.forCompaniesPage.footerConfig.subtitleFontColor +
            "; --footer-title-font-color: " + this.forCompaniesPage.footerConfig.tittleFontColor +
            "; --footer-link-font-color: " + this.forCompaniesPage.footerConfig.linkFontColor;
          this.forCompaniesStyle += this.footerStyle;

          if (this.forCompaniesPage.footerConfig.DLTLogo && this.forCompaniesPage.footerConfig.DLTLogo._id) {
            this.footerLogo = this.currentEnvironment + "/api/1/portal/drLavaTudo/ecommerceAPI/downloadForCompanies?imageId=" + this.forCompaniesPage.footerConfig.DLTLogo._id;
          }
        }
      }
    )

    this.util.getSiteFooterSeals('enterprise').subscribe({
      next: resp => {
        resp.images.forEach(item => {
          const src = this.currentEnvironment + "/api/1/portal/drLavaTudo/ecommerceAPI/downloadSiteFooterSeals?imageId=" + item.image._id
          this.footerSeals.push({
            _id: item.image._id,
            src: src,
            altText: item.altText,
            link: item.link
          })
        })
        this.footerSealsString = JSON.stringify(this.footerSeals);
      }
    })
  }

  public setColors(cta) {
    if (cta) {
      return {
        "--sy-button-background-color": cta.backgroundColor,
        "--sy-button-background-color-active": cta.backgroundColor,
        "--sy-button-background-color-hover": cta.backgroundColor,
        "--sy-button-background-color-focus": cta.backgroundColor,
        "--sy-button-border-color": cta.borderColor,
        "--sy-button-border-color-active": cta.borderColor,
        "--sy-button-border-color-hover": cta.borderColor,
        "--sy-button-border-color-focus": cta.borderColor,
        "--sy-button-color": cta.fontColor
      }
    } else {
      return {
        "--sy-button-background-color": "#ea8f2e",
        "--sy-button-background-color-active": "#e58117",
        "--sy-button-background-color-hover": "#e98821",
        "--sy-button-background-color-focus": "#e98821",
        "--sy-button-border-color": "transparent",
        "--sy-button-border-color-active": "transparent",
        "--sy-button-border-color-hover": "transparent",
        "--sy-button-border-color-focus": "transparent",
        "--sy-button-color": "white"
      }
    }
  }

  getVideoUrl() {
    if (this.forCompaniesPage && this.forCompaniesPage.header && this.forCompaniesPage.header.headerVideo)
      this.videoLink = this.sanitized.bypassSecurityTrustResourceUrl(this.forCompaniesPage.header.headerVideo);
  }
}