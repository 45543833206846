import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceAuthHeader } from '../../shared/constants/service-auth-token';

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) { }

  private API_URL: string = environment.api_url;
  private API_URL_ECOMMERCE: string = environment.api_url_ecommerce;
  private API_HEADERS: HttpHeaders = new HttpHeaders();
  private accountToken: string = environment['X-Explorer-Account-Token'];

  private setHeaders(loggedArea?: boolean, body?: any): void {
    if (loggedArea) {
      let explorer_user = JSON.parse(localStorage.getItem("explorer_user_map"));

      if (explorer_user?.ecommerce?.users[0]?.accessToken?.token) {
        this.API_HEADERS = this.API_HEADERS.set('Authorization', 'Bearer ' + explorer_user.ecommerce.users[0].accessToken?.token)
          .set('x-explorer-account-token', this.accountToken)
          .delete('x-explorer-authentication-class');
      } else {
        this.API_HEADERS = this.API_HEADERS.set('Authorization', 'Basic ' + body.authorization)
          .set('x-explorer-authentication-class', 'crm.person')
          .set('x-explorer-account-token', this.accountToken);
      }
    } else {
      this.API_HEADERS = this.API_HEADERS.set('Authorization', serviceAuthHeader)
        .set('x-explorer-account-token', this.accountToken)
        .delete('x-explorer-authentication-class');
    }
  }

  private getUrl(target: string, loggedArea?: boolean): string {
    return (loggedArea ? this.API_URL_ECOMMERCE : this.API_URL) + target;
  }

  post(target: string, body: object, loggedArea?: boolean): Observable<any> {
    this.setHeaders(loggedArea, body);
    return this.http.post(this.getUrl(target, loggedArea), body, {
      headers: this.API_HEADERS,
    });
  }

  get(target: string, body?: any, loggedArea?: boolean, header?: any): Observable<any> {
    this.setHeaders(loggedArea, body);
    return this.http.get(this.getUrl(target, loggedArea), {
      headers: header ? this.API_HEADERS.set(header.key, header.value) : this.API_HEADERS,
    });
  }
}
