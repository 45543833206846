import { C as ColorHelper } from './color-helpers-9f65db9e.js';

// src/lib/app-utils.ts
function getConfig(packageIdentifier) {
  if (!window.sydleApp?.config?.[packageIdentifier]) {
    return null;
  }
  return window.sydleApp?.config?.[packageIdentifier];
}

/**
 * Insert style tokens into html body using package definitions
 */
function setGlobalTokens() {
  var _a;
  const sydleUiConfig = getConfig('sydle-ui');
  if (!sydleUiConfig) {
    return;
  }
  const primaryColor = sydleUiConfig.primaryColor;
  const accentColor = sydleUiConfig.secondaryColor;
  const globalCSSTokens = sydleUiConfig.customTokens;
  let style = {};
  if (primaryColor) {
    style = Object.assign(Object.assign({}, style), { '--sy-primary': primaryColor });
    const variants = ColorHelper.getColorVariations(primaryColor);
    variants.forEach((variant) => {
      style = Object.assign(Object.assign({}, style), { [`--sy-primary-${variant.name}`]: variant.value });
    });
  }
  if (accentColor) {
    style = Object.assign(Object.assign({}, style), { '--sy-accent': accentColor });
    const variants = ColorHelper.getColorVariations(accentColor);
    variants.forEach((variant) => {
      style = Object.assign(Object.assign({}, style), { [`--sy-accent-${variant.name}`]: variant.value });
    });
  }
  globalCSSTokens === null || globalCSSTokens === void 0 ? void 0 : globalCSSTokens.forEach((element) => {
    style = Object.assign(Object.assign({}, style), { [element.token]: element.value });
  });
  const styleElement = document.createElement('style');
  styleElement.innerHTML = `body ${(_a = JSON.stringify(style)) === null || _a === void 0 ? void 0 : _a.replace(/"/g, '').replace(/,/g, ';')}`;
  document.head.appendChild(styleElement);
}
setGlobalTokens();
