<div class="image-area-men">
    <div class="rectangle-rounded rectangle-men-light-green"></div>
    <div class="rectangle-rounded rectangle-men-green"></div>
    <div class="background-room">
        <img class="men" src="assets/images/home/men.webp">
        <div class="living-room"></div>
        <div class="technician-id">
            <p class="technician-title">Pedro Sampaio</p>
            <p class="technician-description">Desde 2010 com a Dr. Lava Tudo</p>
            <p class="d-flex flex-row star">
                <i *ngFor="let stars of [1,2,3,4,5]" class="las la-star"></i>
            </p>
        </div>
    </div>
</div>