<section>
    <div class="title-container" id="title-container">
        <div class="title-content">
            <div class="description">
                <img class="dlt-logo" src="assets/images/logo_ai.svg" alt="Logo Dr. Lava Tudo">
    
                <div *ngIf="header" class="title-class">
                    <sy-text type="h1">{{header.headline}}</sy-text>
                    <sy-text type="body">{{header.subheadline}}</sy-text>
                </div>
            
                <div *ngIf="header && header.ctas" class="cta-style">
                    <sy-button
                        *ngFor="let cta of header.ctas"
                        label={{cta.buttonTitle}}
                        type="default"
                        target="_self"
                        [style]="setCustomStyle(cta)"
                        class="custom-sy-button"
                        href={{cta.buttonLink}}
                    ></sy-button>
                </div>
            </div>
            <img 
                class="main-image" 
                *ngIf="header && header.mainImage" 
                src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/downloadImageNewSignature?imageId={{header.mainImage._id}}" alt="Imagem principal da landing page">
        </div>
    </div>

    <div class="how-works">
        <sy-text type="h3">Entenda como funciona:</sy-text>
        <div class="works-content">
            <section *ngFor="let howWork of howWorks">
                <div class="step-work">{{howWork.step}}</div>
                <sy-text type="body">{{howWork.text}}</sy-text>
            </section>
        </div>
    </div>

    <div class="list-services">
        <div class="services-title">
            <sy-text type="h6">Nossos serviços <b>para você!</b></sy-text>
            <sy-text type="body">Escolha o plano que mais faz sentido pra sua casa.</sy-text>
        </div>

        <div class="card-container">
            <div class="card-content">
                <div class="card-item" *ngFor="let item of cards">
                    <div class="d-flex flex-row gap-4">
                        <img class="card-img" src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/downloadImageNewSignature?imageId={{item.imageId}}">
                        <div class="card-description">
                            <sy-text type="h4">{{item.name}}</sy-text>
                            <sy-text type="body">{{item.description}}</sy-text>
                            <section>
                                <sy-text type="body" *ngFor="let availableIn of item.availableIn">• {{availableIn}}</sy-text>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="benefits-container" [style]="setBenefitsStyle(benefits.backgroundImageId)" *ngIf="benefits && benefits.benefitsDescription && benefits.benefitsDescription.length">
        <div class="benefits-content">
            <div *ngFor="let benefit of benefits.benefitsDescription">
                <img src="/assets/images/check-circled.svg" alt="Check icon">
                <section>
                    <sy-text type="h3">{{benefit.title}}</sy-text>
                    <sy-text type="body">{{benefit.subtitle}}</sy-text>
                </section>
            </div>
        </div>

        <div class="cta-style">
            <sy-button
                label="ASSINAR"
                type="default"
                target="_self"
                class="custom-sy-button"
                href="/assinatura/precos"
            ></sy-button>
        </div>
    </div>

    <div class="testimony-container">
        <sy-text type="h3">Mais de 500.000 clientes!</sy-text>
        <div *ngIf="testimony" class="testimony-content">
            <div>
                <img src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/downloadImageNewSignature?imageId={{testimony.imageId}}" alt="Avatar pessoa">
                <sy-text type="lead">{{ testimony.name }}</sy-text>
            </div>
            <div>
                <sy-text type="lead">{{ testimony.testimony }}</sy-text>
            </div>
        </div>
    </div>

    <div class="cta-style solo-cta">
        <sy-button
            label="CONHECER OS PLANOS"
            type="default"
            target="_self"
            class="custom-sy-button"
            href="/assinatura/precos"
        ></sy-button>
    </div>

    <div class="diff-container" *ngIf="diffs && diffs.length">
        <div class="diff-content" *ngFor="let diff of diffs" [style.background-color]="diff.backgroundColor">
            <sy-text type="h3" [style.color]="diff.color">{{diff.title}}</sy-text>
            <div class="diff-sections">
                <section *ngFor="let section of diff.sections">
                    <img 
                        [src]="section.hasBenefit ? '/assets/images/check-circled.svg' : '/assets/images/close_white.svg'"
                        [class]="section.hasBenefit ? null : 'red-marked'"
                        [alt]="section.hasBenefit ? 'Melhores condições' : 'Condições normais'" 
                    />
                    
                    <sy-text type="body" [style.color]="diff.color">{{section.text}}</sy-text>
                </section>
            </div>
        </div>
    </div>

    <div class="cta-style solo-cta" *ngIf="diffs && diffs.length">
        <sy-button
            label="ASSINAR"
            type="default"
            target="_self"
            class="custom-sy-button"
            href="/assinatura/precos"
        ></sy-button>
    </div>

    <div *ngIf="commonQuestions" class="common-questions">
        <sy-text type="h3" >Perguntas frequentes</sy-text>
        <dlt-accordion [commonQuestionsList]="commonQuestions"></dlt-accordion>
    </div>
</section>

<app-footer></app-footer>