//Components
import { HomeComponent } from './components/pages/home/home.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { SiteComponent } from './site.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { PrimeComponent } from './components/pages/prime/prime.component';
import { TermsOfUseComponent } from './components/pages/terms-of-use/terms-of-use.component';
import { FrequentlyAskedQuestionsComponent } from './components/pages/frequently-asked-questions/frequently-asked-questions.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { AccordionComponent } from 'src/app/shared/components/accordion/accordion.component';
import { SpecialDealsComponent } from './components/pages/special-deals/special-deals.component';
import { TestimonyComponentComponent } from '../shared/components/testimony-component/testimony-component.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CareerComponent } from './components/pages/career/career.component';
import { CareerJobComponent } from './components/pages/career-job/career-job.component';
import { CareerJobFormComponent } from './components/pages/career-job-form/career-job-form.component';
import { TitleComponent } from './components/shared/title/title.component';
import { PageLocalSEOComponent } from './components/pages/page-local-seo/page-local-seo.component';

// Modules
import { MatMenuModule } from '@angular/material/menu';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { SiteRoutingModule } from './site-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';

// Services
import { HttpService } from '../shared/services/http.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SnackBarService } from '../shared/services/snackbar.service';
import { LoaderService } from '../shared/services/loader.service';
import { UtilService } from '../shared/services/util.service';
import { ErrorHandlingService } from '../shared/services/errorHandling.service';
import { UsersService } from '../shared/services/users.service';
import { CareerService } from '../shared/services/career.service';
import { GtmService } from '../shared/services/gtm.service';
import { TrackingService } from '../shared/services/tracking.service';
import { LocationService } from '../shared/services/location.service';

// Pipes
import { SEOService } from '../shared/services/seo.service';
import { AuthService } from '../shared/services/auth.service';
import { MetaService } from '../shared/services/meta.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatRadioModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    TextMaskModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SiteRoutingModule,
    NgbModule,
    SwiperModule,
    SharedModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    RouterModule,
    CommonModule,
    SiteComponent,
    HeaderComponent,
    PrivacyPolicyComponent,
    PrimeComponent,
    TitleComponent,
    SwiperModule,
    TermsOfUseComponent,
    PageNotFoundComponent,
    TestimonyComponentComponent
  ],
  providers: [
    HttpService,
    SnackBarService,
    LoaderService,
    UtilService,
    UsersService,
    AuthService,
    CareerService,
    GtmService,
    MetaService,
    LocationService,
    SEOService,
    TrackingService,
    HttpService,
    ErrorHandlingService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingService, multi: true },
  ],
  declarations: [
    SiteComponent,
    HomeComponent,
    HeaderComponent,
    FrequentlyAskedQuestionsComponent,
    PrivacyPolicyComponent,
    PrimeComponent,
    TitleComponent,
    TermsOfUseComponent,
    PageNotFoundComponent,
    SpecialDealsComponent,
    ContactComponent,
    PageLocalSEOComponent,
    CareerComponent,
    CareerJobComponent,
    CareerJobFormComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SiteModule {}
