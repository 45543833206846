import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import { UtilService } from "src/app/shared/services/util.service";
import { environment } from "src/environments/environment";
import { UsersService } from "src/app/shared/services/users.service";

type navButtonType = {
  name: string;
  link?: string;
  action?: () => void
};

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnChanges {
  public isCollapsed = false;
  public iconsExibithion = true;
  public customer: any;
  public customerPhoto: string = undefined;
  public customerPortalUrl: string;
  public environment: any = environment;
  public userToken: string;
  public loggedInButtonString: string;
  public loggedOutButtonString: string;

  @Input() menuExibithion = false;
  @Input() origin;
  @Input() affiliatedPortalUrl;
  @Input() registrationStatus;
  @Input() loggedInButton: navButtonType;
  @Input() loggedOutButton: navButtonType;

  @Output() customerData: EventEmitter<any> = new EventEmitter();

  menuItems;

  constructor(
    private route: ActivatedRoute,
    private utilService: UtilService,
    private userService: UsersService,
    private authService: AuthService
  ) {
    this.userService.userData().subscribe({
      next: (value) => {
        if (!value || Object.keys(value).length === 0) {
          return;
        }

        this.setUserToken()

        this.customer = value;
        this.customerPhoto = environment.api_url_ecommerce + "drLavaTudo/loggedAreaAPI/getCustomerPhoto?accessToken=" + this.userToken;
        this.menuExibithion = true;
      },
    });
  }

  ngOnInit(): void {
    this.customerPortalUrl = environment.customer_portal_url;
    this.userService.setLoginStatus(!!this.userToken);
    this.menuExibithion = !!(this.userToken);
    this.iconsExibithion = this.isloginPage() ? false : true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loggedInButtonString = this.loggedInButton ? JSON.stringify(this.loggedInButton) : "";
    this.loggedOutButtonString = this.loggedOutButton ? JSON.stringify(this.loggedOutButton) : "";
  }

  ngAfterViewInit(): void {
    const whatsappLogin = localStorage.getItem("whatsappLogin");

    if (whatsappLogin) {
      this.openModal();
    }
  }

  setUserToken(): void {
    const explorer_user = JSON.parse(localStorage.getItem("explorer_user_map"));
    this.userToken = explorer_user?.ecommerce?.users[0]?.accessToken?.token ? explorer_user.ecommerce.users[0].accessToken.token : null;
  }

  isloginPage(): boolean {
    return (
      this.route.children.filter((child) => {
        return child.snapshot.routeConfig.path.includes("login");
      }).length > 0
    );
  }

  logout = (): void => {
    this.userService.logOut();
    this.utilService.logout();
    window.location.reload();
  };

  toggleMenu(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  openModal(): void {
    this.authService.openModalLogin();
  }

  firstAccessImage(): string {
    if (this.customer && this.customer.email) {
      return this.customer.email.substring(0, 1).toUpperCase();
    }

    return "U";
  }

  contentClicked(e) {
    // Caso mudar o tamanho do menu, altere este valor também
    const menuWidth = 500;

    if (e.clientX > menuWidth && window.innerWidth > 768) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
