<div class="partner-form">
    <p>Olá {{name}}! Você passou pela primeira etapa do seu cadastro!</p>
    <div class="info-content w-100">
        <div class="info my-1" *ngIf="phone"> • <span class="bold"> Telefone: </span> {{phone}} </div>

        <div class="info my-1" *ngIf="email"> • <span class="bold"> E-mail: </span> {{email}} </div>

        <div class="info my-1" *ngIf="city"> • <span class="bold"> Cidade: </span> {{city}} </div>
    </div>

    <p>Agora será necessário acessar o <strong>Portal do Parceiro</strong> para concluir seu cadastro. Clica no botão
        abaixo para receber um e-mail explicando o passo a passo.</p>

    <div id="sendEmailContainer" style="display: flex; justify-content: center">
        <sy-button label="Receber e-mail" (click)="sendEmail(false)"></sy-button>
    </div>

    <div id="resendEmailContainer" style="display: none;">
        <p id="successSendingTitle" >E-mail enviado com sucesso!</p>

        <p>Caso não tenha recebido, clique no botão abaixo:</p>

        <div class="d-flex justify-content-center mb-4">
            <sy-button label="Reenviar e-mail" (click)="sendEmail(true)"></sy-button>
        </div>

        <p class="mb-0">Lembre-se de conferir a caixa de spam.</p>
    </div>
</div>