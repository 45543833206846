import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-pages-header',
  templateUrl: './landing-pages-header.component.html',
  styleUrls: ['./landing-pages-header.component.scss']
})
export class LandingPagesHeaderComponent implements OnInit{
  @Input() headerContent;
  @Input() loading;
  @Input() origin;
  @Input() isLoged;
  @Input() affiliatedPortalUrl;
  @Input() registrationStatus;

  public environment = environment;
  public nameMethod = "";
  public target;
  public backgroundImage: string;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private authService: AuthService
  ) { }
  
  ngOnInit(): void {
    this.nameMethod = this.origin && this.origin === "affiliatedPage" ? "getAffiliatedImages" : "getImagesSite";
    this.target = this.origin && this.origin === "PageBePartner" ? "_self" : "_blank";
    
    if(this.headerContent && this.headerContent.backgroundImage){
      this.backgroundImage = `${this.environment.base_api}/api/1/portal/drLavaTudo/ecommerceAPI/${this.nameMethod}?objId=${this.headerContent.backgroundImage._id}`;
    }

    if(this.headerContent.pageDescription){
      this.meta.addTag({
        name: "description",
        content: this.headerContent.pageDescription,
      });
    }

    if(this.headerContent.pageTitle){
      this.titleService.setTitle(this.headerContent.pageTitle);
    }
  }
  public setColors(cta) {
    if(cta) {
      return {
        "--sy-button-background-color": cta.backgroundColor,
        "--sy-button-background-color-active": cta.backgroundColor,
        "--sy-button-background-color-hover": cta.backgroundColor,
        "--sy-button-background-color-focus": cta.backgroundColor,
        "--sy-button-border-color": cta.borderColor,
        "--sy-button-border-color-active": cta.borderColor,
        "--sy-button-border-color-hover": cta.borderColor,
        "--sy-button-border-color-focus": cta.borderColor,
        "--sy-button-color": cta.fontColor
      }
    } else {
      return {
        "--sy-button-background-color": "#ea8f2e",
        "--sy-button-background-color-active": "#e58117",
        "--sy-button-background-color-hover": "#e98821",
        "--sy-button-background-color-focus": "#e98821",
        "--sy-button-border-color": "transparent",
        "--sy-button-border-color-active": "transparent",
        "--sy-button-border-color-hover": "transparent",
        "--sy-button-border-color-focus": "transparent",
        "--sy-button-color": "white"
      }
    }
  }

  public openModal(): void {
    this.authService.openModalLogin();
  }
}
