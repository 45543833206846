<app-title [title]="title"></app-title>
<div class="d-flex justify-content-center content">
    <div class="text">
        
        <div id="form-page" class="d-xl-flex justify-content-center">
            <form id="form-sucess" [formGroup]="formContact">
            <h6>Fale conosco e tire suas dúvidas</h6>
                <div class="form-group">
                    <mat-form-field floatLabel='always' appearance="outline">
                        <mat-label for="name">Nome </mat-label>
                        <input placeholder="Digite seu nome" type="text" formControlName="name" id="name" name="name" 
                             matInput required>
                        <mat-error *ngIf="f.name.errors && f.name.errors.required">Nome deve ser preenchido</mat-error>
                        <mat-error *ngIf="f.name.errors && !f.name.errors.required && f.name.errors.invalidName">Deve ser nome completo</mat-error>
                    </mat-form-field>
                    <br>
                    <mat-form-field floatLabel='always' appearance="outline">
                        <mat-label for="email"> E-mail </mat-label>
                        <input placeholder="Digite seu e-mail" type="text" formControlName="email" id="email" name="email"
                             matInput required>
                        <mat-error *ngIf="f.email.errors && f.email.errors.required">Email deve ser preenchido</mat-error>
                    </mat-form-field>
                    <br>
                    <mat-form-field floatLabel='always' appearance="outline">
                        <mat-label for="telefone">Telefone </mat-label>
                        <input [textMask]="getPhoneNumberTextMask()" type="text" formControlName="phone" id="phone" name="phone"  matInput required>
                        <mat-error *ngIf="f.phone.errors && f.phone.errors.required">Telefone deve ser preenchido</mat-error>
                    </mat-form-field>
                    <br>
                    <mat-form-field class="mb-3" floatLabel='always' appearance="outline">
                        <mat-label> Cidade </mat-label>
                        <input type="text" formControlName="city" id="city" name="city"  matInput required>
                        <mat-error *ngIf="f.city.errors && f.city.errors.required">Cidade deve ser preenchido</mat-error>
                    </mat-form-field>
                    <br>
                    <mat-radio-group formControlName="company">
                        <mat-label> Você é uma empresa? </mat-label><br>
                        <mat-radio-button value="Sim">Sim </mat-radio-button><br>
                        <mat-radio-button class="mb-3" value="Não">Não</mat-radio-button><br>
                    </mat-radio-group>
                    <br>
                    <mat-radio-group formControlName="reason" required>
                        <mat-label> Como podemos ajudar? </mat-label><br>
                        <mat-radio-button value="budget">Orçamento </mat-radio-button><br>
                        <mat-radio-button value="query">Dúvida</mat-radio-button><br>
                        <mat-radio-button value="sac">Atendimento ao cliente (SAC)</mat-radio-button><br>
                        <mat-radio-button value="providers">Fornecedores</mat-radio-button><br>
                        <mat-radio-button value="other">Outros</mat-radio-button><br>
                    </mat-radio-group>
                    <mat-form-field floatLabel='always' appearance="outline">
                        <mat-label> Mensagem: </mat-label>
                        <textarea class="mensage" type="text" formControlName="mensage" id="mensage" name="mensage" matInput></textarea>
                    </mat-form-field>
                    <br>
                    <mat-checkbox formControlName="checkbox" class="example-margin" required>                        
                            <label class="label">Confirmo que aceito receber os e-mails da Dr. Lava Tudo no endereço preenchido acima*</label> 
                        
                    </mat-checkbox>
                    <br>
                    <app-loading class="loading" *ngIf="processing" ></app-loading>
                    <button (click)="send()" class="button mt-4 button" [disabled]="f.name.errors || f.email.errors || f.phone.errors || f.city.errors || f.checkbox.errors || f.reason.errors">ENVIAR</button>
                    
                </div>
            </form>
            <div id="success" class="gray-box">
                <p>Formulário enviado com sucesso!</p>
            </div>
            
            <div class="phone">
                <div class="d-flex title">
                    <img src="assets/images/pin.svg">
                    <h6 class="tel">Telefone</h6>
                </div>
                <p>Nacional: 0800 944 9999</p>
                <button (click)="onClickButton()"> <img src="assets/icons/whatsapp.svg"> Mande um WhatsApp</button>
            </div>
        </div>
    </div>