<div *ngIf="!formSend" class="partner-form" [@inOutAnimation]>
    <h5>Faça parte do nosso time!</h5>
    <p>Preencha seu e-mail e confirme seu cadastro</p>
    <form [formGroup]="formContact">
        <div class="form-group">
            <div>
                <mat-form-field floatLabel='always' appearance="outline">
                    <mat-label for="name">Nome Completo do responsável</mat-label>
                    <input type="text" formControlName="name" id="name" name="name" matInput required>
                    <mat-error *ngIf="f.name.errors && f.name.errors.required">Nome deve ser preenchido</mat-error>
                    <mat-error *ngIf="f.name.errors && !f.name.errors.required && f.name.errors.invalidName">Nome completo inválido.</mat-error>
                </mat-form-field>
                <br>
                <mat-form-field floatLabel='always' appearance="outline">
                    <mat-label for="phone">Telefone com WhatsApp</mat-label>
                    <input [textMask]="getPhoneNumberTextMask()" type="text" formControlName="phone" id="phone"
                        name="phone" matInput required>
                    <mat-error *ngIf="f.phone.errors && f.phone.errors.required">Campo deve ser preenchido</mat-error>
                    <mat-error *ngIf="f.phone.errors && !f.phone.errors.required && f.phone.errors.invalidPhone">Número de telefone inválido.</mat-error>
                </mat-form-field>
                <br>
                <mat-form-field floatLabel='always' appearance="outline">
                    <mat-label for="email">E-mail</mat-label>
                    <input type="text" formControlName="email" id="email" name="email" matInput required>
                    <mat-error *ngIf="f.email.errors && f.email.errors.required">Campo deve ser preenchido</mat-error>
                    <mat-error *ngIf="f.email.errors && !f.email.errors.required && f.email.errors.invalidEmail">E-mail inválido.</mat-error>
                </mat-form-field>
                <br>
                <mat-form-field floatLabel='always' class="select city-icon" appearance="outline">
                    <mat-label class="city">Cidade</mat-label>
                    <input type="text" formControlName="city" (ngModelChange)="textSearchedUpdate.next($event)" [matAutocomplete]="auto" matInput required>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                        <mat-option *ngIf="loadingCityOptions" class="loading-option" disabled>
                            <div>
                                <app-loading></app-loading>
                            </div>
                        </mat-option>
                        <mat-option class="city-helper" *ngIf="(!filterCities || !filterCities.length) && !loadingCityOptions" disabled>
                            <div>
                                <span>Digite ao menos as 3 primeiras letras da cidade para buscar. </span>
                            </div>
                        </mat-option>
                        <mat-option *ngFor="let i of (!loadingCityOptions ? filterCities : [])" [value]="i._id">{{formatNameWithState(i)}}</mat-option>
                    </mat-autocomplete>                    
                    <mat-error *ngIf="f.city.errors && f.city.errors.required">Campo deve ser preenchido</mat-error>
                    <mat-error *ngIf="f.city.errors && !f.city.errors.required && f.city.errors.incompleteSearch">Digite ao menos 3 letras para buscar.</mat-error>
                    <mat-error *ngIf="f.city.errors && !f.city.errors.required && !f.city.errors.incompleteSearch && f.city.errors.invalidCity">Escolha uma cidade da lista.</mat-error>
                </mat-form-field>
                <br>
            </div>
            <button
                [disabled]="(f.name.errors || f.phone.errors || f.email.errors || f.city.errors)"
                (click)="send()" class="avance">
                <div *ngIf="processing">
                    <app-loading></app-loading>
                </div>
                <div *ngIf="!processing">
                    ENVIAR
                </div>
            </button>
        </div>
    </form>
</div>

<div *ngIf="formSend" [@inOutAnimation] class="d-flex flex-column align-items-center text-center partner-form p-5">
    <img src="assets/images/check-circled.svg" width="60" *ngIf="showImage">
    <h5 *ngIf="title">{{title}}</h5>
    <p class="mb-0" *ngIf="subtitle">{{subtitle}}</p>
</div>
