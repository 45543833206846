import { Component, OnInit } from '@angular/core';
import { UtilService } from '../shared/services/util.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
})
export class SiteComponent implements OnInit {

  private routerList = ['desconto-especial'];
  public showHeader : boolean = true;
  currentEnvironment: string = environment.base_api;
  footerSeals: any[] = [];
  footerSealsString: string;
  constructor(private utilService: UtilService) { }

  ngOnInit(): void {
    this.checkHeaderVisibility();
    this.utilService.getSiteFooterSeals().subscribe({
      next: resp => {
        resp.images.forEach(item => {
          const src = this.currentEnvironment + "/api/1/portal/drLavaTudo/ecommerceAPI/downloadSiteFooterSeals?imageId="+ item.image._id
          this.footerSeals.push({
            _id: item.image._id,
            src: src,
            altText: item.altText,
            link: item.link
          })          
        });
        
        this.footerSealsString = JSON.stringify(this.footerSeals);
      }
    })
  }

  private checkHeaderVisibility(){
    this.routerList.forEach(router => {
      if(window.location.href.match(router)) this.showHeader = false;
    });
  }


}
