<div class="session-confidence w-100" *ngIf="companiesData" [style]=" style | safeStyle ">
    <div class="confidence-board">
        <div class="title">
            <p>Quem confia em nossos serviços</p>
        </div>
        <div class="stamps">
            <div class="companies" *ngFor="let i of companiesData">
                <img src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/downloadForCompanies?imageId={{i.imageId}}" alt="{{i.companieName}}">
            </div>
        </div>      
    </div>
</div>