<div *ngIf="posts && posts.length && content">
    <div class="d-flex flex-column align-items-center">
        <h2 class="title p-3">{{content.title}}</h2>
        <div class="container position-relative">
            <div class="swiper-container" [swiper]="config">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let post of posts">
                        <div class="js-slide" style="z-index: 1">
                            <a href="/blog/{{post.url}}" class="d-block">
                                <div class="d-flex justify-content-center mb-5">
                                    <article class="card shadow dlf-featured-article-carousel">
                                        <div class="row no-gutters justify-content-between">
                                            <div class="col-md img">
                                                <img *ngIf="post.icon"
                                                    src="{{currentEnvironment}}/api/1/blog/drLavaTudo/blogFacade/getImage?objId={{post.id}}&fileId={{post.icon._id}}"
                                                    alt="Imagem principal">
                                                <img *ngIf= "!post.icon" src="assets/images/SELO.png" alt="Imagem Padrão">
                                            </div>
                                            <div class="col-md">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="d-flex align-items-start h-100">
                                                        <div class="pt-2 px-4 pt-md-4 px-md-5">
                                                            <h2 class="text-carousel">{{post.name}}</h2>
                                                            <p class="d-none d-lg-block">{{post.description}}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="d-flex justify-content-between border-top pt-3 m-3 m-md-5">
                                                        <div class="d-inline-block text-uppercase text-primary">
                                                            {{post.category.name}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </article>
                                </div>

                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="config.navigation" id="city-button-prev" class="swiper-button-prev btn d-none d-xl-flex">
            </div>
            <div *ngIf="config.navigation" id="city-button-next" class="swiper-button-next btn d-none d-xl-flex">
            </div>
            <div *ngIf="config.pagination" id="recent-pagination" class="d-xl-none swiper-pagination"></div>
        </div>
    </div>
</div>