import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from './snackbar.service';
import { UtilService } from './util.service';
import { environment } from "src/environments/environment";

@Injectable()
export class ErrorHandlingService implements HttpInterceptor {
  // Map to parse the error messages from server to user friendly
  // messages
  private errorMap = {
    'Preço não configurado.': 'Serviço indisponível.',
  };

  constructor(
    private snackbarService: SnackBarService,
    private utilService: UtilService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        this.handleError(error);
        return throwError(error);
      })
    );
  }

  private parseMessage = (original: string): string => {
    const regexp = new RegExp('{"generalMessages":.*');

    if (!regexp.test(original)) {
      original = original.replace(/^com.sydle.one.*: /, '');
    } else {
      const jsonErr = regexp.exec(original);
      const objErr = JSON.parse(jsonErr[0]);
      original = objErr.generalMessages[0].text;
    }

    const invalidCard = 'Número do cartão informado não é válido';
    if (original.indexOf(invalidCard) >= 0) {
      original += ' Por favor, verifique se os dados informados estão corretos.';
    }

    return this.errorMap[original] || original;
  }

  private showErrorMessage(error: any): void {
    error.generalMessages.forEach(message => {
      if (message.type === 'ERROR') {
        const errorMessage = this.parseMessage(message.text);
        this.snackbarService.error(errorMessage);
      }
    });
  }

  private processError  = (errorResponse: any): Array<string> => {
    let errorMessages;

    if (errorResponse && errorResponse.generalMessages && errorResponse.generalMessages) {
      errorMessages = errorResponse.generalMessages.map(message => {
        // Erro no formato
        // com.sydle.one ...
        // billing ...
        // Método: ...
        // Script: ...
        // {"generalMessages":
        // [{"type":"ERROR" ...
        // ...
        const regexp = new RegExp('{"generalMessages":.*');
        // Se não está nesse formato, volta o erro do jeito que está
        if (!regexp.test(message.text)) {
          return message.text.replace(/^com.sydle.one.*: /, '');
        }

        const jsonErr = regexp.exec(message.text);
        const objErr = JSON.parse(jsonErr[0]);
        const textErr = objErr;

        return textErr;
      });
    }

    return errorMessages;
  }

  private expireSession(): void {
    const explorer_user = JSON.parse(localStorage.getItem("explorer_user_map"));
    let userLogged;

    if(explorer_user?.ecommerce?.users[0]?.accessToken?.token) userLogged = true;

    if (!userLogged) {
      return;
    }

    this.snackbarService.warning('A sessão expirou')
      .afterDismissed()
      .subscribe(() => {
        this.utilService.logout();
        this.openSessionModal();
      });
  }

  public openSessionModal(): void {
    let dialogContainer = document.body.querySelector('dlt-login');
    if (!dialogContainer) {
      dialogContainer = document.createElement('dlt-login');
      document.body.appendChild(dialogContainer);
    }
    
    dialogContainer.dialogTitle = "Sua sessão expirou";
    dialogContainer.open();
  }

  public handleError(response: HttpErrorResponse): void {
    switch (response.status) {
      case 400:
        this.showErrorMessage(response.error);
        break;
      case 401:
        console.log('401');
        this.expireSession();
        break;
      case 403:
        console.log('403');
        this.expireSession();
        break;
    }
  }
}
