<div class="d-flex w-100 align-items-center flex-column">
  <h3><b> Confira se atendemos sua região! </b></h3>
  <sy-input id="Mzipcode" value={{zipcode}} (syInputDidInput)="checkAttendence(null)"
    placeholder="Digite aqui seu CEP" icon="search" icon-position="left" type="text" class="hydrated input ratingZipcode"
    mask="99999-999" [inputmaskOptions]="{ placeholder: '', jitMasking: true }">
    <sy-icon name="house-1" slot="icon" role="img" path="assets/icons" family="streamline-light" aria-label="house 1"
      class="hydrated search"></sy-icon>
  </sy-input>
  <sy-text *ngIf="city && attendenceMessage"> {{attendenceMessage}} </sy-text>
  <sy-text *ngIf="city" class="mb-2">Cidade: {{city}}</sy-text>
  <app-loading *ngIf="processing"></app-loading>
  <div *ngIf="!processing && rating && rating.value > 0" class="map" (click)=redirectToMap()>
    <ngx-rating-stars [rating]="rating" [style]="ratingStyle" [showRatingInNumericFormat]="false">
    </ngx-rating-stars>
  </div>

  <div class="container h-100 mw-100 position-relative p-0" *ngIf="plans && plans.length">
    <div class="swiper-container" [swiper]="swiperConfig">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let plan of plans">
          <dlt-card class="plan-cards" style={{setColors(plan)}}>
            <sy-tag *ngIf="plan.label" class="tag" slot="tag"><span class="p-2">{{plan.label}}</span></sy-tag>
            <dlt-card-header class="card-title w-100 mb-0" slot="header" headline={{plan.name}}>
            </dlt-card-header>
            <dlt-card-content style="--sy-card-content-padding: 5px 15px;" slot="content">
              <div [ngStyle]="{'color': plan.color}" class="content">
                <p class="m-0" style="text-decoration: line-through;" *ngIf="plan.promocionalPrice">R${{plan.fullPrice}}</p>
                <h1 class="price mt-2 mb-1" *ngIf="plan.promocionalPrice">R${{plan.promocionalPrice}}</h1>
                <h1 class="price mt-2 mb-1" *ngIf="!plan.promocionalPrice">R${{plan.fullPrice}}</h1>
                <p class="m-0">/mês</p>
                <sy-button name={{plan.id}} (click)="getPosition($event)" class="cta my-4">{{plan.cta}}</sy-button>
              </div>
              <div class="planItens">
                <div *ngFor="let item of plan.itens" class="d-flex align-items-center pb-3">
                  <img class="footer-check" src="assets\images\check-circled.svg">
                  <p class="mb-0 items" [ngStyle]="{'color': plan.color}">{{item}}</p>
                </div>
              </div>
            </dlt-card-content>
          </dlt-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="plans" class="container">
  <div [ngClass]="this.plans.length == 1? 'justify-content-center' : 'justify-content-between'" class="d-flex mt-5">
    <h2 class="plan-title" *ngFor="let plan of plans"> {{plan.name}}</h2>
  </div>
  <div class="d-flex justify-content-center title">
    <p>Preço por ano</p>
  </div>

  <div [ngClass]="this.plans.length == 1? 'justify-content-center' : 'justify-content-between'" class="d-flex">
    <div class="planPrice" *ngFor="let plan of plans">
      R${{plan.anualPrice}}
    </div>
  </div>

  <sy-divider type="default" class="hydrated"></sy-divider>


  <div style="text-align: center;" class="d-flex justify-content-center title">
    <p>Descontos em outros serviços</p>
  </div>

  <div [ngClass]="this.plans.length == 1? 'justify-content-center' : 'justify-content-between'" class="d-flex">
    <div class="planPrice" *ngFor="let plan of plans">
      {{plan.discount}}%
    </div>
  </div>

  <sy-divider type="default" class="hydrated"></sy-divider>


  <div class="d-flex justify-content-center title">
    <p>Economia anual</p>
  </div>


  <div [ngClass]="this.plans.length == 1? 'justify-content-center' : 'justify-content-between'" class="d-flex">
    <div class="planPrice" *ngFor="let plan of plans">
      R${{plan.anualEconomy}}
    </div>
  </div>

  <sy-divider type="default" class="hydrated"></sy-divider>

  <div class="d-flex justify-content-center title">
    <p>Serviços gratuitos</p>
  </div>

  <div [ngClass]="this.plans.length == 1? 'justify-content-center' : 'justify-content-between'" class="d-flex">
    <div class="planPrice" *ngFor="let plan of plans">
      {{plan.freeServices}}
    </div>
  </div>

  <sy-divider type="default" class="hydrated"></sy-divider>

  <div class="d-flex justify-content-center title">
    <h3 class="title">Serviços disponíveis</h3>
  </div>

  <table class="w-100">
    <tr>
      <td style={{size}}></td>
      <td style={{size}} *ngFor="let plan of plans"> {{plan.name}}</td>
    </tr>
    <tr *ngFor="let h of highlight">
      <td style={{size}}>{{h.name}}</td>
      <td style={{size}} *ngFor="let disponibility of h.disponibility;">
        <sy-icon *ngIf="disponibility === false" name="subtract" path="assets/icons"
          family="streamline-light"></sy-icon>
        <img class="check" *ngIf="disponibility === true" src="assets\images\check-circled.svg">
      </td>
    </tr>
    <tr *ngFor="let l of list">
      <td style={{size}}>{{l.name}}</td>
      <td style={{size}} *ngFor="let disponibility of l.disponibility;">
        <sy-icon *ngIf="disponibility === false" name="subtract" path="assets/icons"
          family="streamline-light"></sy-icon>
        <img class="check" *ngIf="disponibility === true" src="assets\images\check-circled.svg">
      </td>
    </tr>
    <tr *ngIf="standardQntd > 0">
      <td class="more-product" (click)="showList()" style={{size}}> +{{standardQntd}} produtos</td>
      <td style={{size}} *ngFor="let standard of standard.standardPlans">
        <sy-icon *ngIf="standard === false" name="subtract" path="assets/icons" family="streamline-light"></sy-icon>
        <img class="check" *ngIf="standard === true" src="assets\images\check-circled.svg">
      </td>
    </tr>
  </table>


  <div *ngIf="benefits" class="d-flex justify-content-center title">
    <h3 class="title">Clube de benefícios</h3>
  </div>

  <table *ngIf="benefits" class="w-100">
    <tr *ngFor="let b of benefits">
      <td style={{size}}>{{b.name}}</td>
      <td style={{size}} *ngFor="let disponibility of b.disponibility;">
        <sy-icon *ngIf="disponibility === false" name="subtract" path="assets/icons"
          family="streamline-light"></sy-icon>
        <img class="check" *ngIf="disponibility === true" src="assets\images\check-circled.svg">
      </td>
    </tr>
  </table>

  <div class="enterprise-card">
    <sy-card-content slot="content" class="d-flex align-items-center justify-content-between enterprise-content">
      <div class="enterprise">
        <h1 class="enterprise-title">
          Plano Empresarial
        </h1>
        <p style="color: white;">Entre em contato com nossos atendentes e desenvolva o plano perfeito pra suas
          necessidades.</p>
      </div>
      <sy-button (click)="contactSalle()" class="cta-enterprise mr-5">Entrar em Contato</sy-button>
    </sy-card-content>
  </div>
</div>

<app-footer></app-footer>