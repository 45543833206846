<div class="cleanning">
  <div class="container position-relative" *ngIf="cards && cards.length">
    <div class="swiper-container" [swiper]="configCleanning">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let cardItem of cards; let i = index">
          <div class="card-cleanning">
              <div class="card-icon row h-100 m-0 position-relative">
                <div class="col-6 p-3 position-relative h-100 img-left" [ngStyle]="{
                    'background-image': 'url(' + cardItem.photoBeforeUrl + ')'
                  }">
                  <div class="d-flex h-100 align-items-end justify-content-center">
                    <button class="btn btn-before">Antes</button>
                  </div>
                  
                </div>
                <div class="col-6 p-3 position-relative h-100 img-right" [ngStyle]="{
                    'background-image': 'url(' + cardItem.photoAfterUrl + ')'
                  }">
                  <div class="d-flex h-100 align-items-end justify-content-center">
                    <button class="btn btn-after">Depois</button>
                  </div>
                </div>
              </div>
            <div class="card-title">
              <small>{{ cardItem.title }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="configCleanning.navigation" id="button-prev-cleanning"
      class="swiper-button-prev btn btn-secondary d-none d-lg-flex"></div>
    <div *ngIf="configCleanning.navigation" id="button-next-cleanning"
      class="swiper-button-next btn btn-secondary d-none d-lg-flex"></div>
    <div *ngIf="configCleanning.pagination" id="cleanning-pagination" class="swiper-pagination"></div>
  </div>
</div>