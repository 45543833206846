import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/shared/services/users.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-be-an-affiliated',
  templateUrl: './be-an-affiliated.component.html',
  styleUrls: ['./be-an-affiliated.component.scss']
})
export class BeAnAffiliatedComponent implements OnInit {
  public affiliatedPageContent: any;
  public currentEnvironment: string = environment.base_api;
  public questionsList: any;
  public portalUrl: string;
  public isLoged = false;
  loading = true;
  public registrationStatus = "not logged in";
  public environment: any = environment;
  public loggedInButton;
  public loggedOutButton = {
    "name": "Entrar",
    "action": "login"
  };

  constructor(
    private util: UtilService,
    private userService: UsersService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.portalUrl = environment.affiliated_portal_url;
    this.getPageContent();
    this.userService.userIsLogged().subscribe((status) => {
      this.isLoged = status;
      if(this.isLoged){
        this.getAffiliatedStatus();
      }

      this.loading = false;
    });
  }

  private getPageContent = ():void => {
    this.loaderService.startLoader();
    
    this.util.getDynamicContentFromSite("affiliatedContent").subscribe(
      result => {
        this.affiliatedPageContent = result;
        this.loggedInButton = result.pageHeader.navButton;

        this.loaderService.stopLoader();

        if(result && result.pageContent && result.pageContent.some(item => item && item.active && item.sectionName === "commonQuestions")) {
          this.util.getCommonQuestions("affiliatedPage").subscribe({
            next: result => {
              this.questionsList = result;
              
              document.querySelector('dlt-accordion').commonQuestionsList = this.questionsList;
            }
          });
        }
      },
      error => {
        this.loaderService.stopLoader();
      }
    )
  }

  public setColors(cta) {
    if(cta) {
      return {
        "--sy-button-background-color": cta.backgroundColor,
        "--sy-button-background-color-active": cta.backgroundColor,
        "--sy-button-background-color-hover": cta.backgroundColor,
        "--sy-button-background-color-focus": cta.backgroundColor,
        "--sy-button-border-color": cta.borderColor,
        "--sy-button-border-color-active": cta.borderColor,
        "--sy-button-border-color-hover": cta.borderColor,
        "--sy-button-border-color-focus": cta.borderColor,
        "--sy-button-color": cta.fontColor
      }
    } else {
      return {
        "--sy-button-background-color": "#ea8f2e",
        "--sy-button-background-color-active": "#e58117",
        "--sy-button-background-color-hover": "#e98821",
        "--sy-button-background-color-focus": "#e98821",
        "--sy-button-border-color": "transparent",
        "--sy-button-border-color-active": "transparent",
        "--sy-button-border-color-hover": "transparent",
        "--sy-button-border-color-focus": "transparent",
        "--sy-button-color": "white"
      }
    }
  }
  public openModal(): void {
    this.authService.openModalLogin();
  }

  private getAffiliatedStatus() {
    const explorer_user = JSON.parse(localStorage.getItem("explorer_user_map"));
    const userCode = explorer_user?.ecommerce?.users[0]?.code ? explorer_user.ecommerce.users[0].code : null;

    if(userCode) {
      const data = {
        "data": {
          "customerId": userCode
        },
      }

      this.userService.registrateAffiliated(data).subscribe((res) => {
        const userDidLogin = this.userService.getUserDidLogin();
        this.registrationStatus = res.goToPortal ? "finished" : "initial";
        if(this.registrationStatus === "initial") {
          this.loggedInButton.link = "afiliados#affiliated-form";
        }

        if(res.goToPortal && userDidLogin) {
          window.location.href = this.portalUrl;
        }
      });
    }
  }

  public hasActiveSection(sections):boolean{
    if(!sections || !sections.length) return false;
    return sections.find(s => s.active) ? true : false;
  }

  public setSectionClass(section, index, sectionLength){
    let sectionClass = []
    if(index === 0){
      switch(section.sectionName){
        case 'testimonies':
          sectionClass.push('testimony-bg');
          break;
        case 'howWorks':
        case 'commonQuestions':
        case 'form':
          sectionClass.push('top-spacing');
          break; 
      }
      if(section.sectionName === 'testimonies'){
        sectionClass.push('testimony-bg');
      }
      sectionClass.push('start-style');
      return sectionClass.join(' ');
    }else{
      if(!(index === (sectionLength - 1) && section.sectionName === "testimonies")){
        sectionClass.push('secondary-section');
        return sectionClass.join(' ');
      }
    }
  }
}
