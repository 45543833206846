<dlt-header 
    *ngIf="partnerPageContent && partnerPageContent.categories"
    [menuItems]="menuCategories"
></dlt-header>

<app-landing-pages-header [origin]="'PageBePartner'" [headerContent]="partnerPageContent.pageHeader"
    *ngIf="partnerPageContent && partnerPageContent.pageHeader"></app-landing-pages-header>
<div class="card-container d-lg-flex d-none flex-wrap justify-content-center"
    *ngIf="partnerPageContent && partnerPageContent.pageHeaderCards">
    <div *ngFor="let i of partnerPageContent.pageHeaderCards">
        <div class="card">
            <img src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId={{i.icon._id}}"
                class="card-title" *ngIf="i.icon && i.icon._id">
            <h6 class="card-subtitle mb-2 text-muted">{{i.title}}</h6>
            <p class="card-text">{{i.content}}</p>
        </div>
    </div>
</div>

<div class="card-container w-100 d-lg-none" *ngIf="partnerPageContent && partnerPageContent.pageHeaderCards">
    <div class="container">
        <div class="swiper-container" [swiper]="config">
            <div class="swiper-wrapper">
                <div class="swiper-slide dlf-site-banner-slide" *ngFor="let i of partnerPageContent.pageHeaderCards">
                    <div class="card">
                        <img src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId={{i.icon._id}}"
                            class="card-title" *ngIf="i.icon && i.icon._id">
                        <h6 class="card-subtitle mb-2 text-muted">{{i.title}}</h6>
                        <p class="card-text">{{i.content}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="partnerPageContent && partnerPageContent.pageContent && partnerPageContent.pageContent.length && hasActiveSection(partnerPageContent.pageContent)">
    <div *ngFor="let section of partnerPageContent.pageContent; let i = index" [ngClass]="setSectionClass(section, i, partnerPageContent.pageContent.length)">
        <div *ngIf="section.active && section.sectionName === 'howWorks'">
            <app-how-works class="app-how-works" [pageContent]="section.sectionContent" [origin]="'PageBePartner'"></app-how-works>
        </div>        
        <div class="testimony" [ngClass]="{'testimony-bg' : i > 0}"
            *ngIf="section.active && section.sectionName === 'testimonies'">
            <div class="d-flex justify-content-center align-items-end">
                <h4>Veja os depoimentos de alguns dos nossos Doutores e clientes</h4>
            </div>
            <app-testimony-component [customConfig]="config2" [origin]="'PageBePartner'"></app-testimony-component>
        </div>
        <div class="d-lg-flex flex-column justify-content-center align-items-center" id="form-page"
            *ngIf="section.active && section.sectionName === 'form'">
            <app-partner-form (sendForm)="sendForm()"></app-partner-form>
        </div>
    </div>
</div>

<app-footer></app-footer>