import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './site/components/pages/home/home.component';
import { FrequentlyAskedQuestionsComponent } from './site/components/pages/frequently-asked-questions/frequently-asked-questions.component';
import { PrivacyPolicyComponent } from './site/components/pages/privacy-policy/privacy-policy.component';
import { PrimeComponent } from './site/components/pages/prime/prime.component';
import { TermsOfUseComponent } from './site/components/pages/terms-of-use/terms-of-use.component';
import { SiteComponent } from './site/site.component';
import { PageNotFoundComponent } from './site/components/pages/page-not-found/page-not-found.component'
import { SpecialDealsComponent } from './site/components/pages/special-deals/special-deals.component';
import { ContactComponent } from './site/components/pages/contact/contact.component';
import { CareerComponent } from './site/components/pages/career/career.component';
import { CareerJobComponent } from './site/components/pages/career-job/career-job.component';
import { PageForCompaniesComponent } from './landing-pages/components/pages/page-for-companies/page-for-companies.component'
import { PageLocalSEOComponent } from './site/components/pages/page-local-seo/page-local-seo.component';
import { LandingPagesComponent } from './landing-pages/landing-pages.component';
import { BeAPartnerComponent } from './landing-pages/components/pages/be-a-partner/be-a-partner.component';
import { BeAnAffiliatedComponent } from './landing-pages/components/pages/be-an-affiliated/be-an-affiliated.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './checkout/components/payment/payment.component';
import { environment } from 'src/environments/environment';
import { PriceComponent } from './landing-pages/components/pages/newSignature/price/price.component';
import { LeadCaptureComponent } from './landing-pages/components/pages/newSignature/lead-capture/lead-capture.component';
import { NewSignature } from './landing-pages/components/pages/newSignature/newSignature/newSignature.component';


let subdomain = window.location.host.replace('www.', '').split('.')[0];

const checkoutRoute: Routes = [
  {
    path: 'os',
    component: CheckoutComponent,
    children: [
      {
        path: '',
        component: PaymentComponent,
      },
      {
        path: ':id',
        component: PaymentComponent,
      }
    ]
  },
  { path: '404', component: PageNotFoundComponent }
]

const appRoutes: Routes = [
  {
    path: '',
    component: SiteComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'contato', component: ContactComponent },
      { path: 'trabalhe-conosco', component: CareerComponent },
      { path: 'trabalhe-conosco/vaga/:id', component: CareerJobComponent },
      { path: 'perguntas-frequentes', component: FrequentlyAskedQuestionsComponent },
      { path: 'perguntas-frequentes/:id', component: FrequentlyAskedQuestionsComponent },
      { path: 'politica-de-privacidade', component: PrivacyPolicyComponent },
      { path: 'termos-de-uso', component: TermsOfUseComponent },
      { path: 'tdu-chat-web', component: TermsOfUseComponent },
      { path: 'desconto-especial', component: SpecialDealsComponent },
      { path: 'prime', component: PrimeComponent },
    ]
  },
  {
    path: 'seja-um-parceiro',
    component: LandingPagesComponent,
    children: [
      {
        path: '',
        component: BeAPartnerComponent,
      }
    ]
  },
  {
    path: 'para-empresas',
    component: LandingPagesComponent,
    children: [
      {
        path: '',
        component: PageForCompaniesComponent,
      }
    ]
  },
  {
    path: 'afiliados',
    component: LandingPagesComponent,
    children: [
      {
        path: '',
        component: BeAnAffiliatedComponent,
      }
    ]
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      subdomain == environment.checkout_subdomain ? checkoutRoute : appRoutes, { useHash: false, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
