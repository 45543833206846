import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';

declare var window: any;

@Injectable()
export class GtmService {
  constructor() {
    if (!Object.hasOwnProperty.call(window, 'dataLayer')) {
      window.dataLayer = [];
    }
  }

  public pageView(url: string): void {
    this.trigger('TRACK_PAGEVIEW', {
      virtualPageURL: url
    });
  }

  private trigger(event: string, data?: any): void {
    this.pushToDataLayer({
      event,
      ...data
    });
  }

  private pushToDataLayer = data => {
    window.dataLayer.push(data);
  }

  private hash = (text: string): string => {
    return sha256(text).toString(Hex);
  }

  public loginSuccess(customer: Customer, code: string): void {
    this.trigger('login.realizado', {
      ecommerce: {
        customer: {
          _id: customer._id,
          name: customer.name,
          email: customer.email,
          phone: customer.phone
        }
      },
      eventID: code,
      externalID: this.hash(customer._id)
    });
  }

  public partnerLead(partner: any, code: string): void {
    this.trigger('lead_parceiros', {
      eventID: code,
      externalID: this.hash(partner._id)
    });
  }

  public affiliatedLead(affiliated: any, code: string): void {
    this.trigger('lead_afiliados', {
      eventID: code,
      externalID: this.hash(affiliated._id)
    });
  }
}
