import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { SwiperDirective } from "ngx-swiper-wrapper";
import { AuthService } from "src/app/shared/services/auth.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { UsersService } from "src/app/shared/services/users.service";
import { UtilService } from "src/app/shared/services/util.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  @ViewChild(SwiperDirective, { read: false }) directiveRef?: SwiperDirective;

  formZipcode: FormGroup;
  isLoading = false;
  cupom: any;
  homeContent: any;
  environment: any = environment;
  org: string = environment["X-Explorer-Account-Token"];
  userLogged: boolean;

  cupomHeadline: string;
  cupomSubheadline: string;

  constructor(
    private formBuilder: FormBuilder,
    private util: UtilService,
    private snackbarService: SnackBarService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private userService: UsersService,
    private authService: AuthService
  ) { 
    this.util.createCanonicalURL(); 
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.cupomId) {
        this.util.getIndicationData(params.cupomId).subscribe((result) => {
          this.cupom = result;
          localStorage.setItem("coupon", params.cupomId);

          this.getContent();
        });
      } else {
        this.getContent();
      }
    });

    this.formZipcode = this.formBuilder.group({
      zipcode: ["", [Validators.required, Validators.pattern]],
    });

    this.userService.userIsLogged().subscribe((status) => {
      this.userLogged = status;
    });
  }

  public getContent() {
    this.util.getDynamicContentFromSite("editableHomeContent").subscribe(
      (result) => {
        this.homeContent = result;
        if (result.pageHeader.pageDescription) {
          this.meta.addTag({
            name: "description",
            content: result.pageHeader.pageDescription,
          });
        }

        if (result.pageHeader.pageTitle) {
          this.titleService.setTitle(result.pageHeader.pageTitle);
        }

        if (this.cupom) {
          this.homeContent.pageHeader.cupomHeadline = this.homeContent.pageHeader.cupomHeadline.replace(
            "{{customerName}}",
            this.cupom.customer
          );
          this.homeContent.pageHeader.cupomHeadline = this.homeContent.pageHeader.cupomHeadline.replace(
            "{{cupomValue}}",
            this.cupom.value
          );

          this.homeContent.pageHeader.cupomSubheadline = this.homeContent.pageHeader.cupomSubheadline.replace(
            "{{customerName}}",
            this.cupom.customer
          );
          this.homeContent.pageHeader.cupomSubheadline = this.homeContent.pageHeader.cupomSubheadline.replace(
            "{{cupomValue}}",
            this.cupom.value
          );
        }
      },
      () => {
        this.setDefaultContent();
        if (this.homeContent.pageHeader.pageDescription) {
          this.meta.addTag({
            name: "description",
            content: this.homeContent.pageHeader.pageDescription,
          });
        }

        if (this.homeContent.pageHeader.pageTitle) {
          this.titleService.setTitle(this.homeContent.pageHeader.pageTitle);
        }

        if (this.cupom) {
          this.homeContent.pageHeader.cupomHeadline = this.homeContent.pageHeader.cupomHeadline.replace(
            "{{customerName}}",
            this.cupom.customer
          );
          this.homeContent.pageHeader.cupomHeadline = this.homeContent.pageHeader.cupomHeadline.replace(
            "{{cupomValue}}",
            this.cupom.value
          );

          this.homeContent.pageHeader.cupomSubheadline = this.homeContent.pageHeader.cupomSubheadline.replace(
            "{{customerName}}",
            this.cupom.customer
          );
          this.homeContent.pageHeader.cupomSubheadline = this.homeContent.pageHeader.cupomSubheadline.replace(
            "{{cupomValue}}",
            this.cupom.value
          );
        }
      }
    );
  }

  public hasActiveSection(sections): boolean {
    if (!sections || !sections.length) return false;
    return sections.find((s) => s.active) ? true : false;
  }

  setZipcode(): void {
    this.isLoading = true;
    const zipcode = this.formZipcode.get("zipcode").value;
    this.util.searchZipcode(zipcode, true).subscribe((result) => {
      if (result && result.isAvailable) {
        localStorage.setItem("zipcode", zipcode);
        this.snackbarService.success("Temos uma unidade próxima a você.");
        window.location.href = environment.ecommerce_url + "mapa?zipcode=" + zipcode;
      } else {
        this.snackbarService.error("Esse CEP não é atendido no momento.");
      }

      this.isLoading = false;
    });
  }

  setColors(cta) {
    return {
      "background-color": cta.backgroundColor,
      "border-color": cta.borderColor,
      color: cta.fontColor,
    };
  }

  openModalLogin(): void {
    this.authService.openModalLogin();
  }

  setDefaultContent(): void {
    this.homeContent = {
      pageHeader: {
        siteCta: [
          {
            backgroundColor: "#F4822C",
            borderColor: "#F4822C",
            buttonLink: "https://drlavatudo-dev.sydle.com/loja/",
            buttonTitle: "VER VALORES",
            fontColor: "#FFFFFFF",
          },
          {
            backgroundColor: "transparent",
            borderColor: "#FFFFFFF",
            buttonLink: "https://drlavatudo-dev.sydle.com/blog/",
            buttonTitle: "ASSINE AGORA",
            fontColor: "#FFFFFFF",
          },
        ],
        backgroundImage: {
          _id: "639b5f6b7be4f471493727d4",
        },
        pageTitle: "Dr. Lava Tudo: Limpeza de Sofá e Estofados",
        pageDescription:
          "São mais de 60 serviços como limpeza de sofá, tapete, colchões e impermeabilização. Descubra se há uma unidade Dr. Lava Tudo perto de você!",
        headline: "Higienização Profissional à partir de R$19/mês",
        subheadline: "Contrate fácil e resolva ainda hoje",
        cupomHeadline: "{{customerName}} quer mais saúde e conforto para você!",
        cupomSubheadline: "Por isso te presenteou com R${{cupomValue}} para limpar e impermeabilizar seus estofados",
      },
      pageContent: [
        {
          sectionName: "howWorks",
          sectionContent: {},
          active: true,
        },
        {
          sectionName: "commonQuestions",
          sectionContent: {},
          active: true,
        },
        {
          sectionName: "lastBlogPosts",
          sectionContent: {},
          active: true,
        },
        {
          sectionName: "ourGoal",
          sectionContent: {},
          active: true,
        },
        {
          sectionName: "testimonies",
          sectionContent: {},
          active: true,
        },
        {
          sectionName: "dontWasteYourTime",
          sectionContent: {
            subtitle:
              "Revolucionamos prestação de serviços, do início ao fim, garantindo melhor experiência para sua comodidade e bem-estar.",
            title: ["Não perca seu tempo", "Tudo limpo, organizado e cheiroso sem esforço"],
            sectionElements: [
              {
                contentName: "professionals",
              },
              {
                contentName: "guarantee",
              },
            ],
          },
          active: true,
        },
        {
          sectionName: "attendedCities",
          sectionContent: {
            title: ["Presente em mais de", "150 cidades do Brasil"],
          },
          active: true,
        },
        {
          sectionName: "approval",
          sectionContent: {
            mainImage: {
              _id: "6564d0905eaec14071f70233",
            },
            siteCta: [
              {
                backgroundColor: "#F4822C",
                borderColor: "#F4822C",
                buttonTitle: "VER TODOS OS SERVIÇOS",
                buttonLink: "dev.drlavatudo.com/loja",
                fontColor: "#FFFFFFF",
              },
            ],
            subtitle: "São mais de 60 serviços como limpeza de sofá, tapete, colchões e impermeabilização.",
            title: ["Aprovado por mais de", "500.000 clientes"],
          },
          active: true,
        },
        {
          sectionName: "checkService",
          sectionContent: {
            subtitle: "Descubra também o que seus vizinhos falam sobre a Dr. Lava Tudo",
            title: "Confira se atendemos sua região",
          },
          active: true,
        },
      ],
    };
  }

  getBackgroundImg() {
    if (!this.homeContent?.pageHeader?.backgroundImage?._id) return;

    return {
      "background-image": "linear-gradient(rgb(52, 200, 126), rgba(25, 173, 95, 0.43) 50%), url(" +
        environment.base_api + "/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId=" + this.homeContent.pageHeader.backgroundImage._id + ")"
    };
  }
}
