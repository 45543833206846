import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-goals",
  templateUrl: "./goals.component.html",
  styleUrls: ["./goals.component.scss"],
})
export class GoalsComponent implements OnInit {
  @Input() content: any;
  environment: any = environment;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (!this.content || !Object.keys(this.content).length) {
      this.content = {
        siteCta: [
          {
            backgroundColor: "#F4822C",
            borderColor: "#F4822C",
            buttonTitle: "VER VALORES",
            buttonLink: "dev,drlavatudo.com/loja",
            fontColor: "#FFFFFFF",
          },
        ],
        subtitle: "A Dr. Lava Tudo é a única empresa do Brasil que tem seguro de todos os seus procedimentos e atua com total responsabilidade sem gerar nenhum transtorno a seus clientes.",
        title: ["Nosso objetivo é", "te surpreender"],
        sectionElements: [
          {
            content: {
              content: [
                {
                  src: "assets/icons/monitor-heart-beat.svg",
                  title: "Saúde",
                  content: "Livre-se de ácaros, fungos e bactérias, causadores de alergias respiratórias.",
                },
                {
                  src: "assets/icons/cupom-discount.svg",
                  title: "Economia",
                  content: "Uma higienização custa 10% de um estofado novo. Não troque, renove!",
                },
                {
                  src: "assets/icons/time-clock.svg",
                  title: "Tempo",
                  content: "Poupe seu tempo, aproveite o dia com a família e deixa o resto com a gente!",
                },
              ],
            },
            contentName: "cards",
          },
        ],
      };
    }
  }

  setColors(cta) {
    return {
      "background-color": cta.backgroundColor,
      "border-color": cta.borderColor,
      "color": cta.fontColor,
    }
  }
}
