<div *ngIf="content" class="goals">
    <div class="container">
        <div class="goals-col align-items-center">
            <div class="item">
                <div class="d-flex align-items-start align-items-lg-center h-100">
                    <div class="description d-flex flex-column">
                        <h2 class="title">
                            <p>{{content.title[0]}}</p>
                            <p class="font-normal">{{content.title[1]}}</p>
                        </h2>
                        <h4>{{content.subtitle}}</h4>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="d-flex align-items-center h-100">
                    <img *ngIf="content.mainImage" alt="Imagem dos objetivos" class="goals-main-image"
                        src="{{environment.base_api}}/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId={{content.mainImage._id}}">
                    <app-goals-image *ngIf="!content.mainImage"></app-goals-image>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cards-container">
    <div class="cards">
        <div class="cards-content">
            <div class="card-item" *ngFor="let item of content.sectionElements[0].content.content">
                <div class="d-flex flex-row gap-4">
                    <img *ngIf="item.src" class="card-img" src="{{item.src}}">

                    <img *ngIf="!item.src" alt="Imagem do card" class="card-img"
                        src="{{environment.base_api}}/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId={{item.icon._id}}">

                    <div class="card-description">
                        <h4>{{item.title}}</h4>
                        <span>{{item.content}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="button-value">
            <a *ngFor="let cta of content.siteCta" href={{cta.buttonLink}}> <button class="btn btn-secondary d-block mx-auto"
                    [ngStyle]="setColors(cta)"> {{cta.buttonTitle}} </button> </a>
        </div>
    </div>
</div>