<div *ngIf="itemsList && itemsList.length">
    <mat-accordion *ngFor="let item of itemsList">
        <mat-expansion-panel id="{{item.identifier}}" [expanded]="item.expanded" (click)="openAccordion(item.identifier)">
            <mat-expansion-panel-header [collapsedHeight]="'70px'">
                <mat-panel-title>
                    {{item.name}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-content">
                <p *ngIf="!item.contentFormat">{{item.content}}</p>
                <div [innerHTML]="item.contentHtml | safeHtml"></div>             
            </div>            
        </mat-expansion-panel>
    </mat-accordion>
</div>

