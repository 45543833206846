import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-warranty-availability",
  templateUrl: "./warranty-availability.component.html",
  styleUrls: ["./warranty-availability.component.scss"],
})
export class WarrantyAvailabilityComponent implements OnInit {
  @Input() content: any;

  environment: any = environment;

  constructor() {}

  ngOnInit(): void {
    if (!this.content || !Object.keys(this.content).length) {
      this.content = {
        siteCta: [
          {
            backgroundColor: "#F4822C",
            borderColor: "#F4822C",
            buttonTitle: "VER VALORES",
            buttonLink: "/loja",
            fontColor: "#FFFFFFF",
          },
        ],
        subtitle: "Ou você fica 100% SATISFEITO ou o nosso trabalho não terminou.",
        title: "Garantia e disponibilidade",
        content: ["Pós-venda online 24h", "7 dias de garantia", "Cancelamento gratuito até 24hs antes do serviço"],
      };
    }

    this.content.content = this.content.content.filter(list => list !== null);
  }

  setColors(cta) {
    return {
      "background-color": cta.backgroundColor,
      "border-color": cta.borderColor,
      "color": cta.fontColor,
    }
  }
}
