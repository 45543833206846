import { Component, Input, OnInit } from "@angular/core";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

@Component({
  selector: "app-satisfaction-guaranteed",
  templateUrl: "./satisfaction-guaranteed.component.html",
  styleUrls: ["./satisfaction-guaranteed.component.scss"],
})
export class SatisfactionGuaranteedComponent implements OnInit {
  @Input() content: any;

  config: SwiperConfigInterface = {
    slidesPerView: 3,
    spaceBetween: 20,
    breakpoints: {
      770: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: "#testimony-button-next",
      prevEl: "#testimony-button-prev",
    },
    pagination: {
      el: "#testimony-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 20000,
      disableOnInteraction: false,
    },
  };

  constructor() {}

  ngOnInit(): void {
    if (!this.content || !Object.keys(this.content).length) {
      this.content = {
        subtitle: "São mais de 500.000 residências e empresas atendidas.",
        title: ["Satisfação garantida", "em todos os serviços"]
      };
    }
  }
}
