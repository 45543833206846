import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { UsersService } from 'src/app/shared/services/users.service';
@Component({
  selector: 'app-affiliated-login',
  templateUrl: './affiliated-login.component.html',
  styleUrls: ['./affiliated-login.component.scss']
})
export class AffiliatedLoginComponent implements OnInit {
  public userCode;
  public customer;
  loading
  oldRegistrationStatus

  @Input() isLoged;
  @Input() portalUrl;
  @Input() tdu;
  @Input() socialNetWorks;
  @Input() registrationStatus;

  @Output() openModal: EventEmitter<any> = new EventEmitter();

  @ViewChild('termsAndConditions') termsRef;
  phoneWithDDI: string;
  formatPhone: string;
  openTDU: boolean;

  constructor(
    private userService: UsersService,
    private loaderService: LoaderService,
    private snackbarService: SnackBarService,
    private trackingService: TrackingService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.userService.userData().subscribe({
      next: value => {
        if (!value || Object.keys(value).length === 0) {
          this.loading = false;
          return;
        }
        if(value.name == value.login){
          value.name = "";
        }
        if (value.phone) {
          this.phoneWithDDI = '+55' + value.phone;
          this.formatPhone = '(' + value.phone.slice(0, 2) + ')' + value.phone.slice(2);
        }
        this.customer = value
        this.loading = false;
      }
    });
  }

  public openLoginModal(): void {
    this.openModal.emit();
  }

  public openTerms(): void {
    this.openTDU= true
  }

  closeTDU(): void {
    this.openTDU = false;
  }

  public addSocialNetwork(): void {
    const container: any = document.getElementById('more-network-links');
    const select = this.socialNetWorks.map(item => (
      "<option value=" + item._id + "> " + item.name + "</option>"
    ));

    let style = '';
    let styleButtom = '';
    if (window.matchMedia('(max-width: 487px)').matches) {
      style = "style='display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; margin-top: 12px;'";
      styleButtom = "style='margin: 16px auto 0; width: 25px;'";
    } else {
      style = "style='display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 12px;'";
    }

    const component = "<div class='network-node' " + style + ">" +
      "<sy-select label='Rede social' size='30' style='width: 184px; margin-right: 16px;'>" +
      select +
      "</sy-select>" +
      "<sy-input label='Link do seu perfil' type='text' class='network-link''></sy-input>" +
      "<sy-button content='icon-only' icon='bin-2' small fab " + styleButtom + " onClick='this.parentElement.remove()'></sy-button>" +
      "</div>";
    container.insertAdjacentHTML('beforeend', component);
  }

  public verifyInput(id): void {
    let element: any = document.getElementById(id);

    switch (id) {
      case "email":
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(element.value.trim())) {
          element.removeAttribute("error");
        }
        break;

      case "phone":
        let phoneNumber = element.value ? element.value.replace(/\W/gi, '') : "";
        if (/[0-9]{2}[0-9]{5}[0-9]{4}$/.test(phoneNumber)) {
          element.removeAttribute("error");
        }
        break;

      case "name":
        if (/\w+\s\w+/gm.test(element.value)) {
          element.removeAttribute("error");
        }
        break;

      case "accept-terms":
        if (element.checked) {
          let checkboxError = document.getElementById('checkbox-error');
          checkboxError.style.display = "none";
        }
        break;
    }
  }

  sendForm() {
    let email: any = document.getElementById('email');
    let name: any = document.getElementById('name');
    let affiliatedName: any = document.getElementById('affiliatedName');
    let phone: any = document.getElementById('phone');
    let terms: any = document.getElementById('accept-terms');
    let networks: any = document.querySelectorAll('.network-node');

    let hasError = false;

    if (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
        email.removeAttribute("error");
      } else {
        email.setAttribute("error", "Favor inserir um e-mail válido.");
        hasError = true;
      }
    }


    if (/\w+\s\w+/gm.test(name.value)) {
      name.removeAttribute("error");
    } else {
      name.setAttribute("error", "Favor inserir nome e sobrenome.");
      hasError = true;
    }

    let phoneNumber = phone ? phone.value ? phone.value.replace(/\W/gi, '') : "" : this.customer.phone.replace(/\W/gi, '');
    if (phone) {
      if (/[0-9]{2}[0-9]{5}[0-9]{4}$/.test(phoneNumber)) {
        phone.removeAttribute("error");
      } else {
        phone.setAttribute("error", "Favor inserir um telefone válido.");
        hasError = true;
      }
    }
    

    let checkboxError = document.getElementById('checkbox-error');
    if (terms.checked) {
      checkboxError.style.display = "none";
    } else {
      checkboxError.style.display = "block";
      hasError = true;
    }

    if (hasError) return;

    let socialNetworkData = [];
    networks.forEach(item => {
      if (item.childNodes && item.childNodes[0] && item.childNodes[0].value && item.childNodes[1] && item.childNodes[1].value) {
        socialNetworkData.push({
          "socialNetwork": item.childNodes[0].value,
          "url": item.childNodes[1].value
        });
      }
    });

    const explorer_user = JSON.parse(localStorage.getItem("explorer_user_map"));
    this.userCode = explorer_user?.ecommerce?.users[0]?.code ? explorer_user.ecommerce.users[0].code : null;

    const data = {
      "data": {
        "customerId": this.userCode,
        "registrationData": {
          "email": email ? email.value: this.customer.email,
          "name": name.value,
          "affiliatedName": affiliatedName.value,
          "phone": phoneNumber,
          "socialNetworks": socialNetworkData
        }
      }
    }
    this.loaderService.startLoader();
    this.userService.registrateAffiliated(data).subscribe(async (res) =>  {
      if(res?.affiliated) {
        await this.trackingService.affiliatedLead(res?.affiliated);
      }
      this.registrationStatus = "finished";
      this.loaderService.stopLoader();
      this.snackbarService.success('Cadastro realizado com sucesso! Você será encaminhado para a área do afiliado.');
      window.location.href = this.portalUrl;
    }, (err) => {
      this.loaderService.stopLoader();
      if(!err.error.generalMessages?.length){
        this.snackbarService.error("Não foi possível realizar o cadastro, favor entrar em contato");
      }
    });
  }
}
