import { Component, Input } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-pre-registration-error',
  templateUrl: './pre-registration-error.component.html',
  styleUrls: ['./pre-registration-error.component.scss']
})
export class PreRegistrationErrorComponent {
  link: string;
  errorMessage = 'Estou na página para continuar o meu pré cadastro como parceiro da Dr. Lava Tudo e o sistema não está localizando o meu cadastro: ';

  constructor(private utilService: UtilService) { 
    this.link = window.location.href;
  }

  ngOnInit(): void {
  }

  onClickButton = (): void => {
    this.utilService.getWhatsappNumber("Pré-cadastro de parceiro").subscribe({
        next: resp => {
            if (!resp.phone) {
                return;
            }

            const phone = resp.phone;

            this.errorMessage += this.link;
            window.location.href = 'https://api.whatsapp.com/send?phone=' + phone + '&text=' + encodeURIComponent(this.errorMessage);
        }
    });
  }

}
