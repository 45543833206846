import { Component, ViewChild, OnDestroy, Input, ViewEncapsulation } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { masks } from "src/app/shared/constants/masks";
import { Customer } from "src/app/shared/models/customer";
import { CareerService } from "./../../../../shared/services/career.service";
import { LocationService } from "./../../../../shared/services/location.service";
import { Location } from "./../../../../shared/models/location";

@Component({
  selector: "app-career-job-form",
  templateUrl: "./career-job-form.component.html",
  styleUrls: ["./career-job-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CareerJobFormComponent implements OnDestroy {
  @ViewChild("content") modalCareerJobForm;
  @Input() selectiveProcessId: string;

  modalRef: NgbModalRef;
  status = "sending";
  sendEmail: string;
  closeModal = undefined;
  ///
  curriculumTouched: boolean = false;
  customer: Customer;
  focused: string;
  customerEmail: string;
  customerPhone: string;
  contractId: string;
  loginANDtoken: string;
  product: any;
  step: number = 0;
  accessCode: string;
  validPhone: boolean;
  newCodeGenerate: boolean;
  loading: boolean;
  errorMessage: String;
  hasErrorInvalidCredentials: boolean;
  hasntCredentials: boolean;
  categoryName: string = "";
  public recentToken: string = "";
  styleCustomSelect = {
    display: "inline-block",
    width: "100%",
    height: "auto",
    padding: ".375rem 1rem .375rem 0",
    "font-size": "1rem",
    "font-weight": "400",
    "line-height": "1.5",
    color: "#495057",
    "vertical-align": "middle",
    background: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0 center/8px 10px`,
    "border-radius": "0",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    appearance: "none",
    "margin-bottom": "1rem",
  };
  isCreating: boolean = false;
  shouldUpdate: boolean = false;
  title: string;
  description: string;
  sourceButton: string;
  hasJob: boolean;
  phoneInput: string;

  //job model
  address: Location = new Location();
  streetFound: boolean = false;
  quarterFound: boolean = false;
  cityFound: boolean = false;

  masks: any = masks;
  processing: boolean = false;
  processingUpload: boolean = false;
  validatefield: boolean = false;
  errorCEP: boolean = false;

  fileName: string;
  fullName: string;
  phone: string;
  zipCode: string;

  interest: Array<any> = [];
  error: boolean;
  curriculuminvalid = false;
  curriculum: File;
  curriculumErrors = "";

  goToCheckout: boolean;

  checkBoxArray = [
    { name: "Marketing", checked: false },
    { name: "Corporativo", checked: false },
    { name: "Comercial / Atendimento", checked: false },
    { name: "Desenvolvimento", checked: false },
    { name: "Auditoria", checked: false },
  ];

  success: boolean;

  constructor(
    private locationService: LocationService,
    private careerService: CareerService,
    private modalService: NgbModal
  ) {}
  ///

  public ngOnInit() {
    this.checkExistingJob();
  }

  checkExistingJob(): void {
    let url = window.location.pathname.split("/");

    if (url && url.length > 2) {
      if (url[2] == "vaga") {
        let childenUrl;
        childenUrl = url[3].split("-");
        this.selectiveProcessId = childenUrl[childenUrl.length - 1];
      }
    }

    this.hasJob = this.selectiveProcessId ? true : false;
  }

  onCloseClick(result: string) {
    this.reset();
    this.closeModal();
  }

  open(status?: string): void {
    if (status) this.status = status;
    this.modalRef = this.modalService.open(this.modalCareerJobForm, {
      centered: true,
      modalDialogClass: "modal-career-form-job-content",
    });
    this.closeModal = () => this.modalRef.dismiss("sair");
  }

  closeCareerJobForm(sync: boolean): void {
    if (sync) {
      return;
    }

    this.closeModal();
  }

  ngOnDestroy(): void {
    this.status = "sending";
  }

  checkBoxToggle(index) {
    this.checkBoxArray[index].checked = !this.checkBoxArray[index].checked;
    if (this.checkBoxArray[index].checked) {
      this.interest.push(this.checkBoxArray[index].name);
    } else {
      this.interest = this.interest.filter(
        (item) => item !== this.checkBoxArray[index].name
      );
    }
  }

  getPhoneNumberTextMask(): any {
    return {
      mask: (v) => {
        v = v.replace(/[^0-9]/g, "");
        if (v.length <= 10) {
          return masks.phoneNumber;
        } else {
          return masks.mobilePhoneNumber;
        }
      },
    };
  }

  resetError() {
    this.success = false;
    this.error = false;
    this.processingUpload = false;
  }

  reset() {
    this.success = false;
    this.error = false;
    this.processingUpload = false;
  }

  public validate() {
    if (this.hasJob) {
      if (
        this.fullName &&
        this.curriculum &&
        this.phone &&
        this.address &&
        this.address.number &&
        this.address.zipcode &&
        this.address.city &&
        this.address.quarter &&
        this.validatePhone() &&
        this.validateName() &&
        !this.curriculuminvalid &&
        !this.errorCEP
      ) {
        this.error = false;
        return false;
      } else {
        return true;
      }
    } else {
      if (
        this.fullName &&
        this.curriculum &&
        this.phone &&
        this.address &&
        this.address.number &&
        this.address.zipcode &&
        this.address.city &&
        this.address.quarter &&
        this.interest.length &&
        this.validatePhone() &&
        this.validateName() &&
        !this.curriculuminvalid &&
        !this.errorCEP
      ) {
        this.error = false;
        return false;
      } else {
        return true;
      }
    }
  }

  public uploadFile(event) {
    this.curriculum = event.target.files[0];

    let fileFormat = this.curriculum.type;
    let fileSizeMB = this.curriculum.size / (1024 * 1024);

    if (this.curriculum.name.length > 40) {
      this.fileName = this.curriculum.name.slice(0, 40) + "...";
    } else {
      this.fileName = this.curriculum.name;
    }

    if (fileFormat !== "application/pdf") {
      this.curriculumErrors = "Formato inválido";
      this.curriculuminvalid = true;
      return;
    }

    if (fileSizeMB > 5) {
      this.curriculumErrors = "Tamanho de currículo Inválido";
      this.curriculuminvalid = true;
      return;
    }

    this.curriculuminvalid = false;
  }

  setVerifyPhone(valid) {
    this.validPhone = valid;
  }

  editPhone(phone) {
    return phone
      .replace(" ", "")
      .replace("(", "")
      .replace(")", "")
      .replace("-", "");
  }

  onSubmit(): void {
    this.processingUpload = true;
    this.careerService.convertFile(this.curriculum).subscribe((res) => {
      let data;
      data = {
        resume: {
          name: this.fileName,
          base64: res,
        },
        phone: this.phone,
        address: {
          zipCode: this.address.zipcode,
          streetField: this.address.street,
          numberField: this.address.number,
          complement: this.address.complement,
          quarterField: this.address.quarter,
          cityField: this.address.city,
        },
        fullName: this.fullName,
      };
      if (this.hasJob) {
        data.selectiveProcess = this.selectiveProcessId;
        this.careerService.selectiveProcessSubscribe(data).subscribe(
          (res) => {
            this.processingUpload = false;
            this.error = false;
            this.success = true;
          },
          (err) => {
            this.errorMessage =
              err &&
              err.status == 400 &&
              err.error &&
              err.error.generalMessages &&
              err.error.generalMessages[0] &&
              err.error.generalMessages[0].text
                ? err.error.generalMessages[0].text
                : "Não foi possível enviar os seus dados, tente novamente.";
            this.processingUpload = false;
            this.error = true;
            this.checkError(err);
            this.success = false;
          }
        );
      } else {
        data.interests = this.interest;
        this.careerService.uploadCurriculum(data).subscribe(
          (curr) => {
            this.error = false;
            this.processingUpload = false;
            this.success = true;
          },
          (err) => {
            this.processingUpload = false;
            this.error = true;
            this.checkError(err);
            this.success = false;
          }
        );
      }
    });
  }

  findByZipCode() {
    let temp = this.address.zipcode;
    if (temp && temp.length == 9 && !temp.includes("_")) {
      this.errorCEP = false;
      this.processing = true;
      this.locationService.findByZipCode(temp).subscribe(
        (data) => {
          if (data.city) {
            this.cityFound = true;
          } else {
            this.cityFound = false;
          }

          if (data.quarter) {
            this.quarterFound = true;
          } else {
            this.quarterFound = false;
          }

          if (data.street) {
            this.streetFound = true;
          } else {
            this.streetFound = false;
          }

          this.address.zipcode = temp;
          this.address.city = data.city;
          this.address.quarter = data.quarter;
          this.address.street = data.street;
          this.address.state = data.state;
          this.address.number = null;
          this.address.complement = null;
          this.processing = false;
        },
        (err) => {
          this.processing = false;
          if (err && err.status == 400) {
            this.errorCEP = true;
          }
          this.address.city = null;
          this.address.quarter = null;
          this.address.street = null;
          this.address.state = null;
          this.address.number = null;
          this.address.complement = null;
        }
      );
    }
  }

  checkError(err) {
    if (!err) {
      return;
    }

    let expiredSession = false;

    if (err.status == 401 || err.status == 403) {
      expiredSession = true;
    }

    let explorer_user = JSON.parse(localStorage.getItem("explorer_user_map"));

    // Perdemos o token
    if (!explorer_user?.ecommerce?.users[0]?.accessToken?.token) {
      expiredSession = true;
    }

    if (expiredSession) {
      localStorage.removeItem("explorer_user_map");
    }
  }

  validatePhone() {
    this.phoneInput = this.phone ? this.phone.replace(/\D/gim, "") : "";

    if (this.phoneInput.length < 10 || this.phoneInput.length > 11)
      return false;

    if (this.phoneInput[2] == "9" && this.phoneInput.length == 10) return false;

    return true;
  }

  validateName() {
    let nameTest = this.fullName ? this.fullName.split(" ") : [];

    let total = 0;

    nameTest.forEach((n) => {
      if (n != "") {
        total += 1;
      }
    });

    if (total > 1) return true;

    return false;
  }
}
