import { AbstractControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CareerService } from "src/app/shared/services/career.service";
import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Job } from "src/app/shared/models/job";
import { SEOService } from "src/app/shared/services/seo.service";
import { TrackingService } from "src/app/shared/services/tracking.service";
import { UsersService } from "src/app/shared/services/users.service";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/shared/services/auth.service";

export function validateName(control: AbstractControl) {
  if (control.value.split(" ").length <= 1) {
    return { invalidName: true };
  }
  return null;
}
@Component({
  selector: "app-career-job",
  templateUrl: "./career-job.component.html",
  styleUrls: ["./career-job.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CareerJobComponent implements OnInit {
  @ViewChild("modalForm") modalFormRef;

  error: boolean = false;
  loading: boolean = false;
  title: string;
  id: String;
  job: Job;
  userLogged: boolean;
  environment: any = environment;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private activatedRoute: ActivatedRoute,
    private careerService: CareerService,
    private seoService: SEOService,
    private trackingService: TrackingService,
    private userService: UsersService,
    private authService: AuthService
  ) {
    this.userService.userIsLogged().subscribe((status) => {
      this.userLogged = status;
    });
  }

  ngOnInit(): void {
    this.title = "Trabalhe Conosco";
    this.id = this.getJobId();
    this.error = false;

    this.loadJobDescription();
    this.loadScript();
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  setError(error: boolean): void {
    this.error = error;
  }

  getJobId(): string {
    const { id } = this.activatedRoute.snapshot.params;
    const ids = id.split("-");
    return ids[ids.length - 1];
  }

  loadJobDescription(): void {
    this.setLoading(true);
    this.careerService.getJobDescription(this.id).subscribe(
      (res) => {
        if (res) {
          this.job = res;
        }
        this.seoService.setJobDescriptionSEO(this.job.name);
        this.trackingService.pageView();
        this.setError(false);
        this.setLoading(false);
      },
      (err) => {
        this.setError(true);
        this.setLoading(false);
      }
    );
  }

  loadScript(): void {
    this.careerService.getJsonLd(this.id).subscribe((res) => {
      if (res) {
        let script = this._document.createElement("script");
        script.type = "application/ld+json";
        script.text = `${JSON.stringify(res)}`;
        this._document.head.appendChild(script);
      }
    });
  }

  openLoginModal(): void {
    this.authService.openModalLogin();
  }

  openModalJob(): void {
    this.modalFormRef.open();
  }

  detailParagraph(detail) {
    let html = "";

    if (detail && detail.description && detail.description.length) {
      detail.description.forEach((item) => {
        html += "<p>" + item + "</p>";
      });
    }
    return html;
  }

  detailList(detail) {
    let html = "<ul>";

    if (detail && detail.description && detail.description.length) {
      detail.description.forEach((item) => {
        html += "<li class='with-icon d-flex'>" + item + "</li>";
      });
    }

    html += "</ul>";

    return html;
  }
}
