<app-header></app-header>

<main class="w-100">
    <div class="board">
        <section>
            <img src="assets/images/page-not-found.svg" alt="page not found image">
        </section>
        <section>
            <h1>Página não encontrada</h1>
        </section>
        <section>
            <h3>Parece que esta página não existe ou foi removida. Por favor, acesse a página inicial.</h3>
        </section>
        <section>
            <a href="/"><button>IR PARA PÁGINA INICIAL</button></a>
        </section>
    </div>
</main>

<router-outlet></router-outlet>
<app-footer></app-footer>