import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from './../../../environments/environment';

@Injectable()
export class AuthService {
    private loginRef;

    constructor(private httpService: HttpService) {}

    public setLoginRef(ref) {
        this.loginRef = ref;
    }

    public openModalLogin(){
        this.loginRef?.nativeElement?.open();
    }

    public getUserData(userId?: string, checkOpenCart?: boolean): Observable<any> {
      if (checkOpenCart) {
        return this.httpService.get('drLavaTudo/loggedAreaAPI/getCustomer?checkOpenCart=' + checkOpenCart, undefined,  true);
      }

      return this.httpService.get('drLavaTudo/loggedAreaAPI/getCustomer', undefined,  true);
    }

    public updateUserData(userData: any): Observable<any> {
        return this.httpService.post('drLavaTudo/loggedAreaAPI/updateCustomer', userData, true);
    }

    public validateCustomer(userData: any): Observable<any> {
        return this.httpService.post('drLavaTudo/loggedAreaAPI/validateCustomer', userData, true);
    }

    public sendToken(email: string): Observable<any> {
        return this.httpService.post('drLavaTudo/ecommerceAPI/createCode', { email }, false);
    }

    public renewToken(socialToken: string): Observable<any> {
        return this.httpService.get(
            'sys/auth/renewToken', {}, true,
            { key: 'Authorization', value: 'Bearer ' + socialToken  });
    }

    public loginWithEmail(email: string, code: string): Observable<any> {
        return this.httpService.get(
            'sys/auth/signIn', {}, true,
            { key: 'Authorization', value: 'Basic ' + btoa('_loginWithEmail_' + email + ':' + code) });
    }

    public loginWithWhatsapp(id: string, message: string): Observable<any> {
        return this.httpService.get(
            'sys/auth/signIn', {}, true,
            { key: 'Authorization', value: 'Basic ' + btoa('_loginWithWhatsapp_' + id + ':' + message) });
    }

    public loginWithFacebook = (): void => {
        window.location.href = environment.fb_url + encodeURIComponent(window.location.origin + window.location.pathname);
    }

    public loginWithGoogle = (): void => {
        window.location.href = environment.google_url + encodeURIComponent(window.location.origin + window.location.pathname);
    }

    public createWhatsappMessage(): Observable<any> {
        return this.httpService.get('drLavaTudo/ecommerceAPI/createWhatsappMessage', {}, false);
    }

    public retryLogin(id: string, message: string): Observable<any> {
        return this.httpService.post('drLavaTudo/ecommerceAPI/retryLogin', {objectId: id, message: message}, false);
    }

}
