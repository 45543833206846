<div *ngIf="!processing" class="overflow-hidden">
    <app-header></app-header>
    <div class="local-seo-header w-100">
        <div class="local-seo-header-content">
            <div class="local-seo-titles">
                <div class="local-seo-logo">
                    <img *ngIf="contentPage" src="/assets/images/logo_ai.svg" alt="logo Dr Lava Tudo">
                </div>
                <div class="local-seo-principal-image-mobile" *ngIf="isMobile">
                    <img class="local-seo-mobile-image" *ngIf="contentPage"
                        src="{{environment.base_api}}/api/1/portal/drLavaTudo/ecommerceAPI/getSEOImage?objId={{contentPage.objID}}"
                        alt="Imagem principal">
                </div>
                <div class="description">
                    <h2 *ngIf="contentPage">{{contentPage.principalTitle}}</h2>
                    <p *ngIf="contentPage">{{contentPage.principalDescription}}</p>
                    <a><button (click)="goToEcommerce()">VER VALORES</button></a>
                </div>
            </div>
            <div class="local-seo-principal-image" *ngIf="!isMobile">
                <img class="local-seo-desktop-image" *ngIf="contentPage"
                    src="{{environment.base_api}}/api/1/portal/drLavaTudo/ecommerceAPI/getSEOImage?objId={{contentPage.objID}}"
                    alt="Imagem principal">
            </div>
        </div>
        <div class="local-seo-white-box w-100"></div>
    </div>

    <div class="local-seo-content w-100">
        <div 
            *ngIf="contentPage && contentPage.content"
            id="local-seo-content-board" class="local-seo-content-board" 
            [innerHTML]="contentPage.content | safeHtml">
        </div>

        <div class="w-100 local-seo-button-store">
            <a><button (click)="goToEcommerce()">VER VALORES</button></a>
        </div>
    </div>
    <div>

    <div *ngIf="contentPage.relatedItems" class="container">
        <h1>
            Veja também
        </h1>
        <div class="row">
            <div class="col-md-6" *ngFor="let post of contentPage.relatedItems">
                <a href="/{{post.identifier}}">
                    <article class="border-bottom h-100 py-3 py-md-5">
                        <div class="row post">
                            <div class="col-4 d-flex justify-content-center">
                                <img class="img-post" *ngIf="post && post.icon"
                                    src="{{environment.base_api}}/api/1/blog/drLavaTudo/blogFacade/getImage?objId={{post.id}}&fileId={{post.icon._id}}"
                                    alt="Imagem principal">
                                    <img class="img-post" *ngIf="!post || !post.icon" src="assets/images/SELO.png" alt="Default image">
                            </div>
                            <div class="col-8">
                                <div class="category d-inline-block text-primary text-uppercase">{{post.categoria.name}}</div>
                                <h3>{{post.name}}</h3>
                            </div>
                        </div>
                    </article>

                </a>
            </div>
        </div>
    </div>


    <app-satisfaction-guaranteed></app-satisfaction-guaranteed>
    <app-home-questions></app-home-questions>
    <app-goals></app-goals>
    <app-footer></app-footer>
</div>

<dlt-chat-web 
    [page]="'seoLocal'"
    [origin]="environment.base_api"
    [application]= "'ecommerce'"
    [org]="org"
    [isLogged]="userLogged"     
    (openLoginModal)="openModalLogin()">
</dlt-chat-web>