import { Component, OnInit } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { masks } from 'src/app/shared/constants/masks';
import { UsersService } from 'src/app/shared/services/users.service';
import { UtilService } from 'src/app/shared/services/util.service';

export function validateName(control: AbstractControl): any {
  if (control.value.split(' ').length <= 1) {
    return { invalidName: true };
  }
  return null;
}
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  title: string;
  name: string;
  email: string;
  fone: string;
  formContact: FormGroup;
  processing;
  sending;
  
  constructor(
    private utilService: UtilService, 
    private formBuilder: FormBuilder, 
    private userService: UsersService
  ) { 
    this.utilService.createCanonicalURL(); 
  }

  ngOnInit(): void {
    this.title = 'Contato';
    this.createForm();
    this.sending = false;
  }
  send = (): void => {
    this.processing = true;
    this.userService.sendContactEmail(this.formContact.value).subscribe(resp => {
      this.processing = false;
      this.sending = true;
      document.getElementById('success').style.display = 'block';
      window.location.href = '/contato#form-page';
      document.getElementById('form-sucess').style.display = 'none';
    });
  }
  createForm = (): void => {
    this.formContact = this.formBuilder.group({
      name: ['', [Validators.required, validateName]],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      city: ['', Validators.required],
      mensage: [''],
      company: [''],
      reason: ['', Validators.required],
      checkbox: ['', Validators.required]
    });
  }
  onClickButton = (): void => {
    this.utilService.getWhatsappNumber().subscribe({
      next: resp => {
        if (!resp.phone) {
          return;
        }

        const phone = resp.phone;
        window.location.href = 'https://api.whatsapp.com/send?phone=' + phone;
      }
    });
  }
  get f(): any { return this.formContact.controls; }
  getPhoneNumberTextMask = (): any => {
    return {
      mask: v => {
        v = v.replace(/[^0-9]/g, '');
        if (v.length <= 10) {
          return masks.phoneNumber;
        }

        return masks.mobilePhoneNumber;
      }
    };
  }

}
