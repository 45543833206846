<app-header></app-header>

<div class="container">
    <div *ngIf="text" class="text" [innerHTML]="text | safeHtml"></div>

    <div class="social-network-container">
        <div class="social-network-images-container">
            <sy-button content="icon-only" icon="social-media-facebook" href="{{'https://www.facebook.com/share.php?u=' + shareLink}}"></sy-button>
            <sy-button *ngIf = "wppLink" content="icon-only" icon="messaging-whatsapp" href="{{'https://api.whatsapp.com/send?text='+ wppLink.shareText + shareLink}}"></sy-button>
            <sy-button *ngIf = "twitterLink" content="icon-only" icon="social-media-twitter" href="{{'https://twitter.com/intent/tweet?text=' + twitterLink.shareText + '&url=' + shareLink}}"></sy-button>
            <sy-button content="icon-only" icon="email-action-unread" href={{emailLink}}></sy-button>
        </div>

        <sy-text type="h3" class="share-text"> Compartilhe! </sy-text>
    </div>

    <sy-text type="h3"> Ou envie seu link! </sy-text>

    <div class="link-container">
        <sy-text type="h3" id="share-link"> {{shareLink}} </sy-text>
    </div>

    <sy-button label="Copiar link" class="send-button" (click)="copyShareLink()"></sy-button>
</div>

<app-footer></app-footer>