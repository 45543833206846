import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-be-a-partner',
  templateUrl: './be-a-partner.component.html',
  styleUrls: ['./be-a-partner.component.scss']
})
export class BeAPartnerComponent implements OnInit {
  card = [{ img: 'Handshake.svg', title: 'Sinta-se apoiado', text: 'Estamos sempre disponíveis para dar suporte aos nossos parceiros!' },
  { img: 'Money.svg', title: 'Ganhos financeiros', text: 'Ganhe por produtividade. Possibilidades reais de fazer até R$15.000,00/mês!' },
  { img: 'Liked.svg', title: 'Maior empresa de limpeza', text: 'Faça parte do maior projeto de limpeza de estofados da América Latina!' },
  { img: 'Training.svg', title: 'Treinamentos', text: 'Receba treinamentos de alta performance operacional!' },
  { img: 'Time clock.svg', title: 'Horário flexível', text: 'Maior flexibilidade dos horários de trabalho, permitindo ao DOUTOR uma melhor qualidade de vida!' },
  { img: 'Rating star.svg', title: 'Serviços de alto padrão', text: 'Mais de 500.000 clientes confiam na limpeza da Dr. Lava Tudo!' },
  { img: 'Marketing.svg', title: 'Marketing', text: 'Nenhum custo com publicidades e gestão de mídias sociais!' },
  { img: 'Cleaning.svg', title: 'Maquinário e produtos', text: 'Possibilidade de redução de custos na compra de produtos e maquinários!' },
  { img: 'Customer support.svg', title: 'Central de atendimento', text: 'Atendimento ao cliente 24h sem a necessidade de se preocupar em atender telefones!' }];

  currentEnvironment: string = environment.base_api;
  partnerPageContent;
  defaultContent: boolean = false;
  menuCategories;

  constructor(
    private util: UtilService
  ) { }
  send = false;
  public config: SwiperConfigInterface = {
    slidesPerView: 2.2,
    spaceBetween: 32,
    breakpoints: {
      500: {
        slidesPerView: 1.2,
        spaceBetween: 16,
      },
      280: {
        slidesPerView: 1,
        spaceBetween: 16,
      }
    },
  };
  config2: SwiperConfigInterface = {
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
      770: {
        slidesPerView: 2,
        spaceBetween: 20,
        direction: 'vertical'
      },
    },
  };
  sendForm(): void {
    this.send = true;
  }
  ngOnInit(): void {
    this.getPageContent();
  }

  private getPageContent():void {
    this.util.getDynamicContentFromSite('beAPartnerContent').subscribe(
      result =>{
        this.partnerPageContent = result;      
        this.menuCategories = JSON.stringify(result.categories);    
      },
      err => {
        this.defaultContent = true;
      }
    )
  }

  public hasActiveSection(sections):boolean{
    if(!sections || !sections.length) return false;
    return sections.find(s => s.active) ? true : false;
  }

  public setSectionClass(section, index, sectionLength){
    let sectionClass = []
    if(index === 0){
      switch(section.sectionName){
        case 'testimonies':
          sectionClass.push('testimony-bg');
          break;
        case 'howWorks':
        case 'form':
          sectionClass.push('top-spacing');
          break; 
      }
      if(section.sectionName === 'testimonies'){
        sectionClass.push('testimony-bg');
      }
      sectionClass.push('start-style');
      return sectionClass.join(' ');
    }else{
      if(!(index === (sectionLength - 1) && section.sectionName === "testimonies")){
        sectionClass.push('secondary-section');
        return sectionClass.join(' ');
      }
    }
  }
}
