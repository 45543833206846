import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable, ReplaySubject } from "rxjs";

@Injectable()
export class CareerService {
  constructor(private httpService: HttpService) {}

  public getJobs() {
    return this.httpService.get(
      "drLavaTudo/ecommerceAPI/selectiveprocess",
      undefined,
      false,
      { key: "content-type", value: "application/json" }
    );
  }

  public getJobDescription(spId: String) {
    return this.httpService.get(
      "drLavaTudo/ecommerceAPI/getjobsdetail" + "?spId=" + spId,
      undefined,
      false,
      { key: "content-type", value: "aplication/json" }
    );
  }

  public selectiveProcessSubscribe(data) {
    return this.httpService.post(
      "drLavaTudo/loggedAreaAPI/selectiveProcessSubscribe",
      data,
      true
    );
  }

  public uploadCurriculum(data) {
    return this.httpService.post(
      "drLavaTudo/loggedAreaAPI/submitResume",
      data,
      true
    );
  }

  public convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa((<FileReader>event.target).result.toString()));
    return result;
  }

  public getJsonLd(data) {
    return this.httpService.get(
      "drLavaTudo/ecommerceAPI/JsonldFormatter" + "?selectiveProcess=" + data,
      undefined,
      false
    );
  }

  public validatePartnerLink(leadId: string, code: string ): Observable<any> {
    return this.httpService.get("drLavaTudo/ecommerceAPI/validatePartnerLink?leadId=" + leadId + "&code=" + code);
  }

  public sendFinishRegistrationEmail(leadId: any): Observable<any> {
    return this.httpService.post("drLavaTudo/ecommerceAPI/sendFinishRegistrationEmail", leadId, false);
  }
}
