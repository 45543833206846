import { t as tinycolor } from './tinycolor-542087c9.js';

const Color = tinycolor;
class ColorHelper {
  /**
   * Generate color variations
   * @param {string} colorValue
   */
  static getColorVariations(colorValue) {
    const color = Color(colorValue);
    const contrast = this.getContrastColor(colorValue);
    return [
      {
        name: 'contrast',
        value: contrast,
      },
      {
        name: 'hover',
        value: Color.mix(color, contrast, 15).toHexString(),
      },
      {
        name: 'active',
        value: Color.mix(color, contrast, 20).toHexString(),
      },
    ];
  }
  static getTintOrShadeColor(colorValue) {
    const color = Color(colorValue);
    const contrast = this.getContrastColor(colorValue);
    return Color.mix(color, contrast, 50).toHexString();
  }
  /**
   * Return best contrast color (black or white)
   * @param {string} colorValue
   */
  static getContrastColor(colorValue) {
    if (Color.isReadable(colorValue, 'white', {})) {
      return 'white';
    }
    return 'black';
  }
}

export { ColorHelper as C };
