import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BeAPartnerComponent } from './components/pages/be-a-partner/be-a-partner.component';
import { LandingPagesComponent } from './landing-pages.component';
import { SiteComponent } from '../site/site.component';
import { PreRegistrationPartnerComponent } from './components/pages/pre-registration-partner/pre-registration-partner.component';
import { BeAnAffiliatedComponent } from './components/pages/be-an-affiliated/be-an-affiliated.component';
import { PriceComponent } from './components/pages/newSignature/price/price.component';
import { LeadCaptureComponent } from './components/pages/newSignature/lead-capture/lead-capture.component';
import { NewSignature } from './components/pages/newSignature/newSignature/newSignature.component';
import { PageForCompaniesComponent } from './components/pages/page-for-companies/page-for-companies.component';

const checkoutRoutes: Routes = [
  {
    path: 'seja-um-parceiro',
    component: LandingPagesComponent,
    children: [
      {
        path: '',
        component: BeAPartnerComponent,
      }
    ]
  },
  {
    path: 'pre-cadastro',
    component: LandingPagesComponent,
    children: [
      { path: '', component: PreRegistrationPartnerComponent },
    ]
  },
  {
    path: 'afiliados',
    component: LandingPagesComponent,
    children: [
      { path: '', component: BeAnAffiliatedComponent },
    ]
  },
  {
    path: 'para-empresas',
    component: LandingPagesComponent,
    children: [
      { path: '', component: PageForCompaniesComponent },
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(checkoutRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PartnerRoutingModule { }
