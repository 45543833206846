<div class="testimony">
    <div class="container position-relative" *ngIf="cities && cities.length">
        <div class="swiper-container" [swiper]="config">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let city of cities">
                    <a href="/{{city.url}}">
                        <div class="card">
                            <img
                                src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/getSEOImage?objId={{city.id}}"
                                alt="Imagem principal"
                                (error)="changeSource($event)">
                            <h4>{{ city.name }}</h4>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="config.navigation" id="city-button-prev" class="swiper-button-prev btn d-none d-xl-flex">
        </div>
        <div *ngIf="config.navigation" id="city-button-next" class="swiper-button-next btn d-none d-xl-flex">
        </div>
        <div *ngIf="config.pagination" id="attended-pagination" class="d-xl-none swiper-pagination"></div>
    </div>
</div>