import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.scss']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  public questionsList: any = [];
  public loadingQuestions: boolean;
  public urlIdentifier: string;

  constructor(
    private util: UtilService,
    private activatedRoute: ActivatedRoute
  ) { 
    this.util.createCanonicalURL();
  }

  ngOnInit(): void {
    this.getCommonQuestions();
  }

  private getCommonQuestions() {
    this.urlIdentifier = window.location.hash.replace('#', '');

    this.loadingQuestions = true;

    this.util.getCommonQuestions().subscribe({
      next: result => {
        this.loadingQuestions = false;
        this.questionsList = this.formatList(result);
        this.scroll();
      },
      error: result => {
        this.loadingQuestions = false;
      }
    });
  }

  private formatList(list) {
    const returnList = [];
    list.forEach(item => {
      if (!returnList.find(i => i.category.name === item.parent.name)) {
        returnList.push({
          category: item.parent,
          questions: [this.mountQuestion(item)]
        })
      } else {
        returnList.find(i => i.category.name === item.parent.name).questions.push(this.mountQuestion(item))
      }
    });
    return returnList;
  }

  private mountQuestion(question) {
    return {
      content: question.content,
      contentFormat: question.contentFormat,
      contentHtml: question.contentHtml,
      contentMarkdown: question.contentMarkdown,
      identifier: question.identifier,
      name: question.name,
      order: question.order,
      expanded: this.urlIdentifier && this.urlIdentifier === question.identifier ? true : false
    }
  }

  private scroll() {
    setTimeout(() => {
      const fragment = this.activatedRoute.snapshot.fragment;
      if (fragment) {
        const elementRef = document.getElementById(fragment);
        if (elementRef) elementRef.scrollIntoView();
      }
    }, 500);

  }

}
