import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() logoId: string;
  @Input() type: string;
  public whatsAppLink: string;
  public footerSeals: any;
  currentEnvironment: string = environment.base_api;
  logo: string;

  constructor(private utilService: UtilService) {
    this.utilService.getWhatsappNumber().subscribe({
      next: resp => {
        if (!resp.phone) {
          return;
        }

        this.whatsAppLink = 'https://api.whatsapp.com/send?phone=' + resp.phone;
      }
    });
  }

  ngOnInit(): void {
    this.utilService.getSiteFooterSeals(this.type).subscribe({
      next: resp => {
        this.footerSeals = resp.images;
      }
    })
    if (this.logoId) this.logo = this.currentEnvironment + "/api/1/portal/drLavaTudo/ecommerceAPI/downloadForCompanies?imageId=" + this.logoId;
    else this.logo = "assets/images/logo_footer.svg";
  }
}
