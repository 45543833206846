import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-testimony-component',
  templateUrl: './testimony-component.component.html',
  styleUrls: ['./testimony-component.component.scss']
})
export class TestimonyComponentComponent implements OnInit {
  @ViewChild(SwiperDirective, { read: false }) directiveRef?: SwiperDirective;
  @Input() customConfig: SwiperConfigInterface
  @Input() origin: string;

  testimonies: any;
  currentEnvironment: string = environment.base_api;

  public getUserImage(item):string {
    return item.responsablePhoto
            ? this.currentEnvironment + '/api/1/portal/drLavaTudo/ecommerceAPI/getTestimonyImg?objectId=' + item._id + '&photoId=' + item.responsablePhoto
            : 'assets/images/User.svg'
  }

  private config: SwiperConfigInterface;

  constructor(private utilService: UtilService) { }

  ngOnInit(): void {
    this.config = this.customConfig || {
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        770: {
          slidesPerView: 1,
          spaceBetween: 20,
        }
      },
      navigation: false,
      pagination: {
        el: '#testimony-pagination',
        type: 'bullets',
        clickable: true
      },
      autoplay: {
        delay: 20000,
        disableOnInteraction: false
      }
    };

    this.utilService.getTestimonies(this.origin).subscribe(resp => {
      if (!resp) return;

      this.testimonies = resp;
    });
  }

}
