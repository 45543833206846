import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccordionComponent implements OnInit {
  @Input() itemsList:any = []
  constructor() { }

  ngOnInit(): void {
  }

  openAccordion(id: string): void {
    let url = window.location.origin + window.location.pathname;
    window.location.href = url + "#" + id;
  }

}
