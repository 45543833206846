import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  providers: [],
})
export class PriceComponent implements OnInit {
  plans: any;
  highlight: any;
  standard: any;
  benefits: any;
  size: any;
  processing: boolean;
  rating: any;
  zipcode: any;
  city: any;
  visible = false;
  refId: String;
  environment: any = environment;

  message = 'Vim do site da DLT e quero saber os planos empresariais que atendam as necessidades da minha empresa';

  ratingStyle = {
    starsStyle: { height: '14px', width: '14px ' },
    ratingStyle: { 'font-size': '18px' },
    countStyle: { 'font-size': '11px', color: '#68BCAB', 'font-style': 'italic', 'white-space': 'nowrap', 'text-decoration': 'underline', 'margin-left': '15px' }
  };

  @ViewChild('zipcode') zipcodeField: ElementRef;

  customerPortalUrl: string;
  customer: any;
  isLogged: boolean;
  planId: any;
  coordinates: { lon: any; lat: any; };
  userCode: any;
  list = [];
  standardQntd = 0;
  count = 0;
  attendenceMessage: string;
  attendence: boolean;
  swiperConfig: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 10,
    breakpoints: {
      770: {
        slidesPerView: "auto",
        spaceBetween: 0,
        centeredSlides: true,
        watchOverflow: true,
      }
    },
    navigation: true
  };

  constructor(
    private loaderService: LoaderService,
    private util: UtilService,
    private userService: UsersService,
    private locationService: LocationService,
    private snackbarService: SnackBarService,
    private authService: AuthService
  ) {
    this.userService.userIsLogged().subscribe((status) => {
      this.isLogged = status;

      if(status) {
        const planId = sessionStorage.getItem('planId');
        this.planId = planId;
        if (planId) {
          this.getPosition(planId)
        }
      }
    });
  }

  ngOnInit(): void {
    this.refId = localStorage.getItem('refId')

    if (this.refId && this.refId.length !== 24) {
      this.refId = null;
    }
    const zipcode: any = localStorage.getItem('zipcode')
    if (zipcode) {
      this.zipcode = zipcode;
      this.city = localStorage.getItem('city')

      if (zipcode.length === 8) {
        this.locationService.findByZipCode(zipcode.replaceAll(/[-_]/g, '')).subscribe(address => {
          this.city = address.city;

          if (address.isAvaiable) {
            this.attendenceMessage = "Esse CEP é atendido pela nossa equipe!"
          } else {
            this.attendenceMessage = "Esse CEP não é atendido no momento."
          }
        });

        this.checkAttendence(zipcode);
      }
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          this.getAdress(position.coords)
        },
          error => {
            this.getAdress(null)
          })
      } else {
        this.getAdress(null)
      }
    }

    this.util.getPlanPrices().subscribe(resp => {
      this.swiperConfig.initialSlide = resp.plans && resp.plans.length ? Math.floor(resp.plans.length/2) : 0;
      this.plans = resp.plans;
      this.highlight = resp.displayProducts.highlight
      this.standardQntd = resp.displayProducts.standard.length
      this.standard = resp.displayProducts.standard
      this.benefits = resp.benefits;
      this.size = "width: calc(100%/" + (resp.plans.length + 1) + ");"
    })

    this.customerPortalUrl = this.environment.customer_portal_url;
  }

  ngAfterViewInit(): void {
    const whatsappLogin = localStorage.getItem('whatsappLogin');

    if (whatsappLogin) {
      this.authService.openModalLogin();
    }
  }

  showList(): void {
    if (this.standard.standardExpanded && this.standard.standardExpanded.length) {
      this.list = this.standard.standardExpanded.slice(0, this.list.length + 5);
      if (this.list)
        this.standardQntd = this.standard.standardExpanded.length - this.list.length;
    }
  }

  checkAttendence(id) {
    let temp;
    if (id === null) {
      this.attendenceMessage = null;
      this.city = null;
      const element: any = document.getElementById('Mzipcode');

      if (element && element.value) {
        temp = element.value.replaceAll(/[-_]/g, '');
        this.zipcode = temp;
      }
    } else {
      temp = id.replaceAll(/[-_]/g, '');
      //if(temp === this.zipcode) return;
      this.zipcode = temp;
    }
    this.attendence = false;
    this.rating = null;
    this.city= null;
    this.attendenceMessage = null;
    
    if (temp && temp.length === 8) {
      this.locationService.findByZipCode(temp).subscribe(address => {
        this.city = address.city;

        if (address.isAvaiable) {
          this.attendenceMessage = "Esse CEP é atendido pela nossa equipe!";
          this.attendence = true;
          this.searchRatings(temp, address.cityId);
        } else {
          this.attendenceMessage = "Esse CEP não é atendido no momento.";
          this.attendence = false;
          this.rating = null;
        }
      },
        error => {
          this.attendence = false;
          this.rating = null;
          this.snackbarService.error("CEP inválido");
          return;
        });
    }
  }

  searchRatings(temp, cityId): void {
    if (temp && temp.length === 8) {
      localStorage.setItem('zipcode', temp);
      localStorage.setItem('city', this.city);
      if (this.attendence) {
        if (this.coordinates) {
          this.getMap(this.coordinates, cityId);
        } else {
          this.locationService.findCoordinatesByZipCode(temp).subscribe(address => {
            this.processing = true;
            if (address.cityId && address.coordinates && address.coordinates.lon && address.coordinates.lat) {
              this.getMap(address.coordinates, cityId);
            }
          });
        }

      }
    }
  }

  getMap(coordinates, cityId) {
    this.util.getRatingCart({
      zipcode: this.zipcode,
      cityRef: {
        _id: cityId
      },
      coordinates: {
        coordinates: [coordinates.lon, coordinates.lat]
      }
    }).subscribe(resp => {
      if (resp._singleValue === false) {
        this.processing = false;
        return;
      }
      if (resp.ratings.ratingQnt == 0) {
        this.processing = false;
        return;
      }
      this.processing = false;
      this.rating = {
        value: resp.ratings.ratingValue,
        count: 'Veja as ' + resp.ratings.ratingQnt + ' avaliações',
        radius: resp.ratings.ratingRadius
      };
    })
  }

  redirectToMap() {
    window.open('/loja/mapa?zipcode=' + this.zipcode);
  }

  public setColors(plan) {
    return {
      "--dlt-card-background-color": plan.bgColor,
      "--dlt-card-header-background-color": plan.bgColor,
      "color": plan.color,
      "--dlt-card-color": plan.color,
    }
  }

  public setColorsContent(plan) {
    return {
      "color": plan.color
    }
  }

  public contactSalle() {
    this.util.getWhatsappNumber().subscribe({
      next: resp => {
        if (!resp.phone) {
          return;
        }

        const phone = resp.phone;
        window.location.href = 'https://api.whatsapp.com/send?phone=' + phone + '&text=' + encodeURIComponent(this.message);
      }
    });

  }

  public getPosition(event): void {
    if (event.target && event.target.name) {
      this.planId = event.target.name;
    }

    if (this.isLogged) {
      const explorer_user = JSON.parse(localStorage.getItem("explorer_user_map"));
      this.userCode = explorer_user?.ecommerce?.users[0]?.code ? explorer_user.ecommerce.users[0].code : null;

      this.captureLead()
    }
    else {
      this.authService.openModalLogin();
      sessionStorage.setItem('planId', this.planId);
    }
  }

  public getAdress(coords): void {
    if (coords) {
      const coordinates = {
        lon: coords.longitude,
        lat: coords.latitude
      }

      this.coordinates = coordinates;

      this.loaderService.startLoader();
      this.locationService.findAddressByCoordinates(coordinates, null).subscribe(resp => {
        this.zipcode = resp.zipcode;
        this.city = resp.city;
        this.checkAttendence(resp.zipcode);
        this.loaderService.stopLoader();
      }, err => {
        this.locationService.findAddressByCoordinates(null, null).subscribe(resp => {
          this.zipcode = resp.zipcode;
          this.city = resp.city;
          this.checkAttendence(resp.zipcode);
          this.loaderService.stopLoader();
        })
      })
    } else {
      this.loaderService.startLoader();
      this.locationService.findAddressByCoordinates(null, null).subscribe(resp => {
        this.zipcode = resp.zipcode;
        this.city = resp.city;
        this.checkAttendence(resp.zipcode);
        this.loaderService.stopLoader();
      })
    }
  }

  public findAddress(event) {
    let temp: any = event.target.value.replaceAll(/[-_]/g, '');

    if (temp.length === 8) {
      this.locationService.findByZipCode(temp).subscribe(address => {
        this.city = address.city

        if (address.isAvaiable) {
          this.attendenceMessage = "Esse CEP é atendido pela nossa equipe!"
        } else {
          this.attendenceMessage = "Esse CEP não é atendido no momento."
        }
      });
    }

  }
  public captureLead(): void {
    let temp: any = this.zipcode.replaceAll(/[-_]/g, '');
    if (temp.length != 8 && !temp.includes('_') || !this.city) {
      this.snackbarService.error('O CEP informado é inválido');
      return;
    }

    const data = {
      "data": {
        "customer": this.userCode,
        "selectedPlan": this.planId,
        "zipCode": temp,
        "city": this.city,
        "relatedLeadId": this.refId
      }
    }

    this.loaderService.startLoader();
    this.userService.getOrCreateSubscriptionLead(data).subscribe((res) => {
      this.loaderService.stopLoader();
      localStorage.removeItem('refId');
      window.location.href = "/assinatura/sucesso";
    }, err => {
      this.loaderService.stopLoader();
      this.snackbarService.error('Desculpe, algo deu errado. Favor entrar em contato com a nossa equipe.');
    });
  }
}



