import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { timeout } from 'rxjs/operators';

declare var window: any;

@Injectable()
export class MetaService {
  constructor(private httpService: HttpService) {}

  private async trigger(event: string, data?: any) {
    const explorerUser = JSON.parse(localStorage.getItem('explorer_user_map'));
    const userToken = explorerUser?.ecommerce?.users[0]?.accessToken?.token ? explorerUser.ecommerce.users[0].accessToken.token : null;

    let body: any = {
      eventName: event,
      eventTime: new Date().getTime(),
      eventId: data && data.eventID ? data.eventID : null,
      userAgent: window.navigator.userAgent,
      fbc: this.getCookie('_fbc'),
      fbp: this.getCookie('_fbp'),
      url: data && data.virtualPageURL ? data.virtualPageURL : window.location.href
    };

    if (!!userToken) {
      await this.httpService.post('drLavaTudo/loggedAreaAPI/conversionsEvent', body, true).toPromise();
    } else {
      await this.httpService.post('drLavaTudo/ecommerceAPI/conversionsEvent', body, false).toPromise();
    }
  }

  public pageView(url: string, code: string): void {
    this.trigger('PageView', {
      virtualPageURL: url,
      eventID: code
    });
    this.trigger('ViewContent', {
      virtualPageURL: url,
      eventID: code
    });
  }

  public loginSuccess(code: string): void {
    this.trigger('CustomizeProduct', {eventID: code});
  }

  private getCookie = (name): any => {
    let cookie = {};

    document.cookie.split(';').forEach(el => {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });

    return cookie[name];
  }

  public partnerLead(code: string): void {
    this.trigger('lead_parceiros', {
      eventID: code
    });
  }

  public async affiliatedLead(code: string) {
    await this.trigger('lead_afiliados', {
      eventID: code
    });
  }
}
