<div class="how-works-container" *ngIf="pageContent && pageContent.sectionElements && pageContent.sectionElements.length">
  <div *ngFor="let element of pageContent.sectionElements; let i = index">

    <div class="d-xl-flex justify-content-center conditions {{i < pageContent.sectionElements.length -1 ? 'content-space' : ''}}"
      *ngIf="element.active && element.contentName && element.contentName === 'basicConditions'">
      <img class="img"
        src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/{{nameMethod}}?objId={{element.content.image._id}}"
        alt="Pedro Sampaio - Ganha 50 novos clientes por mês com a plataforma  Dr. Lava Tudo" />
      <div class="conditions-title">
        <sy-text class="title" type="h4">{{element.content.title}}</sy-text>
        
        <div *ngIf="element.content.content && element.content.content.length">
          <div class="d-flex align-items-center list" *ngFor="let i of element.content.content">
            <img class="check" src="assets/images/check-2.svg" />
            <sy-text type="body">{{i}}</sy-text>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center {{i < pageContent.sectionElements.length -1 ? 'content-space' : ''}}"
      *ngIf="element.active && element.contentName && element.contentName === 'stepsDoctor' || element.contentName === 'stepsAffiliated'">
      <div class="steps-title">
        <sy-text class="title" type="h4">{{element.content.title}}</sy-text>

        <div *ngIf="element.content.content && element.content.content.length">
          <div class="steps-text" *ngFor="let step of element.content.content; let i = index">
            <sy-text class="step-order" type="h6">{{i+1}}° Passo</sy-text>
            <hr />
            <sy-text class="step-content" type="small">{{step}}</sy-text>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-end {{i < pageContent.sectionElements.length -1 ? 'content-space' : ''}}" 
      *ngIf="element.active && element.contentName && element.contentName === 'video' && videoLink">
      <iframe class="video" [src]="videoLink" title="Seja um parceiro" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

  </div>
</div>