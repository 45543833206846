<app-header
    origin="affiliatedPage" 
    [affiliatedPortalUrl]="portalUrl" 
    [registrationStatus]="registrationStatus"
    [loggedInButton]="loggedInButton"
    [loggedOutButton]="loggedOutButton"
></app-header>

<app-landing-pages-header *ngIf="affiliatedPageContent && affiliatedPageContent.pageHeader" [origin]="'affiliatedPage'"
    [headerContent]="affiliatedPageContent.pageHeader" [affiliatedPortalUrl]="portalUrl" [isLoged]="isLoged"
    [loading]="loading" [registrationStatus]="registrationStatus">
</app-landing-pages-header>

<div class="card-container d-lg-flex d-none flex-wrap justify-content-center"
    *ngIf="affiliatedPageContent && affiliatedPageContent.pageHeaderCards">
    <div *ngFor="let i of affiliatedPageContent.pageHeaderCards">
        <dlt-card>
            <dlt-card-header slot="header" headline={{i.title}}
                image="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/getAffiliatedImages?objId={{i.icon._id}}">
            </dlt-card-header>

            <dlt-card-content slot="content"> {{i.content}} </dlt-card-content>
        </dlt-card>
    </div>
</div>

<div class="card-container w-100 d-lg-none" *ngIf="affiliatedPageContent && affiliatedPageContent.pageHeaderCards">
    <div class="container">
        <sy-carousel class="carousel" items="2" min-width="300" slide-by="1" loop="true" transition-animation="slide" mouse-drag=true
            auto-play="true" auto-play-speed="7000" transition="0.7" hide-nav-buttons="true">
            <sy-slide *ngFor="let i of affiliatedPageContent.pageHeaderCards">
                <dlt-card>
                    <dlt-card-header slot="header" headline={{i.title}}
                        image="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/getAffiliatedImages?objId={{i.icon._id}}">
                    </dlt-card-header>

                    <dlt-card-content slot="content"> {{i.content}} </dlt-card-content>
                </dlt-card>
            </sy-slide>
        </sy-carousel>
    </div>
</div>

<div class="content-container" *ngIf="affiliatedPageContent && affiliatedPageContent.pageContent && 
      affiliatedPageContent.pageContent.length && hasActiveSection(affiliatedPageContent.pageContent)">
    <div *ngFor="let section of affiliatedPageContent.pageContent; let i = index"
        [ngClass]="setSectionClass(section, i, affiliatedPageContent.pageContent.length)">
        <div *ngIf="section.active && section.sectionName === 'howWorks'">
            <app-how-works class="app-how-works" [pageContent]="section.sectionContent"
                [origin]="'affiliatedPage'"></app-how-works>

            <div *ngIf="section.cta" class="cta-style">
                <div class="d-flex align-items-center flex-column" *ngFor="let cta of section.cta">
                    <sy-button *ngIf="!cta.loginButton && !loading" style={{setColors(cta)}} label={{cta.buttonTitle}}
                        href={{cta.buttonLink}} content="text-only" fab="true" target={{target}}></sy-button>

                    <sy-button *ngIf="cta.loginButton && isLoged" style={{setColors(cta)}}
                        [href]="registrationStatus === 'initial' ? 'afiliados#affiliated-form' : portalUrl"
                        label={{cta.buttonLogedTitle}} content="text-only" fab="true"></sy-button>


                    <sy-button *ngIf="cta.loginButton && !isLoged" style={{setColors(cta)}} (click)="openModal()"
                        label={{cta.buttonTitle}} content="text-only" fab="true"></sy-button>
                </div>
            </div>
        </div>

        <div class="testimony" [ngClass]="{'testimony-bg' : i > 0}"
            *ngIf="section.active && section.sectionName === 'testimonies'">
            <div class="d-flex justify-content-center align-items-end">
                <sy-text type="h4">Veja os depoimentos de alguns dos nossos afiliados</sy-text>
            </div>
            <app-testimony-component [customConfig]="config2" [origin]="'affiliatedPage'"></app-testimony-component>

            <div *ngIf="section.cta" class="cta-style">
                <div class="d-flex align-items-center flex-column" *ngFor="let cta of section.cta">
                    <sy-button *ngIf="!cta.loginButton && !loading" style={{setColors(cta)}} label={{cta.buttonTitle}}
                        href={{cta.buttonLink}} content="text-only" fab="true" target={{target}}></sy-button>


                    <sy-button *ngIf="cta.loginButton && isLoged" style={{setColors(cta)}}
                        [href]="registrationStatus === 'initial' ? 'afiliados#affiliated-form' : portalUrl"
                        label={{cta.buttonLogedTitle}} content="text-only" fab="true"></sy-button>


                    <sy-button *ngIf="cta.loginButton && !isLoged" style={{setColors(cta)}} (click)="openModal()"
                        label={{cta.buttonTitle}} content="text-only" fab="true"></sy-button>
                </div>
            </div>
        </div>

        <div class="commonQuestions" *ngIf="section.active && section.sectionName === 'commonQuestions'">
            <div class="accordion">
                <dlt-accordion [accordionTitle]="'Perguntas frequentes'"></dlt-accordion>
            </div>

            <div *ngIf="section.cta" class="cta-style commom-question-button">
                <div class="d-flex align-items-center flex-column" *ngFor="let cta of section.cta">
                    <sy-button *ngIf="!cta.loginButton && !loading" style={{setColors(cta)}} label={{cta.buttonTitle}}
                        href={{cta.buttonLink}} content="text-only" fab="true" target={{target}}></sy-button>

                    <sy-button href={{cta.buttonLink}} *ngIf="cta.loginButton && isLoged" style={{setColors(cta)}}
                        [href]="registrationStatus === 'initial' ? 'afiliados#affiliated-form' : portalUrl"
                        label={{cta.buttonLogedTitle}} content="text-only" fab="true"></sy-button>

                    <sy-button *ngIf="cta.loginButton && !isLoged" style={{setColors(cta)}} (click)="openModal()"
                        label={{cta.buttonTitle}} content="text-only" fab="true"></sy-button>
                </div>
            </div>
        </div>

        <div id="affiliated-form" class="d-lg-flex flex-column justify-content-center align-items-center"
            *ngIf="section.active && section.sectionName === 'form'">
            <app-affiliated-login [isLoged]="isLoged" [portalUrl]="portalUrl" [tdu]="affiliatedPageContent.tdu"
                [socialNetWorks]="affiliatedPageContent.socialNetworks" [registrationStatus]="registrationStatus"
                (openModal)="openModal()">
            </app-affiliated-login>

            <div *ngIf="section.cta" class="cta-style">
                <div class="d-flex align-items-center flex-column" *ngFor="let cta of section.cta">
                    <sy-button *ngIf="!cta.loginButton && !loading" style={{setColors(cta)}} label={{cta.buttonTitle}}
                        href={{cta.buttonLink}} content="text-only" fab="true" target={{target}}></sy-button>

                    <sy-button *ngIf="cta.loginButton && isLoged" style={{setColors(cta)}}
                        [href]="registrationStatus === 'initial' ? 'afiliados#affiliated-form' : portalUrl"
                        label={{cta.buttonLogedTitle}} content="text-only" fab="true"></sy-button>

                    <sy-button *ngIf="cta.loginButton && !isLoged" style={{setColors(cta)}} (click)="openModal()"
                        label={{cta.buttonTitle}} content="text-only" fab="true"></sy-button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>