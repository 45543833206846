<div *ngIf="content" class="satisfaction">
    <div class="container">
        <h2>
            <p class="title-green text-margin">{{content.title[0]}}</p>
            <p class="title-black text-margin">{{content.title[1]}}</p>
        </h2>
        <div class="accordion">
            <app-accordion [itemsList]="questionsList"></app-accordion>
        </div>

        <div class="d-flex justify-content-center gap-2">
            <a *ngFor="let cta of content.siteCta" href={{cta.buttonLink}} class="more-link" [ngStyle]="setColors(cta)"> {{cta.buttonTitle}} </a>
        </div>
    </div>
</div>