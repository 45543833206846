<div class="section-1">
    <!--Desktop-->
    <div class="row w-80 mx-auto" *ngIf="!isMobile">
        <div class="col-lg-6 d-flex flex-column my-auto">
            <div class="row">
                <div class="col-lg-12">
                    <img class="logo" src="assets/images/logo_ai.svg" alt="Dr. Lava Tudo">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h3 class="landingTitle">Cupom de desconto Dr. Lava Tudo</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h6 class="landingContent">Confira nossas promoções e economize na limpeza do seu sofá tapete ou
                        estofado.</h6>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <app-goals-image></app-goals-image>
        </div>
    </div>

    <!--Mobile-->
    <div class="row w-100 mx-auto" *ngIf="isMobile">
        <div class="col-12">
            <img class="logo mx-auto" src="assets/images/logo_ai.svg" alt="Dr. Lava Tudo">
        </div>
        <div class="col-12">
            <app-goals-image></app-goals-image>
        </div>
        <div class="col-12">
            <h3 class="landingTitle">Cupom de desconto Dr. Lava Tudo</h3>
        </div>
        <div class="col-12">
            <h6 class="landingContent">Confira nossas promoções e economize na limpeza do seu sofá tapete ou estofado.
            </h6>
        </div>
    </div>

    <div class="row w-80 mx-auto deals">
        <div class="col-lg-4 col-12" *ngFor="let coupon of couponList">
            <div class="card-discount" (click)="goToEcommerce(coupon.identifier)">
                <div class="discount-content">
                    <div class="card-title">
                        <img class="card-icon" src="assets/images/icons/discount-ticket.svg">
                        {{coupon.name}}
                    </div>
                    <div class="card-content">
                        {{coupon.description}}
                    </div>
                </div>
                <div class="discount-footer">
                    Usar cupom
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section-2">
    <h3 class="title">Como funciona?</h3>
    <div class="row width-content mx-auto">
        <div class="col-lg-6 col-12 col-right-space end-spacing">
            <div class="info-card">
                <div class="info-title">
                    <div><img class="card-icon" src="assets/images/icons/ecommerce-choice.svg" alt="Cupom"></div>
                    <div>Desconto na compra online</div>
                </div>
                <div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>1</span>
                        </div>
                        <span class="info-content">Acesse nossa loja virtual</span>
                    </div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>2</span>
                        </div>
                        <span class="info-content">Selecione o serviço desejado e informe os detalhes</span>
                    </div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>3</span>
                        </div>
                        <span class="info-content">Aplique o nome do cupom no carrinho de compras</span>
                    </div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>4</span>
                        </div>
                        <span class="info-content">Economize na sua limpeza</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12 col-left-space end-spacing">
            <div class="info-card">
                <div class="info-title">
                    <div>
                        <img class="card-icon" src="assets/images/icons/customer-support.svg" alt="Cupom">
                    </div>
                    <div>
                        Desconto na compra por telefone ou whatsapp
                    </div>
                </div>
                <div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>1</span>
                        </div>
                        <span class="info-content">Ligue 0800 944 9999 ou envie um WhatsApp clicando aqui</span>
                    </div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>2</span>
                        </div>
                        <span class="info-content">Solicite o serviço desejado e informe os detalhes</span>
                    </div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>3</span>
                        </div>
                        <span class="info-content">Informe o nome do cupom para o atendente</span>
                    </div>
                    <div class="info-item">
                        <div class="indicator gray">
                            <span>4</span>
                        </div>
                        <span class="info-content">Garanta seu desconto</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section-3">
    <div class="row mx-auto">
        <div class="col-lg-6 col-12 spacing-right">
            <div class="tech-photo">
                <app-technician-image></app-technician-image>
            </div>            
        </div>
        <div class="col-lg-6 col-12 spacing-left">
            <h3 class="title">Por que a Dr. Lava Tudo?</h3>
            <div class="info-item">
                <div class="indicator green">
                    <img src="assets/images/icons/checkmark.svg">
                </div>
                <span class="info-content">Mais comodidade: serviços realizados em domicílio</span>
            </div>
            <div class="info-item">
                <div class="indicator green">
                    <img src="assets/images/icons/checkmark.svg">
                </div>
                <span class="info-content">Mais saúde: higienização que elimina em até 99,7% dos ácaros, fungos e
                    bactérias</span>
            </div>
            <div class="info-item">
                <div class="indicator green">
                    <img src="assets/images/icons/checkmark.svg">
                </div>
                <span class="info-content">Mais qualidade: serviços aprovados por mais de 500.000 clientes</span>
            </div>
            <div class="info-item">
                <div class="indicator green">
                    <img src="assets/images/icons/checkmark.svg">
                </div>
                <span class="info-content">Garantia de 7 dias: ou você fica 100% satisfeito, ou refazemos o
                    serviço</span>
            </div>
            <div class="info-item">
                <div class="indicator green rounded-circle">
                    <img src="assets/images/icons/checkmark.svg">
                </div>
                <span class="info-content">Preço justo: limpar um estofado ou tapete custa, em média, 4% do valor de um
                    item novo</span>
            </div>
            <div class="info-item">
                <div class="indicator green">
                    <img src="assets/images/icons/checkmark.svg">
                </div>
                <span class="info-content">Mais segurança: produtos certificados pela ANVISA e técnicos altamente
                    qualificados.</span>
            </div>
        </div>
    </div>
</div>

<div class="section-4">
    <h3 class="title">O que os clientes acham da Dr. Lava Tudo?</h3>
    <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
            <iframe class="video-frame" src="https://www.youtube.com/embed/FEtThA1Hi64" title="YouTube video player"
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
        encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-12 d-flex flex-column align-items-center button-spacing">
            <a class="btn-green" href="{{ecommerceUrl}}" target="_blank">
                    Faça uma simulação online
            </a>
        </div>
    </div>
</div>