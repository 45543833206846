import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

enum SnackBarType {
    'error', 'warning', 'success', 'info'
}

@Injectable()
export class SnackBarService {

  snackBarConfig: MatSnackBarConfig;
  horizontalPosition: MatSnackBarHorizontalPosition = 'left';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private snackBar: MatSnackBar) { }

    openSnackBar(message: string, type: SnackBarType, snackBarAutoHide: string = '-1', action?: string): any {
        this.snackBarConfig = new MatSnackBarConfig();

        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = parseInt(snackBarAutoHide, 0);

        switch (type) {
            case SnackBarType.error:
                this.snackBarConfig.panelClass = 'snackbar-error';
                break;
            case SnackBarType.warning:
                this.snackBarConfig.panelClass = 'snackbar-warning';
                break;
            case SnackBarType.success:
                this.snackBarConfig.panelClass = 'snackbar-success';
                break;
            case SnackBarType.info:
                this.snackBarConfig.panelClass = 'snackbar-info';
                break;
        }

        return this.snackBar.open(message, action, this.snackBarConfig);
    }

    error(message: string, snackBarAutoHide: string = '-1'): any {
        return this.openSnackBar(message, SnackBarType.error, snackBarAutoHide, 'X');
    }

    warning(message: string, snackBarAutoHide: string = '2000'): any {
        return this.openSnackBar(message, SnackBarType.warning, snackBarAutoHide);
    }

    success(message: string, snackBarAutoHide: string = '2000'): any {
        return this.openSnackBar(message, SnackBarType.success, snackBarAutoHide);
    }

    info(message: string, snackBarAutoHide: string = '2000', action: string): any {
        return this.openSnackBar(message, SnackBarType.info, snackBarAutoHide, action);
    }
}

