<div class="testimony" id="testimony">
  <div class="container position-relative" *ngIf="testimonies">
    <div class="container">
      <sy-carousel
        items="2"
        gutter="24"
        min-width="300"
        loop="true"
        slide-by="1"
        transition-animation="slide"
        mouse-drag=true
        hide-dots="true"
        auto-play="true"
        auto-play-speed="7000"
        transition="0.7"
        hide-nav-buttons="true"
      >
        <sy-slide *ngFor="let testimony of testimonies; let i = index">
          <dlt-card>
            <dlt-card-header slot="header" image="assets/images/ico.svg"></dlt-card-header>

            <dlt-card-content slot="content"> {{ testimony.testimonyText }} </dlt-card-content>

            <dlt-card-footer
              slot="footer"
              image={{getUserImage(testimony)}}
              author={{testimony.responsableName}}
              company={{testimony.descriptiveText}}
            >
            </dlt-card-footer>
          </dlt-card>
        </sy-slide>
      </sy-carousel>
    </div>

    <!-- <div class="swiper-container" [swiper]="config">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let testimony of testimonies; let i = index">
          <dlt-card>
            <dlt-card-header slot="header" image="assets/images/ico.svg"></dlt-card-header>

            <dlt-card-content slot="content"> {{ testimony.testimonyText }} </dlt-card-content>

            <dlt-card-footer
              slot="footer"
              image={{getUserImage(testimony)}}
              author={{testimony.responsableName}}
              company={{testimony.descriptiveText}}
            >
            </dlt-card-footer>
          </dlt-card>
        </div>
      </div>
    </div>
    <div *ngIf="config.navigation" id="testimony-button-prev" class="swiper-button-prev btn btn-secondary d-none d-lg-flex"></div>
    <div *ngIf="config.navigation" id="testimony-button-next" class="swiper-button-next btn btn-secondary d-none d-lg-flex"></div>
    <div *ngIf="config.pagination" id="testimony-pagination" class="swiper-pagination"></div> -->

  </div>
</div>