import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UsersService } from './shared/services/users.service';
import { AuthService } from './shared/services/auth.service';
import { TrackingService } from './shared/services/tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild("modalLogin") modalRef;
  customer
  title = 'site';
  currentUrl: string = null;
  constructor(
      iconRegistry: MatIconRegistry, 
      sanitizer: DomSanitizer, 
      private router: Router, 
      private userService: UsersService, 
      private authService: AuthService, 
      private trackingService: TrackingService
    ) {
    iconRegistry.addSvgIcon('google-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/logo-google.svg'));
  }

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {      
      let url = event.url.split('?');
      if(url[0] != '/assinatura/precos'){
        sessionStorage.removeItem('planId');
      }
    });
  }

  ngAfterViewInit() {
    this.authService.setLoginRef(this.modalRef);
  }

  loginSuccess(data) {
    if (data && data.detail && data.detail.customer) {
      const customerData = data.detail.customer;
      const userDidLogin = data.detail.userDidLogin;
      this.userService.setUserDidLogin(userDidLogin);

      this.customer = customerData;
      this.userService.logIn(customerData)
      this.userService.createOrUpdateConvertedLead(customerData._id).subscribe(() => {});
      UsersService.userLoggedIn.emit(customerData);
      this.trackingService.loginSuccess(customerData);
    }
  }
}
