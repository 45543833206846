import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goals-image',
  templateUrl: './goals-image.component.html',
  styleUrls: ['./goals-image.component.scss']
})
export class GoalsImageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
