import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { UtilService } from "src/app/shared/services/util.service";
import { environment } from "src/environments/environment";
import { DOCUMENT } from '@angular/common';
import { UsersService } from 'src/app/shared/services/users.service';
import { AuthService } from "src/app/shared/services/auth.service";
@Component({
  selector: "app-page-local-seo",
  templateUrl: "./page-local-seo.component.html",
  styleUrls: ["./page-local-seo.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PageLocalSEOComponent implements OnInit {
  @ViewChild('modalLogin') modalRef: any;
  
  public environment:any = environment;
  public org: string = environment['X-Explorer-Account-Token'];
  public userLogged: boolean;
  public contentPage: any;
  public processing: boolean = true;
  public isMobile: boolean = false;

  constructor(
    private utilService: UtilService, 
    private meta: Meta, 
    private title: Title, 
    @Inject(DOCUMENT) private dom, 
    private userService: UsersService,
    private authService: AuthService
  ) {
    this.processing = true;
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 950;
    let identifier = window.location.href.replace(window.location.origin + "/", "").split("?")[0];

    this.utilService.getSEOPage(identifier).subscribe((resp) => {
      if (!resp) window.location.href = "/404";
      else {
        this.contentPage = resp;
        this.contentPage.content = this.contentPage.content.replaceAll(
          /src=("|')\/api\//g,
          "src=$1" + this.environment.base_api + "/api/"
        );
        this.updateCanonicalUrl(window.location.origin + "/" + resp.canonical)
        this.processing = false;

        if(resp.principalTitle) {
          this.title.setTitle(resp.principalTitle);
        }
        
        if (resp.principalDescription) {
          this.meta.addTag({
            name: "description",
            content: resp.principalDescription,
          });
        }
      }
    });

    this.userService.userIsLogged().subscribe((status)=> {
      this.userLogged = status;
    });
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  public goToEcommerce(){
    window.location.href = this.environment.ecommerce_url;
  }

  openModalLogin(): void {
    this.authService.openModalLogin();
  }
}
