import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-recent-posts',
  templateUrl: './recent-posts.component.html',
  styleUrls: ['./recent-posts.component.scss']
})
export class RecentPostsComponent implements OnInit {
  @ViewChild(SwiperDirective, { read: true }) directiveRef?: SwiperDirective;
  @Input() content: any;

  config: SwiperConfigInterface;
  currentEnvironment: string = environment.base_api;
  posts: any;

  constructor(private util: UtilService) { }

  ngOnInit(): void {
    if(!this.content || !Object.keys(this.content).length) {
      this.content = {
        "title": "Últimos Posts do Blog" 
      }
    }

    this.config =  {
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: true,
      pagination: {
        el: '#recent-pagination',
        type: 'bullets',
        clickable: true
      },
      autoplay: {
        delay: 20000,
        disableOnInteraction: false
      }
    };
    this.util.getRecentsPosts().subscribe(result => {
      if (!result) { return; }
      this.posts = result;
    });
  }

}
