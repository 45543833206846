// Modules
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared/shared.module';
import { SiteModule } from '../site/site.module';
import { PartnerRoutingModule } from './landing-pages-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// import { SwiperModule } from 'ngx-swiper-wrapper';



// Components
import { BeAPartnerComponent } from './components/pages/be-a-partner/be-a-partner.component';
import { LandingPagesComponent } from './landing-pages.component';
import { LandingPagesHeaderComponent } from './shared/landing-pages-header/landing-pages-header.component';
import { HowWorksComponent } from './shared/how-works/how-works.component';
import { PartnerFormComponent } from './shared/partner-form/partner-form.component';
import { PreRegistrationPartnerComponent } from './components/pages/pre-registration-partner/pre-registration-partner.component';
import { PreRegistrationFormComponent } from './components/pages/pre-registration-partner/form/pre-registration-form.component';
import { PreRegistrationSuccessComponent } from './components/pages/pre-registration-partner/success/pre-registration-success.component';
import { PreRegistrationErrorComponent } from './components/pages/pre-registration-partner/error/pre-registration-error.component';
import { PreRegistrationHeaderComponent } from './components/pages/pre-registration-partner/header/pre-registration-header.component';
import { BeAnAffiliatedComponent } from './components/pages/be-an-affiliated/be-an-affiliated.component';
import { AffiliatedLoginComponent } from './shared/affiliated-login/affiliated-login.component';
import { PriceComponent } from './components/pages/newSignature/price/price.component';
import { NewSignature } from './components/pages/newSignature/newSignature/newSignature.component';
import { NgxRatingStarsModule } from 'ngx-rating-stars';
import { LeadCaptureComponent } from './components/pages/newSignature/lead-capture/lead-capture.component';
import { PageForCompaniesComponent } from './components/pages/page-for-companies/page-for-companies.component';

// Providers


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    SharedModule,
    TextMaskModule,
    PartnerRoutingModule,
    FormsModule,
    MatProgressBarModule,
    MatIconModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    NgbModule,
    NgxRatingStarsModule,
    SiteModule
  ],
  exports: [
    CommonModule,
    RouterModule
  ],
  providers: [
  ],
  declarations: [
    LandingPagesHeaderComponent,
    BeAPartnerComponent,
    LandingPagesComponent,
    HowWorksComponent,
    PartnerFormComponent,
    PreRegistrationPartnerComponent,
    PreRegistrationFormComponent,
    PreRegistrationSuccessComponent,
    PreRegistrationErrorComponent,
    PreRegistrationHeaderComponent,
    BeAnAffiliatedComponent,
    AffiliatedLoginComponent,
    PriceComponent,
    LeadCaptureComponent,
    NewSignature,
    PageForCompaniesComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LandingPagesModule { }
