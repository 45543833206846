import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '../models/customer';
// import { ShoppingCart } from '../models/shoppingCart';

import { GtmService } from './gtm.service';
import { HttpService } from './http.service';
import { MetaService } from './meta.service';

export enum Action {
  Visited = '',
  ContactClick = 'contactClick',
  ContactDataView = 'contactDataView',
}

@Injectable()
export class TrackingService {
  constructor(
    private gtmService: GtmService,
    private metaService: MetaService,
    private router: Router,
    private httpService: HttpService
  ) {}

  public pageView(url?: string): void {
    if (!url) {
      this.gtmService.pageView(this.router.url);
    } else {
      this.gtmService.pageView(url);
    }
  }

  public recordAction(action: Action, companyId: string): void {
    const data = {
      action,
      companyId,
      traceId: this.getStoredTraceId(),
      accessToken: this.getAccessToken(),
    };

    let facadeClass = 'ecommerceAPI';
    let loggedArea = false;
    if (data.accessToken) {
      facadeClass = 'loggedAreaAPI';
      loggedArea = true;
    }

    const req = this.httpService
      .post('drLavaTudo/' + facadeClass + '/recordAction', data, loggedArea)
      .toPromise();

    req
      .then(response => {
        this.storeTraceId(response.id);
      })
      .catch(error => {
        console.log(error);
      });
  }

  public loginSuccess(customer: Customer): void {
    const code = this.generateRandomString(10);
    this.gtmService.loginSuccess(customer, code);
    this.metaService.loginSuccess(code);
  }

  public partnerLead(partner: any): void {
    const code = this.generateRandomString(10);
    this.gtmService.partnerLead(partner, code);
    this.metaService.partnerLead(code);
  }

  async affiliatedLead(affiliated: any) {
    const code = this.generateRandomString(10);
    this.gtmService.affiliatedLead(affiliated, code);
    await this.metaService.affiliatedLead(code);
  }

  private storeTraceId = (traceId: string) => {
    localStorage.setItem('traceId', JSON.stringify(traceId));
  }

  private getStoredTraceId = () => {
    return JSON.parse(localStorage.getItem('traceId'));
  }

  private getAccessToken = () => {
    const storedCredentials = JSON.parse(localStorage.getItem("explorer_user_map"));
    return storedCredentials?.ecommerce?.users[0]?.accessToken?.token;
  }

  private generateRandomString(size): string {
    let randomString = '';
    const caracters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < size; i++) {
      randomString += caracters.charAt(Math.floor(Math.random() * caracters.length));
    }
    return randomString;
  }
}
