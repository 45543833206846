import { Component, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-cleaning-demonstration',
  templateUrl: './cleaning-demonstration.component.html',
  styleUrls: ['./cleaning-demonstration.component.scss']
})
export class CleaningDemonstrationComponent implements OnInit {
  private configCleanning: SwiperConfigInterface;

  cards = []

  constructor(private utilService: UtilService) { }

  ngOnInit(): void {
    this.configCleanning = {
      slidesPerView: 3,
      spaceBetween: 20,
      breakpoints: {
        770: {
          slidesPerView: 1,
          spaceBetween: 20,
        }
      },
      navigation: {
        nextEl: '#button-next-cleanning',
        prevEl: '#button-prev-cleanning'
      },
      pagination: {
        el: '#cleanning-pagination',
        type: 'bullets',
        clickable: true
      },
      autoplay: {
        delay: 100000,
        disableOnInteraction: false
      }
    }

    this.utilService.getServiceBeforeAfter().subscribe(result => {
      if(result && result.length) {
        result.forEach(card => {
          this.cards.push({
            title: card.name,
            photoBeforeUrl: environment.api_url + 'drLavaTudo/ecommerceAPI/getPhotoBeforeAfter?objectId=' + card._id + '&photoId=' + card.photoBefore._id,
            photoAfterUrl: environment.api_url + 'drLavaTudo/ecommerceAPI/getPhotoBeforeAfter?objectId=' + card._id + '&photoId=' + card.photoAfter._id
          })
        })


      }
    })
  }
}
