/**
 * Localização
 */
export class Location {
    _id?: string;
    name?: string;
    zipcode: string;
    state?: string;
    stateInitials?: string;
    city?: string;
    quarter?: string;
    street?: string;
    number?: number;
    complement?: string;
    coordinates?: any;
    reference?: string;
    country?: string;
    isAvailable?: boolean; 
};
