import { EventEmitter, Injectable} from '@angular/core';
import { Customer } from '../models/customer';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { UtilService } from './util.service';

@Injectable()
export class UsersService {
  static userLoggedIn: EventEmitter<Customer> = new EventEmitter();
  
  private user = new BehaviorSubject({});
  private userLogged = new BehaviorSubject(false);
  private userDidLogin = new BehaviorSubject(false);

  constructor(
    private httpService: HttpService,
    private utilService: UtilService
  ) { }

  userIsLogged = (): Observable<boolean> => {
    return this.userLogged;
  }

  getUserDidLogin(): boolean {
    return this.userDidLogin.value;
  }

  setUserDidLogin(value: boolean): void {
    if(this.userDidLogin.value === value) return;

    this.userDidLogin.next(value);
  }

  logIn(userData: any): void {
    this.updateUserData(userData);
    this.userLogged.next(true);
  }

  logOut(): void {
    this.userLogged.next(false);
  }

  setLoginStatus(status: boolean): void {
    this.userLogged.next(status);
  }

  userData(): Observable<any> {
    return this.user;
  }

  updateUserData(userData: any): void {
    this.user.next(userData);
  }

  getOrderInfo(client: string, order: string): Observable<any> {
    return this.httpService.get('drLavaTudo/ecommerceAPI/getOrderData?c=' + client + '&o=' + order);
  }

  getServiceOrderInfo(serviceOrder: string, order: string): Observable<any> {
    return this.httpService.get('drLavaTudo/ecommerceAPI/getSummaryOS?os=' + serviceOrder + '&o=' + order);
  }

  sendRating(rating: any) {
    return this.httpService.post('drLavaTudo/ecommerceAPI/fillRating', rating, false);
  }

  sendContactEmail(contact: any): Observable<any>  {
    return this.httpService.post('drLavaTudo/ecommerceAPI/sendContactEmail', contact, false);
  }

  sendEmailToCompanies(emailData: any): Observable<any>  {
    return this.httpService.post('drLavaTudo/ecommerceAPI/sendEmailToCompanies', emailData, false);
  }

  partnerPreRegistration(form: any): Observable<any>  {
    return this.httpService.post('drLavaTudo/ecommerceAPI/partnerPreRegistration', form, false);
  }
  
  registrateAffiliated(data: any): Observable<any>  {
    const params = this.utilService.getCampaignParameters();
    data.campaignParams = params;

    return this.httpService.post('drLavaTudo/ecommerceAPI/registrateAffiliated', data, false);
  }
  
  getOrCreateSubscriptionLead(data: any): Observable<any>  {
    const params = this.utilService.getCampaignParameters();
    data.campaignParams = params;

    return this.httpService.post('drLavaTudo/ecommerceAPI/getOrCreateSubscriptionLead', data, false);
  }

  createOrUpdateConvertedLead(customerId: string): Observable<any>  {
    const params = this.utilService.getCampaignParameters();
    const data = {
      customerId,
      campaignParams: params
    };

    return this.httpService.post('drLavaTudo/loggedAreaAPI/createOrUpdateConvertedLead', data, true);
  }
}
