<main>
  <app-title [title]="title"></app-title>
  <section class="section-container position-relative">

    <div class="row justify-content-between">
      <div class="body-style career">
        <div class="container">
          <div class="row pt-5 justify-content-between">
            <div class="col-lg-4 mb-4 mb-lg-0 text-center">
              <h1 class="title-jobs mt-4 mb-3 mb-lg-4">Faça parte do nosso time</h1>
              <div class="jobs-lead">
                <p>
                  A Dr. Lava Tudo está em expansão e à procura de pessoas
                  talentosas! Vem com a gente?
                </p>
                <p>Confira nossas vagas abertas.</p>
              </div>
            </div>

            <div class="col-lg-7 jobs-list">
              <!-- Loading-->
              <app-loading class="loading text-center" *ngIf="loading"></app-loading>

              <div *ngIf="!loading">
                <!-- Error result-->
                <div *ngIf="error">
                  <div class="no-job-available mb-5">
                    <h2 class="sub-title-jobs text-center">
                      Ocorreu um erro e não conseguimos carregar as vagas no
                      momento. Tente novamente mais tarde.
                    </h2>
                  </div>
                </div>

                <!-- Empty result-->
                <div class="col-lg-10" *ngIf="selectiveProcess.length === 0 && !error">
                  <div class="no-job-available mb-5">
                    <h2 class="sub-title-jobs">
                      Nenhum processo seletivo aberto no momento.
                    </h2>
                  </div>
                </div>

                <!-- Selective Process List-->
                <div *ngIf="selectiveProcess.length > 0">
                  <div class="row p-0 w-100 pb-5 mx-0" *ngFor="let item of keysSelectiveProcesses">
                    <div class="col-12 p-0">
                      <h4 class="badge badge-primary">{{ item }}</h4>
                      <div class="card-item" *ngFor="let job of groupSelectiveProcess.get(item)">
                        <div *ngIf="
                        job &&
                        job.a &&
                        job.a.name &&
                        job.a.quantity &&
                        job.a.nameForUrl
                      ">
                          <a class="card mb-2" routerLink="/trabalhe-conosco/vaga/{{
                          job.a.nameForUrl
                        }}">
                            <div class="card-body p-4">
                              <span class="
                              d-flex
                              justify-content-sm-between
                              align-items-sm-center
                            ">
                                <span class="mb-1 mb-sm-0">{{
                                  job.a.name
                                  }}</span>
                                <span class="text-sm-right font-weight-bold">{{ job.a.quantity }}
                                  Vagas</span>
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #empty>
                <div class="nenhuma-vaga mb-3">
                  <h2 class="sub-title-jobs">Nenhum processo seletivo aberto no momento</h2>
                </div>
              </ng-template>

              <ng-template *ngIf="!loading && error">
                <div class="nenhuma-vaga mb-3">
                  <h2 class="sub-title-jobs">Ocorreu um erro e não conseguimos carregar as vagas no momento. Tente
                    novamente
                    mais tarde.</h2>
                </div>
              </ng-template>

              <div class="card card-talent spacing">
                <div class="row">
                  <div class="col-xl-8">
                    <div class="card-body">
                      <h2 class="sub-title-jobs">Não achou a vaga que queria?</h2>
                      <p class="card-text">
                        Você ainda pode cadastrar o seu currículo em nosso banco de
                        talentos.<br>Entraremos
                        em contato com você assim que houver alguma vaga compatível!</p>
                      <a *ngIf="!userLogged"  class="btn btn-sm px-3 py-2 mt-3 text-white"(click)="openLoginModal()">Cadastre-se</a>
                      <a *ngIf="userLogged"  class="btn btn-sm px-3 py-2 mt-3 text-white"(click)="openModalJob(modalJob)">Cadastre-se</a>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div *ngIf="loading">
            <dlf-loading></dlf-loading>
          </div>
        </div>

      </div>
    </div>
    <span class="section-jobs-bg"></span>


  </section>
</main>

<app-career-job-form #modalForm> </app-career-job-form>