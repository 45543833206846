import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { masks } from 'src/app/shared/constants/masks';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { UtilService } from 'src/app/shared/services/util.service';

export function validateName(control: AbstractControl): any {
  if (!control.value.trim().match(/^[a-zA-Z\u00C0-\u017F´]+(\s[a-zA-Z\u00C0-\u017F´]+)+$/)) {
    return { invalidName: true };
  }
  return null;
}

export function validatePhoneNumber(control: AbstractControl): any {
  if (!control.value.replace(/[^\d]/g, "").match(/^[1-9]{2}[9].{8}$/)) {
    return { invalidPhone: true };
  }
  return null;  
}

export function validateEmail(control: AbstractControl): any {
  let regex = /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  if (!regex.test(control.value)) {
    return { invalidEmail: true };
  }
  return null;  
}

export function validateCities(cityOptions: any, subTextCity?: string): any {
  return (control: AbstractControl) => {
    if(!cityOptions || !cityOptions.some(city => city._id === control.value)) {
      if(subTextCity && subTextCity.length<3) {
        return { incompleteSearch: true, invalidCity: false }
      }
      return { invalidCity: true, incompleteSearch: false };
    }
    return null;
  }
}

@Component({
  selector: 'app-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('0.2s ease-out', 
                    style({ height: "*", opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ height: "*", opacity: 1 }),
            animate('0.2s ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class PartnerFormComponent implements OnInit {
  cities: any;
  filterCities: any;
  textSearchedUpdate = new Subject<string>();
  formContact: FormGroup;
  name: string;
  processing: boolean;
  getCitiesRequest: Subscription;
  loadingCityOptions: boolean;
  formSend: boolean;
  title: string;
  subtitle: string;
  showImage: boolean;

  @Output() sendForm = new EventEmitter<boolean>();
  constructor(private utilService: UtilService, private formBuilder: FormBuilder, private userService: UsersService, private snackbarService: SnackBarService, private trackingService: TrackingService) {
    this.textSearchedUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filterCitiesByValue(value);
      }
    );
  }
  createForm = (): void => {
    this.formContact = this.formBuilder.group({
      name: ['', [Validators.required, validateName]],
      city: ['', [Validators.required]],
      phone: ['', [Validators.required, validatePhoneNumber]],
      email: ['', [Validators.required, validateEmail]]
    });
  }

  ngOnInit(): void {
    this.createForm();
  }
  get f(): any { return this.formContact.controls; }

  filterCitiesByValue(value): void {
    this.formContact.controls['city'].setValidators(Validators.compose([Validators.required, validateCities(this.filterCities, value)]));
    this.formContact.controls['city'].updateValueAndValidity()

    if(value && value.length < 3) {
      return;
    }

    if(this.filterCities && this.filterCities.some(city => city._id === value)) return;

    if(this.getCitiesRequest) {
      this.getCitiesRequest.unsubscribe();
    }

    if(!value) {
      this.cities = [];
      this.filterCities = [];

      return;
    }

    this.loadingCityOptions = true;
    this.getCitiesRequest = this.utilService.getCity(value).subscribe(resp => {
      this.cities = resp;
      this.filterCities = this.sortCities(this.cities.filter(city => this.normalizeValue(city.name).includes(this.normalizeValue(value))));
      this.formContact.controls['city'].setValidators(Validators.compose([Validators.required, validateCities(this.filterCities, value)]));
      this.formContact.controls['city'].updateValueAndValidity()
      this.loadingCityOptions = false;
    });
  }

  normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  sortCities(cities){
    if(cities && cities.length) {
      cities.sort((a,b) => {
            return a.name > b.name;
        });
    }

    return cities
}

  send(): void {
    this.processing = true;

    let values = this.formContact.value;
    values.name = values.name.trim();
    values.phone = values.phone.replace(/[^\d]/g, "");

    let parameters = this.utilService.getCampaignParameters();
    Object.assign(values, parameters);
    
    this.userService.partnerPreRegistration(values).subscribe((res) => {
      this.formSend = true;
      this.title = res.title;
      this.subtitle = res.subtitle;
      this.showImage = res.showImage;
      this.trackingService.partnerLead(res.customer)
      this.sendForm.emit(true);
      this.processing = false;
    }, err => {
      this.processing = false;
    });
  }
  getPhoneNumberTextMask = (): any => {
    return { mask: masks.mobilePhoneNumber };
  }

  formatNameWithState(city): string {
    return city.name + " - " + city.state.initials;
  }
  displayFn(value?: number) {
    return value ? this.formatNameWithState(this.filterCities.find(_ => _._id === value)) : undefined;
  }

  public getFbclid = (): any => {
    let fbc = this.getCookie('_fbc');
    if(fbc) {
      return {
        value: fbc.split('.')[3],
        inclusionDate: parseInt(fbc.split('.')[2])
      };
    }
  }

  private getCookie = (name): any => {
    let cookie = {};

    document.cookie.split(';').forEach(el => {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });

    return cookie[name];

  }
}
