import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  @ViewChild('privacy_content') content: TemplateRef<any>;
  @Input() tdu: string;
  @Input() openTDU: boolean;
  @Input() title: string;
  @Input() isModal = false;
  @Output() closeTDU = new EventEmitter<any>();

  constructor(private util: UtilService, private router: Router, private modalService: NgbModal) {}

  ngOnInit(): void {
    if(this.tdu || this.isModal) return;

    this.title = "Termos de uso"
    this.util.createCanonicalURL();
    const origin = this.router.url === "/tdu-chat-web" ? "chat-gpt" : null;
    this.util.getTDU(origin).subscribe((result) => {
      this.tdu = result.tdu;
    });
  }

  public open(content: any): void {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-title',
      scrollable: true,
      size: 'lg',
      windowClass: "privacy-policy-modal"
    }).result.then(result => {
      if (this.openTDU)
        this.closeTDU.emit();
    }, reason => {
      if (this.openTDU)
        this.closeTDU.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isModal && changes && changes.openTDU && this.openTDU) {
      this.openTDU = changes.openTDU.currentValue;

      if (this.openTDU) {
        this.open(this.content);
      }
    }
  }
}
