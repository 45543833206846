import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'newSignature',
  templateUrl: './newSignature.component.html',
  styleUrls: ['./newSignature.component.scss']
})
export class NewSignature implements OnInit {
  currentEnvironment: string = environment.base_api;
  header: any;
  howWorks: any[];
  cards: any[];
  benefits: any;
  commonQuestions: any[];
  diffs: any[];
  testimony: any;
  refId: string;

  constructor(private util: UtilService) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.refId = urlParams.get("refId")
    if(this.refId && this.refId.length === 24) {
      localStorage.setItem('refId', this.refId);
    }
    this.util.getContentLandingPage().subscribe(value => {
      this.header = value.header;
      this.howWorks = value.howWorks;
      this.cards = value.listServices;
      this.benefits = value.benefits;
      this.testimony = value.testimony;
      this.diffs = value.diffNewSignature;

      this.commonQuestions = value.commonQuestions.map(item => {
        return {
          name: item.title,
          identifier: this.setIdentifierQuestions(item.title),
          content: item.content
        };
      });

      let headerBackground = document.getElementById("title-container");
      if (headerBackground) {
        headerBackground.style.background = `url('${this.currentEnvironment}/api/1/portal/drLavaTudo/ecommerceAPI/downloadImageNewSignature?imageId=${value.header.backgroundImage._id}'), #20B073`;
      }
    });
  }

  setCustomStyle(cta) {
    return `--sy-button-color: ${cta.fontColor};
      --sy-button-background-color: ${cta.backgroundColor};
      --sy-button-background-color-active: ${cta.backgroundColor};
      --sy-button-background-color-hover: ${cta.backgroundColor};
      --sy-button-background-color-focus: ${cta.backgroundColor};
      --sy-button-border-color: ${cta.borderColor};
      --sy-button-border-color-hover: ${cta.borderColor};
      --sy-button-border-color-focus: ${cta.borderColor};
      --sy-button-border-color-active: ${cta.borderColor};`;
  }

  setBenefitsStyle(imageId) {
    if (window.screen.width >= 1200) {
      return `background-image: linear-gradient(to left, #d9d9d900 50%, #fff 75%), url("` + this.currentEnvironment + `/api/1/portal/drLavaTudo/ecommerceAPI/downloadImageNewSignature?imageId=` + imageId + `");`;
    }

    else if (window.screen.width < 1200 && window.screen.width >= 800) {
      return `background-image: linear-gradient(to left, #d9d9d900 10%, #fff 80%), url("` + this.currentEnvironment + `/api/1/portal/drLavaTudo/ecommerceAPI/downloadImageNewSignature?imageId=` + imageId + `");`;
    }

    else if (window.screen.width < 800) {
      return `background-image: linear-gradient(to left, #d9d9d900 -100%, #fff 75%), url("` + this.currentEnvironment + `/api/1/portal/drLavaTudo/ecommerceAPI/downloadImageNewSignature?imageId=` + imageId + `");`;
    }
  }

  setIdentifierQuestions(string: string) {
    let name = string.toLowerCase();

    name = name.replace(/[áàãâä]/g, 'a');
    name = name.replace(/[éèêë]/g, 'e');
    name = name.replace(/[íìîï]/g, 'i');
    name = name.replace(/[óòõôö]/g, 'o');
    name = name.replace(/[úùûü]/g, 'u');
    name = name.replace(/[ç]/g, 'c');
    name = name.replace(/[^a-z0-9]/g, '-');
    name = name.replace(/-+/, '-');

    if(name[0] === "-") name = name.substring(1, name.length - 1);
    if(name[name.length - 1] === "-") name = name.substring(0, name.length - 1);

    return name;
  }
}
