<div class="pre-registration-body">
    <div class="header">
        <app-pre-registration-header></app-pre-registration-header>
    </div>
    <div id="formContainer" *ngIf="loading === false" class="container-lg col-xxl-3 col-xl-4 col-lg-5 col-md-6 form" >
        <div *ngIf="found === true">
            <app-pre-registration-form *ngIf="partner && serviceInfo" [partner]="partner" [leadId]="this.leadId" [serviceInfo]="serviceInfo" (done)="setDone($event)"></app-pre-registration-form>
            <app-pre-registration-success *ngIf="!partner && !serviceInfo" [title]="title" [subtitle]="subtitle" [showImage]="showImage"></app-pre-registration-success>
        </div>
        <app-pre-registration-error *ngIf="found === false"></app-pre-registration-error>
    </div>
</div>
