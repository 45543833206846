// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://dev.drlavatudo.com/',
  fb_url: 'https://drlavatudo-dev.sydle.one/api/1/ecommerce/sys/auth/sso/signin/drlavatudo-dev/defaultAuthenticatorFacebook?one_redirect=',
  google_url: 'https://drlavatudo-dev.sydle.one/api/1/ecommerce/sys/auth/sso/signin/drlavatudo-dev/defaultAuthenticatorGoogle?one_redirect=',
  api_url: 'https://drlavatudo-dev.sydle.one/api/1/portal/',
  api_url_ecommerce: 'https://drlavatudo-dev.sydle.one/api/1/ecommerce/',
  api_authorization_token: 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiZGlyIn0..rUxJe7mnpQGCk2UbDLpeJg.YwNF5J97iUh_i5uHrCxt7j9ZWdQ1RWkcLB7NUco9khQfuLu-VlBUHOUbhjRz9xX30SW1hQRpGpeMmFPyjqDH14B5W2vG-rBJbJ59sVZr986oB42d73Q_zNxQx41sig00eJE8Q0MZEkiQBWmOsN6w6GCZ0ubM6Ykj6HCPzwco7OeL49B_jJ8Zcru30K3R_lxvba0P_yRh8HkP9wOPaCFvzj3lzl7_M5hTZCPi2pslYely8EZRUo_SaaZ4fqB5caxgABhaGJ3MK1BsthnWaYVycnleRJPOXIw5uZXTygOSbEqqFhtCU7ZNZzDuNnqtrCfaiOSU_ocrZxVf2IjT9yqQ6rPZaRsQeQJjx7aKNIBQp879lHNFfAgNcRj7INVY6c0HyI7wlQMGBpdHgdIzzS-YZQE1mSupQzkn9zdBpQKO_Y3RUuszImv0jJZ0OXl-eOsc0RGub2qQTvZBkM4WcHEGxhcKDDKCkR1RKJidCpJeUZzliyVM306_IxzJe2pZlHD8oyx_i0bqC1L1W8zMRD-NUOaCM7-ncTcE93_R8c0MjkUOW9157r2-Ywyzy57Q1zYZ4VoSivmFtB1eRjzB4HkibIvC2mSwn7PvTjx8vcOM1lo.yB2Td7QkcJUWlnyeP4qreg',
  'X-Explorer-Account-Token': 'drlavatudo-dev',
  ecommerce_url: 'https://dev.drlavatudo.com/loja/',
  customer_portal_url: 'https://dev.drlavatudo.com/cliente',
  affiliated_portal_url: 'https://dev.drlavatudo.com/portal-afiliado',
  base_api:  'https://drlavatudo-dev.sydle.one',
  checkout_subdomain: 'checkout-drlavatudo-dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
