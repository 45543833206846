<app-loading *ngIf="loading"></app-loading>
<div class="affiliated-login">

    <div class="login-container" *ngIf="!isLoged && !loading">
        <div class="login-header">
            <sy-text type="h4">
                Seja nosso afiliado!
            </sy-text>
            <sy-text type="small">
                Clique no botão abaixo e realize o seu login. Depois basta seguir as intruções que irão aparecer na sua
                tela.
            </sy-text>
        </div>

        <div class="button-container">
            <sy-button (click)="openLoginModal()" label="EFETUAR LOGIN " content="text-only" fab="true">
            </sy-button>
        </div>
    </div>

    <div class="loged-container" *ngIf="isLoged && !loading">
        <div class="loged-container" *ngIf="true">
            <div class="login-container" *ngIf="registrationStatus === 'finished'">
                <div class="login-header">
                    <sy-text type="h4">
                        Portal do afiliado
                    </sy-text>
                    <sy-text type="small">
                        Acesse o portal do afiliado e confira seus benefícios
                    </sy-text>
                </div>

                <div class="button-container">
                    <sy-button 
                        href={{portalUrl}} 
                        target="_top" 
                        label="Acessar portal do afiliado" 
                        content="text-only" 
                        fab="true"
                    ></sy-button>
                </div>
            </div>
        </div>

        <div class="form-container" *ngIf="registrationStatus === 'initial' && customer">
            <div class="form-header">
                <sy-text type="h4">Seja nosso afiliado!</sy-text>
                <sy-text type="small">Preencha o formulário abaixo e confirme seu cadastro</sy-text>
            </div>
            <form>
                <div class="form-group">
                    <sy-input *ngIf="customer.email !== customer.login" id="email" label="E-mail *" type="text"
                        value={{customer.email}} (syInputDidInput)="verifyInput('email')"></sy-input>
                    <sy-text *ngIf="customer.email === customer.login">E-mail: {{customer.email}}</sy-text>
                    <sy-input id="name" label="Nome *" type="text" value={{customer.name}}
                        (syInputDidInput)="verifyInput('name')"></sy-input>
                    <sy-input class="mb-3" id="affiliatedName" label="Nome de afiliado utilizado" type="text"
                        placeholder="Apelido usado em suas redes sociais"></sy-input>
                    <sy-input *ngIf="!phoneWithDDI || phoneWithDDI !== customer.login" id="phone" label="Telefone *"
                        mask="(99) 99999-9999" [inputmaskOptions]="{ placeholder: '', jitMasking: true }"
                        value={{customer.phone}} (syInputDidInput)="verifyInput('phone')"></sy-input>
                    <sy-text *ngIf="phoneWithDDI && phoneWithDDI === customer.login">Telefone:
                        {{formatPhone}}</sy-text>
                    <div class="network-container" id="network-container">
                        <sy-text type="small">
                            Redes Sociais
                        </sy-text>
                        <sy-button label="+" content="text-only" fab="true" small type="accent"
                            (click)="addSocialNetwork()">
                        </sy-button>

                        <div class="more-network-links" id="more-network-links">
                            <div class="network-node">
                                <sy-select label="Rede social" size="50">
                                    <option *ngFor="let item of socialNetWorks" value={{item._id}}> {{item.name}}
                                    </option>
                                </sy-select>
                                <sy-input label="Link do seu perfil" type="text" class="network-link"></sy-input>
                            </div>
                        </div>
                    </div>

                    <sy-checkbox id="accept-terms" (click)="verifyInput('accept-terms')"></sy-checkbox>
                    <div class="terms-content">
                        <sy-text type="small">Li e aceito os </sy-text>
                        <sy-text type="small" (click)="openTerms()" class="terms-link">termos e condições do programa de
                            afiliado</sy-text>

                        <div class="checkbox-error" id="checkbox-error">
                            <sy-text type="error">
                                É necessário aceitar os termos e condições do programa de afiliados para enviar seus
                                dados.
                            </sy-text>
                        </div>
                    </div>

                    <div class="button-container">
                        <sy-button (click)="sendForm()" label="ENVIAR" content="text-only" fab="true">
                        </sy-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-terms-of-use
    *ngIf="!!tdu"
    id="term"
    [isModal]="true"
    [title]="'Termos e condições do programa de afiliado'" 
    [tdu] ="tdu" 
    [openTDU]="openTDU" 
    (closeTDU)="closeTDU()"
></app-terms-of-use>
