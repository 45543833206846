import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pre-registration-success',
  templateUrl: './pre-registration-success.component.html',
  styleUrls: ['./pre-registration-success.component.scss']
})
export class PreRegistrationSuccessComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showImage: string;

  constructor() { }
}
