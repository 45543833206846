import { Component, OnInit } from "@angular/core";
import { UtilService } from "../../../../shared/services/util.service";

@Component({
  selector: "app-prime",
  templateUrl: "./prime.component.html",
  styleUrls: ["./prime.component.scss"],
})
export class PrimeComponent implements OnInit {
  constructor(private util: UtilService) {}

  ngOnInit(): void {}
}
