import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-verified-professionals",
  templateUrl: "./verified-professionals.component.html",
  styleUrls: ["./verified-professionals.component.scss"],
})
export class VerifiedProfessionalsComponent implements OnInit {
  @Input() content: any;

  environment: any = environment;

  constructor() {}

  ngOnInit(): void {
    if (!this.content || !Object.keys(this.content).length) {
      this.content = {
        siteCta: [
          {
            backgroundColor: "#F4822C",
            borderColor: "#F4822C",
            buttonTitle: "VER VALORES",
            buttonLink: "drlavatudo.com/loja",
            fontColor: "#FFFFFFF",
          },
        ],
        subtitle:"Nossos especialistas são treinados e equipados para garantir um atendimento diferenciado e qualidade na entrega dos serviços.",
        title: "Profissionais verificados",
        content: ["Equipamentos e produtos profissionais", "Atendimento imediato", "Sem taxa de deslocamento"],
      };
    }

    this.content.content = this.content.content.filter(list => list !== null);
  }

  setColors(cta) {
    return {
      "background-color": cta.backgroundColor,
      "border-color": cta.borderColor,
      "color": cta.fontColor,
    }
  }
}
