<div *ngIf="forCompaniesPage" [style]="forCompaniesStyle | safeStyle">
    <dlt-header [identifier]="'forCompaniesContent'">
    </dlt-header>

    <div class="session-header w-100">
        <div class="header" *ngIf="forCompaniesPage && forCompaniesPage.header">
            <div class="titles">
                <p><a href="/">Início</a> / {{forCompaniesPage.pageTitle}}</p>
                <h1>{{forCompaniesPage.header.headerTitle}}</h1>
                <h2>{{forCompaniesPage.header.headerSubtitle}}</h2>
                <h3>{{forCompaniesPage.header.headerDescription}}</h3>
            </div>
            <div class="redirect-button" *ngIf="forCompaniesPage.header.redirectButton">
                <sy-button style={{setColors(forCompaniesPage.header.redirectButton)}}
                    label={{forCompaniesPage.header.redirectButton.buttonTitle}}
                    href={{forCompaniesPage.header.redirectButton.buttonLink}} content="text-only"
                    fab="true"></sy-button>
            </div>

            <iframe width="724" height="403" [src]="videoLink" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
            encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="w-100 white-box"></div>
    </div>

    <div class="session-differential w-100"
        *ngIf="forCompaniesPage && forCompaniesPage.sessionDifferential && forCompaniesPage.sessionDifferential.length">
        <div class="differential-board">
            <div class="d-flex" *ngFor="let i of forCompaniesPage.sessionDifferential">
                <dlt-card>
                    <dlt-card-header slot="header" headline={{i.title}}
                        image="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/downloadForCompanies?imageId={{i.icon._id}}">
                    </dlt-card-header>

                    <dlt-card-content slot="content">
                        {{i.content}}
                    </dlt-card-content>
                </dlt-card>
            </div>
        </div>
    </div>

    <div class="session-satisfaction w-100" *ngIf="satisfactionBoard && satisfactionBoard.active">
        <div class="satisfaction-board">
            <div class="title">
                <p>Satisfação garantida</p>
            </div>
            <div class="testimony">
                <app-testimony-component [customConfig]="config2"
                    [origin]="'PageForCompanies'"></app-testimony-component>
            </div>
        </div>
    </div>

    <app-companies-board *ngIf="companiesBoard && companiesBoard.active && companiesData"
        [companiesData]="companiesData" [backgroundColor]="forCompaniesPage.backgroundColorContentSection"
        [fontColor]="forCompaniesPage.fontColorContentSection"></app-companies-board>

    <div class="session-form w-100" id="form-page" *ngIf="budgetForm && budgetForm.active">
        <div class="gray-box w-100"></div>
        <div class="form-board">
            <div id="form-sucess">
                <div class="description">
                    <h2>Queremos conhecer um pouco mais sobre sua empresa!</h2>
                    <p>Preencha o formulário abaixo e nossos consultores entrarão em contato.</p>
                </div>
                <div class="form-container">
                    <form (ngSubmit)="onSave()">
                        <mat-form-field class="input-container w-100" appearance="outline" floatLabel="always">
                            <mat-label> Nome completo* </mat-label>
                            <input type="name" matInput [formControl]="nameFormControl" id="InputName" name="InputName">
                            <mat-error *ngIf="nameFormControl.invalid">Este campo é obrigatório.</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-container w-100" appearance="outline" floatLabel="always">
                            <mat-label> Email* </mat-label>
                            <input type="email" matInput [formControl]="emailFormControl" id="InputEmail"
                                name="InputEmail">
                            <mat-error *ngIf="emailFormControl.invalid">{{getEmailErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-container w-100" appearance="outline" floatLabel="always">
                            <mat-label> Cidade* </mat-label>
                            <input type="text" matInput [formControl]="cityFormControl" id="InputCity" name="InputCity">
                            <mat-error *ngIf="cityFormControl.invalid">Este campo é obrigatório.</mat-error>
                        </mat-form-field>
                        <mat-form-field id="company-session" class="input-container w-100" appearance="outline"
                            floatLabel="always">
                            <mat-label> Empresa* </mat-label>
                            <input type="text" matInput [formControl]="companyFormControl" id="InputCompany"
                                name="InputCompany">
                            <mat-error *ngIf="companyFormControl.invalid">Este campo é obrigatório.</mat-error>
                        </mat-form-field>

                        <div class="custom-radio">
                            <label> Aproximadamente quantos funcionários a empresa tem?* </label>
                            <mat-radio-group [formControl]="companySizeFormControl" required
                                (change)="verifyConfirmation('error-size')">
                                <mat-radio-button value="01-50">01-50</mat-radio-button>
                                <mat-radio-button value="51-100">51-100</mat-radio-button>
                                <mat-radio-button value="101-200">101-200</mat-radio-button>
                                <mat-radio-button value="201+">201+</mat-radio-button>
                                <p id="error-size">Este campo é obrigatório.</p>
                            </mat-radio-group>
                        </div>

                        <div id="details-session">
                            <label> Pretende limpar os seus estofados ou carpetes nos próximos meses?* </label>
                            <mat-radio-group [formControl]="interestFormControl"
                                (change)="verifyConfirmation('error-interest')" required>
                                <mat-radio-button value="Sim, estou precisando!">Sim, estou
                                    precisando!</mat-radio-button>
                                <mat-radio-button value="Sim, mas daqui algum tempo!">Sim, mas daqui algum
                                    tempo!</mat-radio-button>
                                <mat-radio-button value="Agora não!">Agora não!</mat-radio-button>
                                <p id="error-interest">Este campo é obrigatório.</p>
                            </mat-radio-group>
                        </div>

                        <mat-form-field id="confirmation-session" class="input-container w-100" appearance="outline"
                            floatLabel="always">
                            <mat-label> Detalhes do pedido* </mat-label>
                            <textarea type="text" matInput [formControl]="detailsFormControl" id="InputDetails"
                                name="InputDetails"></textarea>
                            <mat-error *ngIf="detailsFormControl.invalid">Este campo é obrigatório.</mat-error>
                        </mat-form-field>

                        <mat-checkbox [formControl]="confirmationFormControl"
                            (change)="verifyConfirmation('error-confirmation')">
                            Confirmo que aceito receber os e-mails da Dr. Lava Tudo no endereço preenchido acima*
                            <p id="error-confirmation">Este campo é obrigatório.</p>
                        </mat-checkbox>

                        <button type="submit" class="login-btn email-btn"> ENVIAR </button>
                        <app-loading class="loading" *ngIf="processing"></app-loading>
                    </form>
                </div>
            </div>
            <p id="success">Formulário enviado com sucesso!</p>

        </div>
    </div>
    <dlt-footer [logo]="footerLogo" [logoSydle]="'/assets/logo-sydle-white.svg'" [sealsImages]="footerSealsString"></dlt-footer>
</div>