<div *ngIf="content" class="d-flex flex-column flex-lg-row align-items-center w-100 gap-4 gap-lg-4 mb-5">
    <div class="flex-item">
        <div class="image-area">
            <img *ngIf="content.image" alt="Imagem do parceiro"
                src="{{environment.base_api}}/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId={{content.image._id}}">
            <app-technician-image *ngIf="!content.image"></app-technician-image>
        </div>
    </div>
    <div class="d-flex flex-column cerified-professionals flex-item w-100 justify-content-center">
        <h3 class="mb-0">{{content.title}}</h3>
        <h4 class="item-description">{{content.subtitle}}</h4>
        <ul class="m-0 p-0">
            <li class="d-flex mb-3" *ngFor="let list of content.content" >{{list}}</li>
        </ul>

        <div class="d-flex gap-2">
            <a *ngFor="let cta of content.siteCta" href={{cta.buttonLink}}> <button class="btn btn-secondary mt-3" [ngStyle]="setColors(cta)"> {{cta.buttonTitle}} </button> </a>
        </div>
    </div>
</div>