<main>    
    <app-title [title]="'Perguntas frequentes'"></app-title>

    <div class="body-style">
        <div *ngIf="loadingQuestions" class="spinner">
            <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
        </div>

        <div *ngIf="!loadingQuestions && questionsList && questionsList.length">
            <div class="row w-100 pt-5 mx-0" *ngFor="let question of questionsList">        
                <div class="col-lg-7 col-12 spacing">
                    <h4 class="title">{{question.category.name}}</h4>
                    <app-accordion [itemsList]="question.questions"></app-accordion>
                </div>
            </div>
        </div>
    </div>
</main>