<footer class="footer">
    <div class="top-footer">
        <div class="content-logo">
            <a href="/">
                <img src="{{logo}}" alt="Logo Dr. Lava Tudo">
            </a>
            <p>A Dr. Lava Tudo é referência em limpeza, lavagem e higienização de sofás, cadeiras, colchões e estofados em geral em todo Brasil.</p>
            <div class="link-networks">
                <a href="https://www.instagram.com/drlavatudo_oficial" target="_blank">
                    <img src="assets/icons/instagram.svg" alt="Ícone instagram">
                </a>
                <a href="https://www.facebook.com/drlavatudo" target="_blank">
                    <img src="assets/icons/facebook.svg" alt="Ícone facebook">
                </a>
            </div>
        </div>
        <div class="content-budget">
            <h2>Solicite um orçamento</h2>
            <p>Por ligação: 0800 944 9999</p>
            <p>Por whatsapp: +55 800 892 5001</p>
            <p>E-mail: contato@drlavatudo.com</p>
            <a [href]="whatsAppLink" target="_blank">
                <img src="assets/icons/whatsapp.svg" alt="Ícone whatsapp">
                <span>WHATSAPP</span>
            </a>
        </div>
        <div class="content-links">
            <h2>Links populares</h2>
            <a href="trabalhe-conosco">Carreiras</a>
            <a href="afiliados">Afiliados</a>
            <a href="ebooks">E-books</a>
            <a href="blog">Blog</a>
        </div>
    </div>
    <div class="bottom-footer">
        <div *ngIf="footerSeals && footerSeals.length" class="seals">
            <a *ngFor="let seal of footerSeals" href="{{seal.link}}" [style]="seal.link ? 'pointer-events: all;' : 'pointer-events: none;'" target="_blank">
                <img src="{{currentEnvironment}}/api/1/portal/drLavaTudo/ecommerceAPI/downloadSiteFooterSeals?imageId={{seal.image._id}}" alt="{{seal.altText}}">
            </a>
        </div>
        <div class="content-text">
            <div>
                <a href="politica-de-privacidade">Política de privacidade</a>
                <a href="termos-de-uso">Termos de uso</a>
            </div>
            <a href="https://www.sydle.com/br/" target="_blank">Powered by SYDLE</a>
        </div>
    </div>
</footer>
