import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

/**
 * data = {
 *    title: Título da página,
 *    description: Descrição da página,
 *    image: Imagem (Thumbnail Twitter/Facebook),
 *    url: URL que a página foi acessada,
 *    noindex: Se a página deve ser indexada ou não,
 * }
 */
@Injectable()
export class SEOService {
  private data: any = {};
  private titleSufix = ' | Dr. Lava Tudo - Limpeza de Sofás e Estofados 0800 944 9999';

  constructor(
    @Inject(DOCUMENT) private dom,
    private title: Title,
    private meta: Meta,
    private router: Router
    ) { }

  /**
   * SEO de Página de Home
   */
  public setHomeSEO(): void {
    this.setSEOData({
      title: 'Dr. Lava Tudo - Limpeza de Sofás e Estofados 0800 944 9999',
      description: 'Somos especializados em limpeza de sofás e estofados em geral. Vamos até sua casa! Confira!',
      noIndex: false,
      url: environment.base_url + this.router.url
    });
  }

  /**
   * SEO do Login para o processo de Checkout
   */
  public setLoginCheckoutSEO(url: string): void {
    this.setSEOData({
      title: 'Login' + this.titleSufix,
      noIndex: true,
      url: environment.base_url + url
    });
  }

  /**
   * SEO de Página 404
   */
  public set404SEO(): void {
    this.setSEOData({
      title: 'Página não encontrada' + this.titleSufix,
      noIndex: true,
      url: environment.base_url + this.router.url
    });
  }

  /**
   * SEO de Página TDU
   */
  public setTDUSEO(): void {
    this.setSEOData({
      title: 'Termos de Uso' + this.titleSufix,
      noIndex: true,
      url: environment.base_url + this.router.url
    });
  }

  /**
   *
   * SEO da Página de Descrição de vaga
   *
   */
   public setJobDescriptionSEO(name) {
    this.setSEOData({
      title: "Vaga - " + name + this.titleSufix,
      noIndex: false,
      url: environment.base_url + this.router.url,
    });
  }

  /**####################
   * Funções auxiliares
   ######################*/

  private setSEOData(data: any = {}): void {
    this.data = data;
    this.setTitle();
    this.setStandardTags();
    this.setTwitterTags();
    this.setOpenGraphTags();
    this.createCanonicalURL();
  }

  private createCanonicalURL(): void {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }

  private setTitle(): void {
    if (this.data.title) {
      this.title.setTitle(this.data.title);
    }
  }

  private setStandardTags(): void {

    this.createOrUpdateTag('description', this.data.description);

    if (this.data.noIndex) {
      this.createOrUpdateTag('robots', 'noindex');
    }
  }

  private setTwitterTags(): void {
    this.createOrUpdateTag('twitter:title', this.data.title);
    this.createOrUpdateTag('twitter:description', this.data.description);
    this.createOrUpdateTag('twitter:card', 'summary_large_image');
    this.createOrUpdateTag('twitter:image', this.data.image);
  }

  private setOpenGraphTags(): void {
    this.createOrUpdateTag('og:title', this.data.title, 'property');
    this.createOrUpdateTag('og:description', this.data.description, 'property');
    this.createOrUpdateTag('og:url', this.data.url, 'property');
    this.createOrUpdateTag('og:type', 'website', 'property');
    this.createOrUpdateTag('og:site_name', 'DogLife', 'property');
    this.createOrUpdateTag('og:locale', 'pt_BR', 'property');
  }

  private createOrUpdateTag(property: string, content: string, identifier: string = 'name'): void {
    const tagSelector = identifier + ' = "' + property + '"';
    const tag = this.meta.getTag(tagSelector);

    if (content) {
      let updatedTag = {};
      updatedTag[identifier] = property;
      updatedTag['content'] = content;

      if (tag) {
        this.meta.updateTag(updatedTag);
      } else {
        this.meta.addTag(updatedTag);
      }
    }
  }
}
