<ng-template #content>
  <div class="career-job-form-modal-content">
    <div class="modal-header flex-column">
      <button type="button" class="close" aria-label="Fechar" (click)="onCloseClick('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
      <h1 class="modal-title mt-3" id="modal-basic-title">Enviar currículo</h1>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="onSubmit()">
        <div class="form-group formulario">
          <fieldset>
            <div class="container">
              <div class="form-row">
                <div class="form-group col-lg-12 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label for="nameField">Nome: </mat-label>
                    <input matInput id="nameField" class="form-control" name="nameField"
                      pattern="[a-zA-ZÀ-ÿ.'-]+(?: +[a-zA-ZÀ-ÿ.'-]+)+" type="text" #nameField="ngModel"
                      [(ngModel)]="fullName" required />
                    <mat-error *ngIf="
                      (nameField.hasError('required') &&
                        (nameField.dirty || nameField.touched)) ||
                      (!validateName() && (nameField.dirty || nameField.touched))
                    " class="invalid-feedback d-block">Por favor informe seu nome completo.</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="form-group col-lg-12 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label class="mt-2 mb-0" for="phoneField">Telefone celular:</mat-label>
                    <input matInput id="telefone" class="form-control" #phoneField="ngModel" name="phone"
                      [(ngModel)]="phone" [textMask]="{ mask: masks.mobilePhoneNumber }"
                      (input)="setVerifyPhone(phone.valid)" [appValidPhone]="'mobile'" required />
                    <mat-error *ngIf="
                      (phoneField.hasError('required') &&
                        (phoneField.dirty || phoneField.touched)) ||
                      (!validatePhone() &&
                        (phoneField.dirty || phoneField.touched))
                    " class="invalid-feedback d-block">
                      Por favor informe um telefone celular válido.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="form-group col-lg-6 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label class="mt-2 mb-0" for="zipCodeField">CEP: </mat-label>
                    <input matInput type="text" class="form-control" id="zipCodeField" name="zipCodeField"
                      (change)="findByZipCode()" [(ngModel)]="address.zipcode" autocomplete="00000000"
                      #zipCodeField="ngModel" inputmode="numeric" pattern="\d{5}-?\d{3}" required
                      [textMask]="{ mask: masks.cep }" />
                    <mat-error *ngIf="processing" class="valid-feedback d-block">
                      Pesquisando o CEP, por favor aguarde...
                    </mat-error>
                    <mat-error *ngIf="
                      zipCodeField.hasError('required') &&
                      zipCodeField.invalid &&
                      zipCodeField.touched
                    " class="invalid-feedback d-block">
                      Este campo é obrigatório.
                    </mat-error>
                    <mat-error *ngIf="
                      errorCEP &&
                      !zipCodeField.hasError('pattern') &&
                      !zipCodeField.hasError('required')
                    " class="invalid-feedback d-block">
                      CEP não encontrado, por favor digite novamente.
                    </mat-error>
                    <mat-error *ngIf="
                      zipCodeField.hasError('pattern') &&
                      zipCodeField.invalid &&
                      zipCodeField.touched
                    " class="invalid-feedback d-block">
                      Por favor, digite um CEP válido.
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="form-group col-lg-9 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label class="mt-2 mb-0" for="streetField">Endereço:</mat-label>
                    <input matInput type="text" id="streetField" #streetField="ngModel" name="streetField"
                      [(ngModel)]="address.street" [disabled]="
                      streetFound ||
                      processing ||
                      !address.zipcode ||
                      zipCodeField.invalid ||
                      !address.city
                    " required />
                    <mat-error *ngIf="
                      streetField.hasError('required') &&
                      (streetField.dirty || streetField.touched)
                    " class="invalid-feedback d-block">
                      Este campo é obrigatório.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group col-lg-3 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label class="mt-2 mb-0" for="numberField">Número:</mat-label>
                    <input matInput [disabled]="
                      processing ||
                      !address.zipcode ||
                      zipCodeField.invalid ||
                      !address.city
                    " type="text" pattern="\d*" id="numberField" #numberField="ngModel" name="numberField"
                      [(ngModel)]="address.number" required />
                    <mat-error *ngIf="
                      numberField.hasError('required') &&
                      (numberField.dirty || numberField.touched)
                    " class="invalid-feedback d-block">
                      Este campo é obrigatório.
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="form-group col-lg-12 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label class="mt-2 mb-0" for="complementField">Complemento:
                      <small class="text-muted">(opcional)</small>
                    </mat-label>
                    <input matInput type="text" id="complementField" name="complementField"
                      [(ngModel)]="address.complement" [disabled]="
                        processing ||
                        !address.zipcode ||
                        zipCodeField.invalid ||
                        !address.city
                      " />
                  </mat-form-field>

                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="form-group col-lg-6 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label class="mt-2 mb-0" for="quarterField">Bairro: *</mat-label>
                    <input matInput id="quarterField" name="quarterField" [(ngModel)]="address.quarter" [disabled]="
                      quarterFound ||
                      processing ||
                      !address.zipcode ||
                      zipCodeField.invalid ||
                      !address.city
                    " #quarterField="ngModel" required />
                    <mat-error *ngIf="
                      quarterField.hasError('required') &&
                      (quarterField.dirty || quarterField.touched)
                    " class="invalid-feedback d-block">
                      Este campo é obrigatório.
                    </mat-error>
                  </mat-form-field>

                </div>
                <div class="form-group col-lg-6 mb-2">
                  <mat-form-field floatLabel='always' appearance="fill">
                    <mat-label class="mt-2 mb-0" for="cityField">Cidade: *</mat-label>
                    <input matInput id="cityField" name="cityField" [(ngModel)]="address.city"
                      [disabled]="true" #cityField="ngModel" required />
                    <mat-error *ngIf="
                      cityField.hasError('required') &&
                      (cityField.dirty || cityField.touched)
                    " class="invalid-feedback d-block">
                      Este campo é obrigatório.
                  </mat-error>
                  </mat-form-field>

                </div>
              </div>
            </div>

          </fieldset>
          <div class="container">
            <div *ngIf="!hasJob">
              <h5>Seu currículo</h5>
              <p class="text-muted mb-6">
                Com seu currículo, poderemos determinar as áreas mais adequadas às
                suas habilidades e aos seus interesses.
              </p>
            </div>
            <p class="text-muted mb-2">
              Envie seu currículo no formato PDF com no máximo 5 MB.
            </p>
            <div class="custom-file divisor">
              <label *ngIf="!fileName" class="custom-file-label" for="customFile">Currículo</label>
              <label *ngIf="fileName" class="custom-file-label" for="customFile">{{ fileName }}</label>
              <input type="file" (change)="uploadFile($event)" class="custom-file-input" name="customFile"
                id="customFile" (click)="curriculumTouched = true" />
              <mat-error *ngIf="!curriculum && curriculumTouched">
                Este campo é obrigatório
              </mat-error>
              <mat-error *ngIf="curriculum && curriculuminvalid">
                {{ curriculumErrors }}
              </mat-error>
            </div>
            <div class="ckeckboxes" *ngIf="!hasJob">
              <h5 class="h5 mb-1">Suas áreas de interesse</h5>
              <p class="text-muted mb-4">
                Conte para nós em quais áreas você gostaria de trabalhar.
              </p>
              <div *ngFor="let check of checkBoxArray; index as i">
                <div class="container">
                  <div class="row" *ngIf="i % 2 == 0">
                    <div class="col-lg-6 mb-2">
                      <input type="checkbox" id="{{ checkBoxArray[i].name }}" name="scales"
                        (change)="checkBoxToggle(i)" />
                      <label class="ml-2 check" for="checkBoxArray[i].name">{{
                        checkBoxArray[i].name
                        }}</label>
                    </div>
                    <div class="col-lg-6 mb-2" *ngIf="i + 1 < checkBoxArray.length">
                      <input (change)="checkBoxToggle(i + 1)" type="checkbox" id="{{ checkBoxArray[i + 1].name }}"
                        name="scales" />
                      <label class="ml-2 check" for="checkBoxArray[i+1].name">{{
                        checkBoxArray[i + 1].name
                        }}</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div *ngIf="success" class="modal-footer success-message flex-column">
            <div class="alert alert-success" role="alert">
              Parabéns, o seu curriculo foi enviado com sucesso, logo entraremos
              em contato!
            </div>
            <button type="close" (click)="onCloseClick('success')" class="btn btn-block py-3 mb-2">
              OK
            </button>
          </div>
          <div *ngIf="!success" class="modal-footer flex-column pt-0">
            <div *ngIf="error" class="text-center">
              <div class="alert alert-danger mt-4" role="alert">
                {{ errorMessage }}
              </div>
              <button type="close" (click)="resetError('error')" class="btn btn-block py-3 mb-2">
                OK
              </button>
            </div>
            <button *ngIf="!error" [disabled]="validate()" [hidden]="processingUpload || error" type="submit"
              class="btn btn-block py-3 mt-4">
              Candidatar-se
            </button>
            <div *ngIf="processingUpload" class="card-body">
              <div class="card-deck">
                <app-loading class="loading text-center"></app-loading>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>