<div class="home overflow-hidden">

    <!-- Header -->
    <div id="header-background-image" class="header" [ngStyle]="getBackgroundImg()">
        <div class="container">
            <div class="header-elements">
                <div class="header-title h-100">
                    <div class="header-spacing">
                        <div class="description d-flex flex-column" [ngClass]="{'mt-0':cupom}">
                            <div class="title-class" *ngIf="!cupom && homeContent && homeContent.pageHeader">
                                <h1><b>{{homeContent.pageHeader.headline}}</b></h1>
                                <p>{{homeContent.pageHeader.subheadline}}</p>
                            </div>

                            <div class="title-class" *ngIf=cupom>
                                <h1><b>{{homeContent.pageHeader.cupomHeadline}}</b></h1>
                                <p>{{homeContent.pageHeader.cupomSubheadline}}</p>
                            </div>

                            <div *ngIf="homeContent && homeContent.pageHeader && homeContent.pageHeader.siteCta"
                                class="cta-style">
                                <a *ngFor="let cta of homeContent.pageHeader.siteCta" class="text-decoration-none"
                                    [href]="cta.buttonLink">
                                    <button class="btn btn-primary cta-button"
                                        [ngStyle]="setColors(cta)">{{cta.buttonTitle}}</button>
                                </a>
                            </div>
                        </div>
                        <div class="home-principal-image">
                            <img *ngIf="homeContent && homeContent.pageHeader.mainImage && homeContent.pageHeader.mainImage._id"
                                src="{{environment.base_api}}/api/1/portal/drLavaTudo/ecommerceAPI/getImagesSite?objId={{homeContent.pageHeader.mainImage._id}}"
                                alt="Imagem principal">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="content-page" *ngIf="homeContent && homeContent.pageContent && homeContent.pageContent.length && hasActiveSection(homeContent.pageContent)">
        <div class="content-sections" *ngFor="let section of homeContent.pageContent; let i = index">
            <!-- [ngClass]="setSectionClass(section, i, homeContent.pageContent.length)" -->
            <div class="howWorks py-5" *ngIf="section.active && section.sectionName === 'howWorks'">
                <app-how-it-works [content]="section.sectionContent"></app-how-it-works>
            </div>

            <div class="commonQuestions" *ngIf="section.active && section.sectionName === 'commonQuestions'">
                <app-home-questions [content]="section.sectionContent"></app-home-questions>
            </div>

            <div class="lastBlogPosts py-5" *ngIf="section.active && section.sectionName === 'lastBlogPosts'">
                <app-recent-posts [content]="section.sectionContent"></app-recent-posts>
            </div>

            <div class="ourGoal" *ngIf="section.active && section.sectionName === 'ourGoal'">
                <app-goals [content]="section.sectionContent"></app-goals>
            </div>

            <div class="testimonies pt-5" *ngIf="section.active && section.sectionName === 'testimonies'">
                <div class="pt-4">
                    <app-satisfaction-guaranteed [content]="section.sectionContent"></app-satisfaction-guaranteed>
                </div>
            </div>

            <div class="dontWasteYourTime py-5 mx-auto" *ngIf="section.active && section.sectionName === 'dontWasteYourTime'">
                <div class="text-width text-center">
                    <h4 class="d-block text-green"> {{section.sectionContent.title[0]}} </h4>
                    <h2 class="text-gray"> {{section.sectionContent.title[1]}} </h2>
                    <h4 class="d-block">{{section.sectionContent.subtitle}}</h4>
                </div>

                <div *ngFor="let sectionElements of section.sectionContent.sectionElements">
                    <app-verified-professionals *ngIf="sectionElements.contentName === 'professionals'"
                        [content]="sectionElements.content"></app-verified-professionals>
                    <app-warranty-availability *ngIf="sectionElements.contentName === 'guarantee'"
                        [content]="sectionElements.content"></app-warranty-availability>
                </div>
            </div>

            <div class="attendedCities py-5" *ngIf="section.active && section.sectionName === 'attendedCities'">
                <div class="container">
                    <h2 class="attendedCitiesTitle">
                        <p class="text-margin text-center mb-0"> {{section.sectionContent.title[0]}} </p>
                        <p class="text-margin text-normal text-center"> {{section.sectionContent.title[1]}} </p>
                    </h2>
                    <app-attended-citys></app-attended-citys>
                </div>
            </div>

            <div class="approval py-5" *ngIf="section.active && section.sectionName === 'approval'">
                <div class="recommendations pt-4">
                    <div class="container">
                        <h2>
                            <p class="title-green text-margin text-center">{{section.sectionContent.title[0]}}</p>
                            <p class="title-black text-margin text-center">{{section.sectionContent.title[1]}}</p>
                        </h2>
                        <h4 class="approval-subtitle text-center">{{section.sectionContent.subtitle}}</h4>
                        <div class="d-block mx-auto my-5">
                            <app-cleaning-demonstration></app-cleaning-demonstration>
                        </div>

                        <div class="d-flex justify-content-center gap-2">
                            <a *ngFor="let cta of section.sectionContent.siteCta" href={{cta.buttonLink}} class="text-decoration-none">
                                <button class="btn btn-secondary button-all-services d-block m-auto" [ngStyle]="setColors(cta)"> {{cta.buttonTitle}} </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="checkService py-5" *ngIf="section.active && section.sectionName === 'checkService'">
                <div class="verify-zipcode">
                    <h3 class="text-margin text-normal text-center">{{section.sectionContent.title}}</h3>
                    <div class="zipcode-card">
                        <span class="zipcode-title">{{section.sectionContent.subtitle}}</span>
                        <div class="d-flex flex-row gap-4 w-100 mt-4 align-items-center">
                            <form class="w-100 formZipcode" [formGroup]="formZipcode">
                                <div class="form-group">
                                    <mat-form-field class="zipcode-field w-100" floatLabel='always'
                                        appearance="outline">
                                        <mat-label for="zipcode">Informe seu CEP</mat-label>
                                        <input placeholder="00000-000" type="text" formControlName="zipcode" required
                                            id="zipcode" name="zipcode" matInput pattern="\d{5}-?\d{3}" mask="00000-000">
                                    </mat-form-field>
                                </div>
                            </form>
                            <button [disabled]="!formZipcode.valid || isLoading" (click)="setZipcode()"
                                class="btn btn-secondary">
                                <span [hidden]="isLoading">BUSCAR</span>
                                <div class="customSpinner">
                                    <mat-progress-spinner [hidden]="!isLoading" mode="indeterminate" color="accent"diameter="30"></mat-progress-spinner>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<dlt-chat-web [page]="'home'" [origin]="environment.base_api" [application]="'ecommerce'" [org]="org"
    [isLogged]="userLogged" (openLoginModal)="openModalLogin()">
</dlt-chat-web>