import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-lead-capture',
  templateUrl: './lead-capture.component.html',
  styleUrls: ['./lead-capture.component.scss']
})
export class LeadCaptureComponent implements OnInit {
  shareLink: string;
  emailLink: string
  linePosition:string
  text;
  wppLink: any;
  twitterLink: any;

  public copyShareLink():void {
    navigator.clipboard.writeText(this.shareLink);
    this.snackbarService.info("Link copiado para a área de transferência.", "2500", "");
  }

  constructor(
    private snackbarService: SnackBarService,
    private userService: UsersService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("explorer_user_map"));
    if(!user?.ecommerce?.users[0]?.code) {
      window.location.href = "/assinatura/precos";
    }
    const data = {
      data:{
        customer: user.ecommerce.users[0].code
      }
    }
    this.loaderService.startLoader();
    this.userService.getOrCreateSubscriptionLead(data).subscribe((res) => {
      this.text = res.text;
      this.shareLink = "drlavatudo.com/assinatura-premiada?refId="+res._id;
      this.wppLink = res.sharingLink.find(item=> item.socialNetwork == "whatsapp")
      this.twitterLink = res.sharingLink.find(item=> item.socialNetwork == "twitter")
      const textEmail = res.sharingLink.find(item=> item.socialNetwork == "email");
      if(textEmail){
        this.emailLink = 'mailto:?subject=' + encodeURIComponent(textEmail.shareText) + '&body=' + encodeURIComponent(this.shareLink);
      }
      
      this.loaderService.stopLoader();
    });
  }

}
