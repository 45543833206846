import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { CleaningDemonstrationComponent } from './components/cleaning-demonstration/cleaning-demonstration.component';
import { AccordionComponent } from './components/accordion/accordion.component';

//Services
import { HttpService } from '../shared/services/http.service';
import { SafeHTML } from './pipes/safeHTML.pipe';
import { SafeStyle } from './pipes/safeStyle.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { TestimonyComponentComponent } from './components/testimony-component/testimony-component.component';
import { SatisfactionGuaranteedComponent } from './components/satisfaction-guaranteed/satisfaction-guaranteed.component';
import { HomeQuestionsComponent } from './components/home-questions/home-questions.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { GoalsComponent } from './components/goals/goals.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';


//Components
import { FooterComponent } from './components/footer/footer.component';
import { VerifiedProfessionalsComponent } from './components/verified-professionals/verified-professionals.component';
import { TechnicianImageComponent } from './components/technician-image/technician-image.component';
import { WarrantyAvailabilityComponent } from './components/warranty-availability/warranty-availability.component';
import { CleanningImageComponent } from './components/cleanning-image/cleanning-image.component';
import { GoalsImageComponent } from './components/goals-image/goals-image.component';
import { AttendedCitysComponent } from './components/attended-citys/attended-citys.component';
import { RecentPostsComponent } from './components/recent-posts/recent-posts.component'
import { LoadingComponent } from './components/loading/loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { TextMaskModule } from 'angular2-text-mask';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CompaniesBoardComponent } from './components/companies-board/companies-board.component';

@NgModule({
  declarations: [
    FooterComponent,
    SafeHTML,
    SafeStyle, 
    MarkdownPipe, 
    TestimonyComponentComponent,
    CustomLoaderComponent,
    CleaningDemonstrationComponent,
    SatisfactionGuaranteedComponent,
    HomeQuestionsComponent,
    AccordionComponent,
    GoalsComponent,
    HowItWorksComponent,
    VerifiedProfessionalsComponent,
    TechnicianImageComponent,
    WarrantyAvailabilityComponent,
    CleanningImageComponent,
    GoalsImageComponent,
    AttendedCitysComponent,
    RecentPostsComponent,
    LoadingComponent,
    CompaniesBoardComponent
  ],
  providers: [
    HttpService,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SwiperModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatExpansionModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    TextMaskModule,
    MatInputModule,
    MatCheckboxModule
  ],
  exports: [
    FooterComponent,
    SafeHTML,
    SafeStyle,
    MarkdownPipe,
    TestimonyComponentComponent,
    SwiperModule,
    CustomLoaderComponent,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSelectModule,
    CleaningDemonstrationComponent,
    SatisfactionGuaranteedComponent,
    HomeQuestionsComponent,
    AccordionComponent,
    GoalsComponent,
    HowItWorksComponent,
    VerifiedProfessionalsComponent,
    TechnicianImageComponent,
    WarrantyAvailabilityComponent,
    CleanningImageComponent,
    GoalsImageComponent,
    AttendedCitysComponent,
    RecentPostsComponent,
    LoadingComponent,
    CompaniesBoardComponent
  ]
})
export class SharedModule { }
