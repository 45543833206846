import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { CareerService } from "./../../../../shared/services/career.service";

@Component({
  selector: 'app-pre-registration-partner',
  templateUrl: './pre-registration-partner.component.html',
  styleUrls: ['./pre-registration-partner.component.scss']
})
export class PreRegistrationPartnerComponent implements OnInit {
  loading: boolean;
  found: boolean;

  leadId: any;
  hash: any;

  partner: any;
  serviceInfo: any;
  title: string;
  subtitle: string;
  showImage: boolean;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private careerService: CareerService,
  ) { }

  ngOnInit(): void {
    this.loaderService.startLoader();
    this.loading = true;
    
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams) {
        this.leadId = queryParams.i ? queryParams.i : null;
        this.hash = queryParams.h ? queryParams.h : null;
      }
    });

    if (this.leadId && this.hash) {
      this.careerService.validatePartnerLink(this.leadId, this.hash).subscribe(value => {
        if (value.error) {
          this.found = false;
        } else {
          this.found = true;
          this.title = value.title ? value.title : "Formulário enviado!";
          this.subtitle = value.subtitle ? value.subtitle : "";
          this.showImage = value.showImage ? true : false;
          this.partner = value.partner ? value.partner : null;
          this.serviceInfo = value.serviceInfo ? value.serviceInfo : null;
        } 

        this.loaderService.stopLoader();
        this.loading = false;
      }, err => {
        this.found = false;
        this.loaderService.stopLoader();
        this.loading = false;
      });
    } else {
      this.found = false;
      this.loaderService.stopLoader();
      this.loading = false;
    }
  }

  setDone(event): void {
    this.title = event.title;
    this.subtitle = event.subtitle;
    this.showImage = event.showImage;
    this.partner = null;
    this.serviceInfo = null;
    
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
