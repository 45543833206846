import { Component, HostListener, OnInit } from '@angular/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-special-deals',
  templateUrl: './special-deals.component.html',
  styleUrls: ['./special-deals.component.scss']
})
export class SpecialDealsComponent implements OnInit {

  public isMobile: boolean = false;
  public ecommerceUrl: string = environment.ecommerce_url;
  public couponList: any = [];
  constructor(private util: UtilService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobileWidth();
  }

  ngOnInit(): void {
    this.mobileWidth();
    this.getCoupons();
  }

  private getCoupons(){
    this.util.getCouponList().subscribe(
      result=>{
        this.couponList = result;
        this.mountCouponDescription();
      }
    )
  }

  private mountCouponDescription(){
    this.couponList.forEach(coupon => {
      let description = `Desconto de ${coupon.value} `;
      if(coupon.onlyFirstPurchase) description += 'para a primeira compra';
      coupon.description = description;
    });
  }

  private mobileWidth() {
    this.isMobile = window.innerWidth <= 768;
  }

  public goToEcommerce(couponIdentifier){
    window.location.href = this.ecommerceUrl.slice(0,this.ecommerceUrl.length-1) + '?cupom=' + couponIdentifier;
  }

}
