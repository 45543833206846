import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';
import { City } from '../../models/city';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-attended-citys',
  templateUrl: './attended-citys.component.html',
  styleUrls: ['./attended-citys.component.scss']
})
export class AttendedCitysComponent implements OnInit {
  @ViewChild(SwiperDirective, { read: true }) directiveRef?: SwiperDirective;
  public currentEnvironment: string = environment.base_api;
  imgArray = [];
  constructor(private util: UtilService) { }
  cities: any;
  config: SwiperConfigInterface;
  ngOnInit(): void {
    this.config = {
      slidesPerView: 3,
      spaceBetween: 20,
      breakpoints: {
        1000: {
          slidesPerView: 2,
        },
        770: {
          slidesPerView: 1,
        }
      },
      navigation: true,
      pagination: {
        el: '#attended-pagination',
        type: 'bullets',
        clickable: true
      },
      autoplay: {
        delay: 20000,
        disableOnInteraction: false
      }
    };
    this.util.getAttendedsCitys().subscribe(result => {
      if (!result) { return; }
      this.cities = result;
    });
  }

  changeSource(event) { event.target.src = "assets/images/SELO.png"; }
}


