import { Component, Input, OnInit } from "@angular/core";
import { UtilService } from "../../services/util.service";

@Component({
  selector: "app-home-questions",
  templateUrl: "./home-questions.component.html",
  styleUrls: ["./home-questions.component.scss"],
})
export class HomeQuestionsComponent implements OnInit {
  @Input() content: any;
  questionsList: any = [];

  constructor(private util: UtilService) {}

  ngOnInit(): void {
    if (!this.content || !Object.keys(this.content).length) {
      this.content = {
        siteCta: [
          {
            backgroundColor: "transparent",
            borderColor: "transparent",
            buttonTitle: "QUER SABER MAIS? CLICA AQUI",
            buttonLink: "drlavatudo.com/perguntas-frequentes",
            fontColor: "#34C87E",
          },
        ],
        title: ["Aproveite para tirar", "todas as suas dúvidas"],
      };
    }

    this.util.getCommonQuestions("home").subscribe((result) => {
      this.questionsList = result;
    });
  }

  setColors(cta) {
    return {
      "background-color": cta.backgroundColor,
      "border-color": cta.borderColor,
      color: cta.fontColor,
    };
  }
}
